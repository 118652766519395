export default {
  'withdrawal-ph-1': 'ID кошелька',
  'withdrawal-ph-2': 'Сумма вывода',
  'confirm-modal-1': 'Подтвердите действие',
  'confirm-modal-2': 'Подтвердить',
  'confirm-modal-3': 'Отмена',
  copy: 'Копировать',
  delete: 'Удалить',
  download: 'Скачать',
  'modal-p-text-1': 'Подробная инструкция по работе с Инвестиционными Планами',
  'modal-p-text-2':
    'После того как депозит был зачислен на Ваш счет вы можете инвестировать свои средства в выбранный план.',
  'modal-p-text-3':
    'Следуйте инструкции ниже что бы активировать ваш Инвестиционный план.',
  'modal-p-text-4':
    "В личном кабинете перейдите на страницу 'Управление планами'. Здесь Вы найдете подробную информацию по всем инвестиционным продуктам. Это сроки инвестиций, комиссии сервиса, а так же доходность плана.",
  'modal-p-text-5':
    "Для активации нужного плана, нажмите кнопку 'Выбрать' , введите сумму  стартового капитала на которую хотите запустить план и нажмите на кнопку 'Активировать.",
  'modal-p-text-6':
    "С помощью функции 'Автоматическое реинвестирование' вы можете автоматически инвестировать свои средства на длительный срок и получить дополнительные преимущества.",
  'modal-p-text-7':
    'Сразу после запуска инвестиционного плана вы можете активировать или деактивировать опцию одним щелчком мыши.',
  'modal-p-text-8': 'Поздравляем, вы успешно запустили инвестиционный план!',
  'modal-p-text-9':
    'Вы можете изменить эту настройку по своему усмотрению, даже когда когда срок исполнения уже начался. Если вы активировали данную опцию, то по истечении Срока инвестиционного плана весь капитал, включая всю прибыль реинвестируется автоматически. Если данная опция отключена, то автоматическое реинвестирования не происходит, и весь капитал с прибылью будет начислен на Свободный Баланс.',

  update: 'Загрузить',
  'update-avatar': 'Загрузите вашу аватарку',
  save: 'Сохранить',
  'modal-d-text-1': 'Инструкция по Пополнению Депозита на CoinFuze',
  'modal-d-text-2': "Перейдите на страницу 'Депозит' в вашем личном кабинете.",
  'modal-d-text-3':
    "Чтобы начать процесс пополнения, нажмите кнопку 'Пополнить Баланс'.",
  'modal-d-text-4':
    'Вам будет предоставлен уникальный идентификатор кошелька для отправки средств. Пожалуйста, убедитесь, что вы используете именно этот идентификатор при совершении платежа.',
  'modal-d-text-5':
    'У вас также есть возможность использовать QR-код, расположенный слева от идентификатора кошелька. Вы можете отсканировать его с помощью мобильного телефона для более удобного внесения данных.',
  'modal-d-text-6':
    'После отправки средств на указанный кошелек, средства в автоматическом режиме зачислятся вам на Баланс. Обычно это занимает от 1 минуты до 2 часов, но временные рамки могут варьироваться в зависимости от блокчейна и других факторов.',
  'modal-d-text-7':
    "Подтвержденные депозиты будут отображаться в нижней части страницы на вкладке 'История Депозитов'. Здесь вы сможете отслеживать все ваши заявки на пополнения.",
  'modal-d-text-8':
    'Следуйте этой инструкции, чтобы легко и безопасно пополнить свой депозит на CoinFuze. Если у вас возникли вопросы или потребовалась дополнительная помощь, обращайтесь к нашей службе поддержки.',

  'modal-w-text-1':
    'Что бы начать процесс вывода средств следуйте этой инструкции:',
  'modal-w-text-2':
    "В личном кабинете в меню слева перейдите на страницу 'Вывод средств'.",
  'modal-w-text-3': 'Введите количество USDT, которое вы хотите вывести.',
  'modal-w-text-4':
    'Укажите свой кошелек USDT TRC20, на который вы хотите получить средства.',
  'modal-w-text-5':
    'Подтвердите операцию, введя код подтверждения, который будет отправлен на вашу электронную почту, а так же код пароль (2FA) двухфакторной аутентификации если он у вас подключен.',
  'modal-w-text-6':
    'После подтверждения ваш запрос на вывод средств будет обработан. Обычно это занимает от нескольких минут до 24 часов, в зависимости от загрузки системы.',
  'modal-w-text-7':
    'Обратите внимание, что минимальная сумма для вывода составляет 10 USDT и есть небольшая комиссия за сетевой сбор. Кроме того, вам нужно будет убедиться, что срок вашего инвестиционного плана истек и прибыль начислена на Свободный Баланс чтобы иметь возможность выводить средства.',

  validation: {
    nameValidFormat: '{attribute} не должен содержать цифры или символы',
    twoCharactersInRow:
      '{attribute} не должен иметь более двух одинаковых символов подряд',
    email: 'Формат электронной почты неверен',
    required: 'Поле {attribute} обязательно',
    minValue: 'Минимальное значение {attribute} не может быть меньше {min}',
    maxValue: 'Максимальное значение {attribute} не может быть больше {max}',
    minLength: '{attribute} должен быть не менее {min} символов',
    maxLength: '{attribute} должен быть не более {max} символов',
    validFormat:
      '{attribute} должен начинаться с заглавной буквы и содержать только буквы',
    validPassword:
      '{attribute} должен содержать хотя бы одну цифру и одну заглавную букву',
    sameAsPassword: 'Пароли должны совпадать',
    numeric: '{attribute} неверен. Должно быть числом',
    minimumValue: 'Сумма не должна составлять менее 100 USDT'
  },
  Country: 'Страна',
  'phone-input-placeholder': 'Введите ваш номер телефона',

  'withdrawal-instruction': 'Подробная инструкция по выводу средств',
  'withdrawal-instruction-text-1':
    'Чтобы начать процесс вывода средств, следуйте этим инструкциям:',
  'withdrawal-instruction-text-2':
    'В вашем личном кабинете в левом меню перейдите на страницу "Вывод средств".',
  'withdrawal-instruction-text-3':
    'Введите сумму USDT, которую вы хотите вывести.',
  'withdrawal-instruction-text-4':
    'Укажите ваш кошелек USDT TRC20, на который вы хотите получить средства.',
  'withdrawal-instruction-text-5':
    'Подтвердите транзакцию, введя код подтверждения, который будет отправлен на вашу электронную почту, а также код пароля (2FA) двухфакторной аутентификации, если она подключена.',
  'withdrawal-instruction-text-6':
    'После подтверждения ваш запрос на вывод средств будет обработан. Обычно это занимает от нескольких минут до 24 часов в зависимости от загруженности системы.',
  'withdrawal-instruction-text-7':
    'Обратите внимание, что минимальная сумма для вывода составляет 10 USDT, и взимается небольшая сетевая комиссия. Кроме того, вы должны убедиться, что ваш инвестиционный план истек и прибыль зачислена на Свободный Баланс, чтобы можно было произвести вывод средств.',
  'plans-instruction-text-1':
    'После зачисления депозита на ваш счет вы можете инвестировать ваши средства в выбранный план.',
  'plans-instruction-text-2':
    'Следуйте инструкциям ниже, чтобы активировать ваш инвестиционный план.',
  'plans-instruction-text-3':
    'Перейдите на страницу "Инвестиционные планы" в вашем личном кабинете. Здесь вы найдете подробную информацию о каждом инвестиционном плане: условия инвестиций, комиссии и доходность плана.',
  'plans-instruction-text-4':
    'Чтобы активировать желаемый план, нажмите на "Плюс" и введите в поле "Начальный капитал" сумму, на которую вы хотите начать план, и нажмите кнопку "Активировать".',
  'plans-instruction-text-5':
    'С функцией "Автоматическое реинвестирование" вы можете автоматически инвестировать ваши средства на длительный срок и получать дополнительные преимущества.',
  'plans-instruction-text-6':
    'Сразу после начала инвестиционного плана вы можете активировать или деактивировать опцию одним кликом.',
  'plans-instruction-text-7':
    'Поздравляем, вы успешно запустили инвестиционный план!',
  'plans-instruction-text-8':
    'Вы можете изменять эту настройку по своему желанию, даже если срок уже начался. Если эта опция активирована, по окончании срока инвестиционного плана весь капитал, включая все прибыли, автоматически реинвестируется. Если эта опция отключена, автоматического реинвестирования не происходит, и весь капитал с прибылью зачисляется на Свободный Баланс.',
  'plans-ininstruction-text-9':
    'Если у вас активирована функция "Автоматическое реинвестирование", весь капитал и прибыль плана будут постоянно реинвестироваться после его окончания, пока вы не отключите эту опцию.',
  'plans-instruction-text-10':
    'Таким образом, вы можете полностью автоматически инвестировать свой капитал на долгосрочный срок с минимальными затратами. Чтобы вывести весь капитал, вы должны отключить эту функцию. После отключения функции весь капитал, включая доход, будет зачислен на Свободный Баланс по окончании текущего срока инвестиционного плана. При использовании этой опции дополнительные сборы не взимаются.',
  'plans-instruction-text-11':
    'Всю информацию о работе с Активными и Завершенными Инвестиционными Планами можно найти в разделе "История планов" в нижней части страницы.',
  'popup-instruction-title-2': 'Пошаговое руководство',
  'popup-instruction-text-1': 'Инструкции по депозиту CoinFuze',

  'popup-instruction-text-2':
    'Перейдите на страницу "Депозит" в вашем личном кабинете.',
  'popup-instruction-text-3':
    'Чтобы начать процесс депозита, нажмите кнопку "Депозит".',
  'popup-instruction-text-4':
    'Вам будет предоставлен уникальный идентификатор кошелька для отправки средств. Пожалуйста, убедитесь, что вы используете этот идентификатор при совершении платежа.',
  'popup-instruction-text-5':
    'У вас также есть возможность использовать QR-код, расположенный слева от идентификатора кошелька. Вы можете отсканировать его с помощью мобильного телефона для более удобного ввода данных.',
  'popup-instruction-text-6':
    'После отправки средств на указанный кошелек, средства будут автоматически зачислены на ваш баланс. Обычно это занимает от 1 минуты до 2 часов, но временные рамки могут варьироваться в зависимости от блокчейна и других факторов.',
  'popup-instruction-text-7':
    'Подтвержденные депозиты будут отображаться в нижней части страницы во вкладке "История депозитов". Здесь вы сможете отслеживать все ваши запросы на депозит.',
  'popup-instruction-text-8':
    'Следуйте этим инструкциям, чтобы легко и безопасно сделать ваш депозит на CoinFuze. Если у вас есть вопросы или вам нужна дополнительная помощь, пожалуйста, свяжитесь с нашей службой поддержки.',
  error: 'Ошибка',
  'partnership-text-31': 'Количество рефералов',
  'partnership-text-30': 'Сумма депозитов',
  'autorization-tab-1': 'Авторизация',
  'autorization-tab-2': 'Регистрация',
  'autorization-login': 'Логин',
  'autorization-username': 'Логин/Email/Телефон',
  'autorization-password': 'Пароль',
  'autorization-forgot': 'Забыли пароль?',
  'autorization-btn-login': 'Войти в аккаунт',
  'autorization-name': 'Имя',
  'autorization-surname': 'Фамилия',
  'autorization-repeat-password': 'Подтвердите пароль',
  'autorization-ypass': 'Ваш пароль',
  'autorization-newpass': 'Ваш новый пароль',
  'autorization-email': 'Электронная почта',
  'autorization-tel': 'Телефон',
  'autorization-promo': 'Промокод',
  'autorization-text-0': 'Я принимаю',
  'autorization-text-1': 'Условия и положения,',
  'autorization-text-2': 'Политика конфиденциальности',
  'autorization-text-3': 'Политика AML',
  'autorization-text-4': 'Мне 18 лет или больше',
  'autorization-text-5': 'Условия обслуживания',
  'autorization-text-6': 'Запомнить меня',

  'autorization-text-7': 'У вас нет аккаунта?',
  'autorization-text-8':
    'Введите ваш логин, и мы отправим инструкции на вашу электронную почту:',
  'autorization-text-10': 'У вас уже есть аккаунт?',
  'autorization-text-11': 'Войти',
  'autorization-btn-register': 'Регистрация',
  'autorization-btn-cancel': 'Сбросить пароль',
  'autorization-status-2': 'Указанный email/телефон не найден.',
  'autorization-status-1': 'Неверный логин или пароль',
  'autorization-status-name': 'Некорректное имя',
  'autorization-status-surname': 'Некорректная фамилия',
  'autorization-status-password':
    'Пароль должен быть не менее 7 символов и содержать как минимум 1 заглавную и 1 строчную букву',
  'autorization-status-repeat-password': 'Пароли не совпадают',
  'autorization-status-email': 'Некорректный формат почты',
  'autorization-status-phone': 'Некорректный номер телефона',
  'autorization-status-agree-rules': 'Подтвердите условия',
  'autorization-status-agree-age': 'Подтвердите условия',
  'validation-1': 'Для регистрации вы должны быть старше 18 лет',
  'validation-2': 'Для регистрации вы должны принять условия и положения',
  'header-item-1': 'Общий баланс',
  'header-item-2': 'Свободный баланс:',
  'header-item-3': 'Активный баланс:',
  'non-message': 'Нет новых уведомлений',
  'message-btn': 'Показать все уведомления',
  'header-item-4': 'Личная информация',
  'header-item-5': 'Пригласить друзей',
  'header-item-6': 'Добавить',
  'header-item-7': 'Выйти',
  'footer-item-2': 'Условия использования',
  'footer-item-3': 'Политика конфиденциальности',
  'footer-item-1': 'Политика AML',
  'sidebar-item-1': 'Панель управления',
  'sidebar-item-2': 'Банкинг',
  'sidebar-item-3': 'Управление планами',
  'sidebar-item-4': 'Реферальная программа',
  'sidebar-item-5': 'Вывод средств',
  'sidebar-item-6': 'Личная информация',
  'sidebar-item-7': 'Новости',
  'sidebar-item-8': 'Выйти из аккаунта',
  'dashboard-subtitle-1': 'Аккаунт',
  'dashboard-subtitle-2': 'Финансы',
  'dashboard-subtitle-3': 'Инвестиции',
  'dashboard-subtitle-4': 'Партнёры',
  'dashboard-subtitle-5': 'Срок',
  'dashboard-subtitle-6': 'Текущий результат',
  'dashboard-subtitle-7': 'Финансовый результат',
  'dashboard-text-1': 'Свободный баланс',
  'dashboard-text-2': 'Активные средства',
  'dashboard-text-3': 'Верифицирован',
  'dashboard-text-4': 'Участник с',
  'dashboard-text-5': 'Общая прибыль по планам',
  'dashboard-text-6': 'Реферальный доход',
  'dashboard-text-7': 'Депозит',
  'dashboard-text-8': 'Вывод',
  'dashboard-text-9': 'Общая прибыль',
  'dashboard-text-10': 'Активные планы',
  'dashboard-text-10-1': 'Закрытые планы',
  'dashboard-text-11': 'Планы на реинвестирование',
  'dashboard-text-12': 'Количество партнеров',
  'dashboard-text-13': 'Партнеры 1-й линии',
  'dashboard-text-14': 'Партнеры 2-й линии',
  'dashboard-text-15': 'Партнеры 3-й линии',
  'dashboard-text-16': 'Период',
  'dashboard-text-17': 'дней',
  'dashboard-text-18': 'Новостей пока нет',
  'dashboard-text-19': 'В настоящее время',
  'dashboard-text-20': 'Недостаточно данных для графика',
  'dashboard-text-21': '- Нет активных инвестиций',
  'dashboard-text-22': 'Читать все новости',
  'dashboard-text-22-1': 'Читать все',
  'dashboard-text-23': 'Инвестиционный план',
  progressLabel: 'Прогресс инв. плана',
  daysLabel: 'Дней до завершения',
  'deposit-subtitle-1-1':
    'Перед совершением депозита убедитесь, что вы завершили',
  'deposit-subtitle-1-2': 'Регистрацию вашего аккаунта.',
  'deposit-subtitle-1-3': 'В противном случае вы не сможете сделать депозит.',
  'deposit-subtitle-2': 'Зарабатывайте с CoinFuze',
  'deposit-text-1':
    'Чтобы пополнить ваш баланс, нажмите на кнопку ниже "Пополнить счет".',
  'deposit-text-2':
    'Все ваши транзакции по депозитам будут сохранены в разделе "История" ниже, где вы сможете отслеживать статус ваших запросов.',
  'deposit-text-3':
    'Когда запрос получит статус "Завершено", средства будут автоматически добавлены к вашему "Свободному балансу".',
  'deposit-text-4':
    'В настоящее время мы используем наиболее стабильную криптовалюту для нашей системы - Tether USDT.',
  'deposit-text-5':
    'После совершения депозита вы сможете наслаждаться всеми преимуществами сотрудничества с CoinFuze.',
  'deposit-text-6': 'Среднемесячная прибыль - 6.8%*',
  'deposit-text-7': 'Годовая прибыль - 98.7%',
  'deposit-text-8': 'Полностью автоматизированная торговая система',
  'deposit-text-9':
    'Возможность реинвестирования и гибкая система выбора инвестиционных сроков',
  'deposit-text-10':
    '*Данные о доходности взяты из открытой статистики работы алгоритма за 2024 год.',
  'deposit-text-11': 'Подробная инструкция по внесению депозита',
  'deposit-text-12':
    'У вас есть вопросы о депозите? Задайте их в чате поддержки',

  'deposit-text-13':
    'Чтобы пополнить баланс, нажмите на кнопку ниже Пополнить Счет.',
  'deposit-text-14':
    'Все ваши транзакции связанные с пополнением будут сохранятся в разделе История ниже, где вы можете отслеживать статусы своих заявок.',
  'deposit-text-15':
    'Когда заявка получает статус Завершен, средства автоматически добавляются вам в Свободный Баланс.',
  'deposit-text-16':
    'На данный момент мы используем для нашей системы самую стабильную криптовалюту - Tether USDT',
  'deposit-text-17':
    'После пополнения депозита вы сможете пользоваться всеми преимуществами сотрудничества с CoinFuze.',
  'deposit-text-18':
    'У вас есть вопросы по пополнению? Задайте их в чате поддержки.',
  'deposit-btn': 'Пополнить счет',
  notification: 'Скопировано',
  'deposit-popup-text-1': 'Криптовалюта',
  'deposit-popup-text-2': 'Валюта депозита',
  'deposit-popup-text-3': 'Ваш баланс:',
  'deposit-popup-text-4': 'Сеть',
  'deposit-popup-text-5': 'Примечание:',
  'deposit-popup-text-6-1': 'Отправляйте только',
  'deposit-popup-text-6-2':
    'на этот адрес депозита. Монеты будут зачислены автоматически после',
  'deposit-popup-text-6-3': '6 подтверждений в сети.',
  'deposit-popup-text-6-4':
    'Адреса смарт-контрактов не поддерживаются (свяжитесь с нами).',
  'deposit-popup-text-7': 'Адрес для депозита:',
  'deposit-popup-text-8': 'Копировать',
  history: 'История',
  'deposit-history-text-1': 'Дата депозита',
  'deposit-history-text-2': 'Способ платежа',
  'deposit-history-text-3': 'Сумма депозита',
  'deposit-history-text-4': 'Статус транзакции',
  'deposit-history-text-5': 'Транзакция',
  'deposit-history-text-6': 'Транзакций нет',
  'deposit-history-text-7': 'Показаны',
  'deposit-history-text-8': 'из',
  'plans-subtitle-1': 'Управление инвестициями',
  'plans-subtitle-2': 'Первые инвестиции',
  'plans-text-1':
    'В этом разделе вы можете создавать планы из доступных или просматривать финансовые результаты всех ваших активных или закрытых планов.',
  'plans-text-2':
    'Для выбора доступно шесть инвестиционных планов, каждый с разными условиями по сроку инвестирования, ожидаемой доходности и комиссиям сервиса Coinfuze.',
  'plans-text-3':
    'Чтобы активировать выбранный план, пользователи должны сделать минимальный депозит и нажать кнопку "Активировать".',
  'plans-text-4': 'Подробная инструкция по работе с Инвестиционными Планами.',
  'plans-text-5': 'Часто задаваемые вопросы в разделе Инвестиции.',
  'plans-text-6':
    'Чтобы начать инвестировать, вам нужно сделать депозит. Это можно сделать на странице "Депозит" в меню слева или на кнопке ниже.',
  'plans-text-7':
    'После обработки вашего депозита активируйте выбранный инвестиционный план.',
  'plans-text-8':
    'CoinFuze предлагает функцию автоматического реинвестирования. Это означает, что по окончании срока плана все прибыли из предыдущего периода автоматически переводятся в начальный капитал нового плана.',
  'plans-text-9': 'Перейти к депозиту',
  'plans-text-10': 'Активировать инвестиционный план',
  'plans-text-11': 'Да',
  'plans-text-12': 'Нет',
  'plans-text-13': 'Активные планы',
  'plans-text-14': 'Дата начала',
  'plans-text-15': 'Дата окончания',
  'plans-text-16': 'Стартовый капитал',
  'plans-text-17': '% финансовый результат',
  'plans-text-18': 'Прибыль в',
  'plans-text-19': 'Статус плана',
  'plans-text-20': 'Реинвестировать',
  'plans-text-21': 'Сохранить',
  'plans-text-22': 'Доступные планы',
  'plans-text-23': 'Мин. инвестиция',
  'plans-text-24': 'Срок',
  'plans-text-25': 'Доходность',
  'plans-text-26': 'до',
  'plans-text-27': 'Комиссия',
  'plans-text-28': 'Введите сумму стартового капитала:',
  'plans-text-29': 'Активировать',
  'plans-text-30': 'Введите минимальную сумму',
  'plans-text-31': 'Название',
  'plans-text-32': 'Стартовый капитал',
  'plans-text-33': 'Текущий результат',
  'plans-text-34': 'Прибыль',
  'plans-text-35': 'Комиссия',
  'plans-text-36': 'Активен',
  'plans-text-37': 'Завершен',
  'plans-text-38': 'Инвестиционный план',
  'plans-text-39': 'дней',
  'plans-text-40': 'Выбрать',
  'plans-text-41': 'Статус:',
  'partnership-subtitle-1': 'Реферальная программа',
  'partnership-subtitle-2': 'Ваша реферальная ссылка',
  'partnership-subtitle-3': 'Приглашенные рефералы',
  'partnership-subtitle-4': 'Уровень 1 - комиссия с депозита и прибыли',
  'partnership-subtitle-5': 'Уровень 2 - комиссия с депозита и прибыли',
  'partnership-subtitle-6': 'Уровень 3 - комиссия с депозита и прибыли',
  'partnership-text-1': 'Рекомендуйте нас своим друзьям.',
  'partnership-text-2':
    'Получайте пассивный доход с помощью реферальной программы.',
  'partnership-text-3': 'Скопируйте вашу реферальную ссылку.',
  'partnership-text-4': 'Отправьте ее своим друзьям.',
  'partnership-text-5': 'Получайте бонусы за их пополнения депозитов',
  'partnership-text-6': 'Получайте бонусы с их прибыли',
  'partnership-text-7':
    'Каждый участник CoinFuze получает уникальную реферальную ссылку и промокод для распространения среди друзей со всего мира. Просто скопируйте эту ссылку и отправьте тем, кого хотите пригласить в CoinFuze.',
  'partnership-text-8': 'ВАШ ПРОМО',
  'partnership-text-9': 'Поделитесь своей партнерской ссылкой или промокодом.',
  'partnership-text-10': 'Изучайте статистику переходов',
  'partnership-text-11':
    'Отслеживайте, сколько людей зарегистрировалось по вашей ссылке',
  'partnership-text-12': 'Наблюдайте за ростом ваших доходов',
  'partnership-text-13': 'Всего приглашено &nbsp;&nbsp;&nbsp;',
  'partnership-text-14': 'Партнеры 1-го уровня',
  'partnership-text-15': 'Партнеры 2-го уровня',
  'partnership-text-16': 'Партнеры 3-го уровня',
  'partnership-text-17': 'Общий доход:',
  'partnership-text-18': 'Поиск',
  'partnership-text-19':
    'Посмотрите, кто уже зарегистрировался по вашей партнерской ссылке и сколько бонусов вы получили. Не забывайте, что у нас трехуровневая реферальная программа. Вы получаете бонусы не только за тех, кого пригласили в CoinFuze, но и за участников, приглашенных вашими рефералами.',
  'partnership-text-20': 'Введите имя вашего реферала',
  'partnership-text-21': 'Бонусы с депозитов',
  'partnership-text-22': 'Бонусы с прибыли',
  'partnership-text-23': 'Доход от партнерства',
  'partnership-text-24': 'Дата регистрации',
  'partnership-text-25': 'Такого реферала нет',
  'partnership-text-26': 'Поиск...',
  'partnership-text-27': 'Рефералов нет',
  'partnership-text-28': 'Уровень',
  'partnership-text-29': 'Объем оборота',
  'partnership-text-32': 'Бонусы',
  'partnership-text-33': 'Пройдено',
  'partnership-text-34': 'Ваш уровень',
  'partnership-text-35': 'Закрыт',
  'partnership-text-36': 'Реферальная ссылка:',
  'partnership-text-37': 'Ваш промо:',
  'partnership-text-38': 'Командный оборот:',
  'partnership-text-39': 'Комиссия с депозита и прибыли:',
  'partnership-text-40': 'Рефералы',
  'partnership-text-41': 'Командные бонусы',
  'news-text-1': 'Новостей пока нет.',
  'news-text-2': 'Читать далее',
  'news-text-3': 'Промо-материалы',
  'news-text-4':
    'Поделитесь нашими промо-материалами с друзьями и зарабатывайте!',
  'news-text-5':
    'Мы подготовили для вас подборку промо-материалов для привлечения новых участников CoinFuze.',
  'news-text-6': 'Делитесь и зарабатывайте вместе с друзьями!',
  'news-text-7':
    'В CoinFuze каждый участник обладает эксклюзивной реферальной ссылкой и промокодом, которым можно поделиться с друзьями из любой точки земного шара. Достаточно просто скопировать эту ссылку и отправить её тем, кого вы хотели бы видеть в числе пользователей CoinFuze.',
  'news-text-8': 'ВАШ ПРОМО',
  'news-text-9': 'Поделитесь вашей партнерской ссылкой или промокодом.',
  'news-text-10': 'Изучите статистику переходов по ней',
  'news-text-11':
    'Следите за тем, сколько людей зарегистрировалось по вашей ссылке',
  'news-text-12': 'Наблюдайте за ростом ваших доходов.',
  'news-text-13': 'Зарабатывайте до 6% реферальных бонусов',
  'withdrawal-text-1': 'Дата вывода',
  'withdrawal-text-2': 'Способ оплаты',
  'withdrawal-text-3': 'Сумма вывода',
  'withdrawal-text-4':
    'Для вывода средств необходимо подтвердить вашу личность',
  'withdrawal-text-5':
    'Для вывода средств необходимо завершить процесс верификации, загрузив ваши документы, удостоверяющие личность.',
  'withdrawal-text-6': 'Проверить',
  'withdrawal-text-7': 'Вывод средств',
  'withdrawal-text-8':
    'Введите код подтверждения, отправленный на вашу электронную почту:',
  'withdrawal-text-9': 'Введите код',
  'withdrawal-text-10': 'Отправить новый код ещё раз через:',
  'withdrawal-text-11': 'Проверить код',
  'withdrawal-text-12': 'Отправить ещё раз',
  'withdrawal-text-13': 'Проверено',
  'withdrawal-text-14': 'Отправить новый код ещё раз через:',
  'withdrawal-text-15': 'Введите код из мобильного приложения',
  'withdrawal-text-16': 'Введите код из',
  'withdrawal-text-17': 'Информация:',
  'withdrawal-text-18':
    'Свободный баланс - это ваш доступный баланс для вывода средств. Если у вас есть активные инвестиции, они не учитываются, так как заблокированы до окончания инвестиции.',
  'withdrawal-text-19': 'Вывод средств',
  'withdrawal-text-19-1': 'Доступно для вывода:',
  'withdrawal-text-19-2': 'Начать вывод средств',
  'withdrawal-text-20':
    'На этой странице вы можете вывести средства на ваш криптовалютный кошелек. Следуйте подсказкам, чтобы сделать вывод максимально быстрым и удобным.',
  'withdrawal-text-21': 'Вывод, как и депозит, доступны в криптовалюте',
  'withdrawal-text-22':
    'Вывод средств может занять некоторое время, так как транзакции в криптовалютной сети происходят с разной скоростью.',
  'withdrawal-text-23':
    'Запросы на вывод средств принимаются и обрабатываются автоматически в короткие сроки.',
  'withdrawal-text-24': 'Начать вывод средств',
  'withdrawal-text-24-1': 'Подробная инструкция по выводу средств',
  'withdrawal-text-25': 'Увеличьте доходность ваших инвестиций!',
  'withdrawal-text-26':
    'Вы можете получить ещё больше прибыли, реинвестируя ваши средства.',
  'withdrawal-text-27': 'Реинвестируйте ваш депозит и заработанную прибыль.',
  'withdrawal-text-28':
    'Начните инвестиционный период с большим стартовым капиталом.',
  'withdrawal-text-29': 'Наблюдайте за ежедневным увеличением вашего дохода.',
  'withdrawal-text-30': 'Реинвестировать сейчас',
  'withdrawal-text-31': 'Выберите нужный кошелек для вывода',
  'withdrawal-text-32':
    'Чтобы вывести средства на ваш кошелек, добавьте его в ваш аккаунт и создайте запрос на вывод.',

  'withdrawal-text-33': 'Вывести на этот',
  'withdrawal-text-34': 'Отправить заявку',
  'withdrawal-text-35': 'Информация:',
  'withdrawal-text-36':
    'Показанный баланс - это ваш доступный баланс, а не общая сумма средств. Если у вас есть активные инвестиции, они не учитываются, так как заблокированы до окончания инвестиционного периода.',
  'withdrawal-text-37': 'Сумма для вывода',
  'personal-text-1': 'Личные данные',
  'personal-text-2': 'Двухфакторная аутентификация',
  'personal-text-3': 'Пароль',
  'personal-text-4': 'Верификация',
  'personal-text-5': 'Ваше имя',
  'personal-text-6': 'Введите правильное имя',
  'personal-text-7': 'Ваша фамилия',
  'personal-text-8': 'Введите правильную фамилию',
  'personal-text-9': 'Отмена',
  'personal-text-10': 'Сохранить',
  'personal-text-11': 'Включить/отключить',
  'personal-text-12':
    'Двухфакторная аутентификация (2FA) по электронной почте включена по умолчанию для всех аккаунтов и не может быть отключена.',
  'personal-text-13':
    'Для изменения вашего адреса электронной почты в CoinFuze следует обратиться в поддержку. После обработки вашего запроса поддержкой вы получите уведомление о том, что ваш адрес успешно изменен на новый.',
  'personal-text-14':
    'Важно помнить, что для предотвращения мошенничества и защиты ваших данных поддержка может запросить дополнительную информацию или документы для верификации вашей личности.',
  'personal-text-15':
    '2FA отключена. Мы советуем всем клиентам включить двухфакторную аутентификацию (2FA) для дополнительной безопасности.',
  'personal-text-16': 'Отключить',
  'personal-text-17': 'Включить',
  'personal-text-18': 'Аккаунт',
  'personal-text-19':
    'Эта функция позволяет использовать Google Authenticator или ваш аккаунт Telegram для двухфакторной аутентификации. В дополнение к подтверждению от вашей электронной почты, вам также потребуется предоставить безопасный код от Google Authenticator или Telegram для выполнения определенных действий в вашем личном кабинете. Эти действия включают: вывод средств, изменение личной информации, а также пароля от аккаунта. Это обеспечивает дополнительную защиту от входа хакеров в ваш аккаунт, даже если они смогли получить ваш пароль.',
  'personal-text-20':
    'Эта функция позволяет использовать Google Authenticator или совместимое приложение, такое как FreeOTP, для двухфакторной аутентификации. В дополнение к вашему имени пользователя и паролю, вам также потребуется предоставить шестизначный код безопасности для входа на этот сайт. Код безопасности меняется каждые 30 секунд. Это обеспечивает дополнительную защиту от входа хакеров в ваш аккаунт, даже если они смогли получить ваш пароль.',
  'personal-text-21': 'Получить',
  'personal-text-22':
    'Скачайте и установите Google Authenticator на ваш смартфон или компьютер.',
  'personal-text-23': 'Используйте один из следующих шагов:',
  'personal-text-24':
    'Официальное приложение Google Authenticator для Android, iOS и BlackBerry. Не забудьте синхронизировать часы вашего устройства с временем сервера. Сдвиг времени на вашем устройстве может привести к тому, что вы не сможете войти в свой личный кабинет.',
  'personal-text-25': 'и',
  'personal-text-26':
    'Не забудьте синхронизировать часы вашего устройства с временем сервера. Сдвиг времени на вашем устройстве может привести к невозможности входа в ваш личный кабинет.',
  'personal-text-27': 'Настройка.',
  'personal-text-28': 'Отсканируйте следующий QR-код в',
  'personal-text-29': 'Ключ настройки',
  'personal-text-30':
    'Если вы хотите изменить ключ, отключите двухфакторную аутентификацию. Когда вы попытаетесь снова её включить, будет сгенерирован новый ключ.',
  'personal-text-31': 'Активировать двухфакторную аутентификацию.',
  'personal-text-33': 'Код безопасности',
  'personal-text-34': 'Ваш пароль',
  'personal-text-35': 'Включить',
  'personal-text-37': 'Текущий пароль',
  'personal-text-38': 'Новый пароль',
  'personal-text-39': 'Подтвердите новый пароль',
  'personal-text-41': 'Загружаемые документы',
  'personal-text-42': 'Загрузить',
  'personal-text-43': 'Допустимый формат',
  'personal-text-44': 'Ваши документы находятся на проверке',
  'personal-text-45': 'Ваши документы были проверены',
  'personal-text-46': 'Ваши документы',
  'personal-text-47': 'Активные сессии',
  'personal-text-48': 'Сессии браузера',
  'personal-text-49':
    'Управляйте и выходите из своих активных сессий в других браузерах и устройствах.',
  'personal-text-50':
    'При необходимости вы можете выйти из всех других сессий браузера на всех ваших устройствах. Ниже перечислены некоторые из ваших последних сессий; однако этот список может быть не исчерпывающим. Если вы считаете, что ваш аккаунт был скомпрометирован, вам также следует обновить свой пароль.',
  'personal-text-51': 'Это устройство',
  'personal-text-51-1': 'выйти',
  'personal-text-52': 'Выйти из других сессий браузера',
  'personal-text-53': 'Водительское удостоверение',
  'personal-text-54': 'Банковская квитанция',
  file: 'Файл не выбран',
  'personal-text-55':
    'Для смены пароля пройдите двухфакторную аутентификацию (2FA)',
  'personal-text-56':
    'Для изменения имени и фамилии пройдите двухфакторную аутентификацию (2FA)',
  'personal-text-57':
    'Двухфакторная аутентификация (2FA) в настоящее время включена для этого аккаунта',
  'personal-text-58':
    'Пожалуйста, скопируйте этот одноразовый экстренный пароль и храните его в безопасном месте. Если у вас нет доступа к устройству двухфакторной аутентификации, вы можете использовать этот пароль вместо обычного кода безопасности. Этот пароль уничтожается сразу после использования. Мы рекомендуем вам распечатать этот пароль и хранить его в безопасном и доступном месте, например, в вашем кошельке или сейфе.',
  'personal-text-59': 'Отключение двухфакторной аутентификации.',
  'personal-text-60':
    'Пожалуйста, введите код двухфакторной аутентификации из.',
  'personal-text-61': 'Отправить',
  'personal-text-62': 'Отмена',
  'text-63': 'Перейти в личный кабинет',
  'text-64': 'Вернуться',
  'text-65': 'Уведомления',
  'text-66': 'Отметить все уведомления как прочитанные',
  'text-67': 'Отметить',
  'text-68': 'Ваша регистрация почти завершена',
  'text-69':
    'Вы должны завершить процесс регистрации перед инвестированием. Для этого нажмите кнопку "Завершить" и подтвердите свою регистрацию по электронной почте, отправленной на вашу почту.',
  'text-70': 'Завершить регистрацию',
  'text-71': 'Выбрать язык',

  'cookies-1': 'Просматривая этот сайт, вы соглашаетесь с',
  'cookies-2': 'нашей политикой конфиденциальности',
  ok: 'Ок',
  'table-1': 'Логин',
  'table-2': 'Бонус (Депозит)',
  'table-3': 'Бонус (Прибыль)',
  'table-4': 'Количество рефералов',
  'table-5': 'Дата регистрации',
  'table-6': 'Тип транзакции',
  'table-7': 'Дата',
  'table-8': 'Сумма',
  'table-9': 'Статус транзакции',
  'table-10': 'ID Транзакции',

  'profile-window-1': 'Логин:',
  'profile-window-2': 'Телефон:',
  'profile-window-3': 'Язык:',
  'profile-window-4': 'Тема:',
  'profile-window-5': 'Пригласить друзей:',

  'two-factor-1': 'Двухфакторная валидация',
  'two-factor-2':
    'Введите код подтверждения, отправленный на вашу электронную почту:',
  'two-factor-3': 'Отправить повторно',
  'two-factor-4': 'Проверить',
  'two-factor-5': 'Проверено',
  'two-factor-6': 'Отправить новый код ещё раз через:',
  'two-factor-7': 'Введите код подтверждения, отправленный на ваш',
  'two-factor-8': 'Введите код',

  back: 'Вернуться к управлению планами',
  'back-to-site': 'Вернуться на сайт',
  'back-to-news': 'Вернуться к новостям',
  support: 'поддержка',
  title: 'Заголовок',
  step: 'Шаг',
  'page-not-found': 'Страница не найдена',
  home: 'Главная',

  'staking-1': 'Вернуться к управлению планами',
  'staking-2': 'криптовалютный стейкинг',
  'staking-3':
    'При необходимости вы можете выйти из всех других сессий браузера на всех ваших устройствах. Ниже перечислены некоторые из ваших последних сессий; однако этот список может быть не исчерпывающим. Если вы считаете, что ваш аккаунт был скомпрометирован, вам также следует обновить свой пароль.',
  'staking-4': 'Перейти',
  'staking-4-1': 'Активировать',

  'staking-5': 'Стейкинг',
  'staking-6': 'Финансовый результат:',
  'staking-7': 'Дата последней прибыли:',
  'staking-8': 'Депозит на стейкинг:',
  'staking-9': 'Уровни',
  'staking-10': 'Последнее инвестирование:',
  'staking-11': 'уровень',
  'staking-12': 'Объем оборота',
  'staking-13': 'Бонусы',
  'staking-14': 'Пройдено',
  'staking-15': 'Ваш уровень',
  'staking-16': 'Закрыть',
  'staking-17': 'Уровень стейкинга:',
  'staking-18': 'Активен',
  'staking-19': 'Неактивен',
  'staking-20': 'Завершен',
  'staking-table-1': 'Инвестиционный план',
  'staking-table-2': 'Дата начала',
  'staking-table-3': 'Дата окончания',
  'staking-table-4': 'Стартовый капитал',
  'staking-table-5': 'Текущий результат, USDT',
  'staking-table-6': 'Прибыль, USDT',
  'staking-table-7': 'Комиссия, USDT',
  'staking-table-8': 'Статус плана',
  'staking-table-9': 'Статус',
  'plan-modal-1': 'Инвестиционный план',
  'plan-modal-2': 'Дата начала',
  'plan-modal-3': 'Дата окончания',
  'plan-modal-4': 'Стартовый капитал',
  'plan-modal-5': 'Финансовый результат',
  'plan-modal-6': 'Прибыль в usdt',
  'plan-modal-7': 'Введите сумму стартового капитала:',
  'plan-modal-8': 'Активировать',

  'plan-page-1': 'Активные планы',
  'plan-page-2': 'Доступные планы',
  done: 'Выполнено',
  purpose: 'Цель',
  'password-reset': 'Пароль был отправлен на ваш емейл',

  'table-next': 'Далее',
  'table-prev': 'Пред.',

  'tr_status-1': 'В Ожидании',
  'tr_status-2': 'В Ручную',
  'tr_status-3': 'Отклонено',
  'tr_status-4': 'Одобрено',
  'tr-type-0': 'Все',
  'tr-type-1': 'Пополнение',
  'tr-type-2': 'Вывод',
  'tr-type-3': 'Дивиденды',
  'tr-type-4': 'Бонус за пополнение',
  'tr-type-5': 'Реферальная выплата',
  'tr-type-6': 'Бонус за оборот команды',
  'tr-type-7': 'Активация плана',
  'tr-type-8': 'Закрытие плана',
  'tr-type-9': 'Штраф',
  'tr-type-10': 'Комиссия',

  'document-1': 'ID-карта',
  document: 'Паспорт',

  'deposit-benefits-1': 'Зарабатывай вместе с',
  'deposit-benefits-2': 'Средняя ежемесячная прибыль',
  'deposit-benefits-3': 'Годовая прибыль',
  'deposit-benefits-4': 'Полностью автоматизированная система торговли.',
  'deposit-benefits-5':
    'Возможность реинвестирования и гибкая система выбора сроков инвестиций.',

  'staking-1-1': 'Инвестиционный продукт',
  'staking-1-2':
    'разработан и ориентирован на пользователей, которые хотят получать стабильный пассивный доход, простой в управлении инструмент, а также ежедневные начисления и вывод прибыли.',
  'staking-1-3':
    'продукт с возможностью развития и роста доходности, за счет 5 уровней инструмента.',

  copied: 'скопировано',

  'email-verification-1': 'Подтвердите свою учетную запись.',
  'email-verification-2':
    'При регистрации на вашу почту было отправлено письмо с кнопкой для подтверждения. Если по какой-то причине вы не можете найти письмо, нажмите на кнопку ниже и мы отправим письмо повторно.',
  'email-verification-3':
    'Для подтверждения вашей учетной записи нажмите на кнопку в письме.',
  'email-verification-4':
    'После подтверждения электронной почты вы можете войти в свой аккаунт и начать использовать все функции CoinFuze.',
  'email-verification-5': 'Отправить',
  'email-verification-6': 'Отправить повторно письмо с подтверждением',

  'def-file-input': 'Файл не выбран',
  'personal-text-16-1': 'Подключите ваш telegram',
  'new-notification': 'Новое уведомление',
  'personal-text-32':
    'Чтобы убедиться, что все настроено правильно, введите в поле ниже код безопасности, полученый в Google Authenticator и пароль вашей учетной записи. Если код и пароль верны, нажмите кнопку "Включить" и функция двухфакторной аутентификации будет активирована.',
  'personal-label-1': 'Код безопасности',
  'personal-label-2': 'Ваш пароль',

  'withdrawal-text-24-2': 'Часто задаваемые вопросы в разделе Вывод средств'
}
