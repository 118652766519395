import {
  email,
  sameAs,
  required,
  minLength,
  maxLength,
  //   url,
  //   or,
  // numeric,
  decimal
} from 'vuelidate/lib/validators'

export default {
  firstNameRule: {
    required,
    minLength: minLength(2),
    maxLength: maxLength(15),
    nameValidFormat: (val) => /^(?!.*([^\W\d_])\1\1)[\p{L}\s-]*$/gu.test(val),
    twoCharactersInRow: (val) => !/(.)\1{2}/u.test(val)
  },
  lastNameRule: {
    required,
    minLength: minLength(2),
    maxLength: maxLength(15),
    nameValidFormat: (val) => /^(?!.*([^\W\d_])\1\1)[\p{L}\s-]*$/gu.test(val),
    twoCharactersInRow: (val) => !/(.)\1{2}/u.test(val)
    // nameValidFormat: val => /^(?!.*([^\W\d_])\1\1)[\p{L}\s-]{1,15}$/gu.test(val)
  },
  loginRule: { required },
  emailRule: { required, email },
  phoneRule: { required },
  passwordRule: {
    required,
    minLength: minLength(8),
    maxLength: maxLength(35),
    validPassword: (val) =>
      /^(?=.*\d)(?=.*[a-z][^\\s])(?=.*[A-Z]|[!@#$%^&*])(?!.*[\s]).{8,35}$/g.test(
        val
      )
  },
  confirmPasswordRule: {
    required,
    sameAsPassword: sameAs('password')
  },
  promo: {
    // required
    // numeric,
    // maxLength: maxLength(7),
    // minLength: minLength(7)
  },

  countryRule: {
    name: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(2)
    }
  },
  paysumRule: {
    decimal,
    minimumValue: (val) => parseFloat(val) >= 100
  }
}
