export default {
  'withdrawal-ph-1': 'Cüzdan Kimliği',
  'withdrawal-ph-2': 'Çekilecek tutar',
  'confirm-modal-1': 'Eylemi onayla',
  'confirm-modal-2': 'Onayla',
  'confirm-modal-3': 'İptal',
  copy: 'Kopyala',
  download: 'İndir',
  'modal-p-text-1': 'Yatırım Planları ile Çalışma Hakkında Detaylı Talimatlar',
  'modal-p-text-2':
    'Hesabınıza yatırılan mevduatın hesabınıza alınmasından sonra, fonlarınızı seçilen plana yatırabilirsiniz.',
  'modal-p-text-3':
    'Yatırım planınızı etkinleştirmek için aşağıdaki talimatları izleyin.',
  'modal-p-text-4':
    "Kişisel hesabınızda, 'Plan Yönetimi' sayfasına gidin. Burada tüm yatırım ürünleri hakkında detaylı bilgiler bulacaksınız. Bu, yatırım dönemleri, hizmet ücretleri ve plan karlılığı dahil.",
  'modal-p-text-5':
    "İstediğiniz planı etkinleştirmek için 'Seç' düğmesine tıklayın, planı başlatmak istediğiniz başlangıç sermayesi miktarını girin ve 'Etkinleştir' düğmesine tıklayın.",
  'modal-p-text-6':
    "'Otomatik Yeniden Yatırım' özelliği ile fonlarınızı otomatik olarak uzun bir süre boyunca yatırabilir ve ek avantajlar elde edebilirsiniz.",
  'modal-p-text-7':
    'Yatırım planını başlattıktan hemen sonra, seçeneği tek bir tıklamayla etkinleştirebilir veya devre dışı bırakabilirsiniz.',
  'modal-p-text-8':
    'Tebrikler, başarılı bir şekilde bir yatırım planı başlattınız!',
  'modal-p-text-9':
    "Bu ayarı isteğinize göre değiştirebilirsiniz, hatta yürütme süresi başladıktan sonra bile. Bu seçeneği etkinleştirdiyseniz, yatırım planı süresinin sona ermesinden sonra, tüm sermaye ve kar dahil olmak üzere tüm kazançlar otomatik olarak yeniden yatırılır. Bu seçenek devre dışı bırakılmışsa, otomatik yeniden yatırım gerçekleşmez ve tüm sermaye ve kar Vade Dışı Bakiye'ye aktarılır.",

  update: 'Yükle',
  'update-avatar': 'Avatarınızı yükleyin',
  save: 'Kaydet',
  'modal-d-text-1': 'CoinFuze Üzerine Para Yatırma Talimatları',
  'modal-d-text-2': "'Hesabım' sayfasına gidin.",
  'modal-d-text-3':
    "'Para Yatır' işlemine başlamak için 'Bakiye Ekle' düğmesine tıklayın.",
  'modal-d-text-4':
    'Paranın gönderilmesi için benzersiz bir cüzdan kimliği sağlanacaktır. Lütfen ödeme yaparken bu kimliği kullandığınızdan emin olun.',
  'modal-d-text-5':
    'Cüzdan kimliğinin solundaki QR kodunu da kullanma seçeneğiniz bulunmaktadır. Verilerin daha kolay girilmesi için cep telefonunuzla tarayabilirsiniz.',
  'modal-d-text-6':
    'Belirtilen cüzdana para gönderdikten sonra, paranız otomatik olarak Bakiyenize yatırılacaktır. Bu genellikle 1 dakika ile 2 saat arasında sürer, ancak zaman çerçeveleri blockchain ve diğer faktörlere bağlı olarak değişebilir.',
  'modal-d-text-7':
    "'Onaylanan yatırımlar, 'Yatırım Geçmişi' sekmesinde sayfanın altında görüntülenir. Burada tüm yatırım taleplerinizi takip edebilirsiniz.",
  'modal-d-text-8':
    'CoinFuze hesabınıza güvenli ve kolay bir şekilde para yatırmak için bu kılavuzu izleyin. Herhangi bir sorunuz veya daha fazla yardıma ihtiyacınız varsa, lütfen destek servisimize başvurun.',

  'modal-w-text-1':
    'Para çekme işlemini başlatmak için şu talimatları izleyin:',
  'modal-w-text-2': "'Hesabım'ın sol menüsünde 'Para Çekme' sayfasına gidin.",
  'modal-w-text-3': 'Çekmek istediğiniz USDT miktarını girin.',
  'modal-w-text-4':
    'Fonları almak istediğiniz USDT TRC20 cüzdanınızı belirtin.',
  'modal-w-text-5':
    'İşlemi onaylamak için e-posta adresinize gönderilecek onay kodunu ve etkinleştirilmişse iki faktörlü kimlik doğrulama (2FA) şifresini girin.',
  'modal-w-text-6':
    'Onaylandıktan sonra, para çekme talebiniz işleme alınır. Bu genellikle sistem yüküne bağlı olarak birkaç dakikadan 24 saate kadar sürebilir.',
  'modal-w-text-7':
    'Lütfen minimum çekim tutarının 10 USDT olduğunu ve küçük bir ağ ücretinin olduğunu unutmayın. Ayrıca, fonları çekebilmek için yatırım planınızın süresinin dolduğundan ve karın Serbest Bakiyenize aktarıldığından emin olmalısınız.',
  validation: {
    nameValidFormat: '{attribute}, rakam veya semboller içermemelidir',
    twoCharactersInRow:
      '{attribute}, üst üste iki veya daha fazla aynı karakter içeremez',
    email: 'E-posta formatı geçersiz',
    required: '{attribute} zorunludur',
    minValue: '{attribute} min değeri {min} değerinden küçük olamaz',
    maxValue: '{attribute} max değeri {max} değerinden büyük olamaz',
    minLength: '{attribute}, en az {min} karakter olmalıdır',
    maxLength: '{attribute}, en fazla {max} karakter olmalıdır',
    validFormat: '{attribute}, ilk harfi büyük ve sadece harf içermelidir',
    validPassword: '{attribute}, en az bir rakam ve büyük harf içermelidir',
    sameAsPassword: 'Şifreler aynı olmalıdır',
    numeric: '{attribute} geçersiz. Bir rakam olmalıdır',
    minimumValue: "Tutar 100 USDT'den az olmamalıdır"
  },
  Country: 'Ülke',
  'phone-input-placeholder': 'Telefon numaranızı girin',
  'withdrawal-instruction': 'Detaylı çekim talimatları',
  'withdrawal-instruction-text-1':
    'Çekim işlemine başlamak için bu talimatları izleyin:',
  'withdrawal-instruction-text-2':
    "Kişisel kabininizde sol menüde 'Fon Çekme' sayfasına gidin.",
  'withdrawal-instruction-text-3': 'Çekmek istediğiniz USDT miktarını girin.',
  'withdrawal-instruction-text-4':
    'Fonları almak istediğiniz USDT TRC20 cüzdanınızı belirtin.',
  'withdrawal-instruction-text-5':
    'Onay kodunu girmek için e-postanıza gönderilecek onay kodunu ve iki faktörlü kimlik doğrulamanın (2FA) şifre kodunu (varsa) girerek işlemi onaylayın.',
  'withdrawal-instruction-text-6':
    'Onaylandıktan sonra çekim talebiniz işleme alınacaktır. Bu genellikle sistem yüküne bağlı olarak birkaç dakikadan 24 saate kadar sürebilir.',
  'withdrawal-instruction-text-7':
    'Lütfen çekmek için minimum miktarın 10 USDT olduğunu ve küçük bir ağ ücreti olduğunu unutmayın. Ayrıca, yatırım planınızın süresinin dolmuş olması ve karların Free Balance bakiyesine yatırılmış olması gerektiğini unutmamalısınız ki çekim yapabilesiniz.',
  'plans-instruction-text-1':
    'Yatırım hesabınıza kredi verildikten sonra seçtiğiniz plana yatırım yapabilirsiniz.',
  'plans-instruction-text-2':
    'Yatırım planınızı etkinleştirmek için aşağıdaki talimatları izleyin.',
  'plans-instruction-text-3':
    "Kişisel kabininizde 'Yatırım Planları' sayfasına gidin. Burada her yatırım planı hakkında detaylı bilgiler bulacaksınız. Bu, yatırım şartları, komisyonlar ve planın getirisidir.",
  'plans-instruction-text-4':
    "İstenen planı etkinleştirmek için 'Artı'ya tıklayın ve 'Başlangıç Sermayesi' alanına başlamak istediğiniz miktarı girin ve 'Etkinleştir' düğmesine tıklayın.",
  'plans-instruction-text-5':
    "'Otomatik Yeniden Yatırım' özelliği ile fonlarınızı uzun vadeli otomatik olarak yatırabilir ve ek faydalar elde edebilirsiniz.",
  'plans-instruction-text-6':
    'Yatırım planını başlattıktan hemen sonra bu seçeneği tek bir tıklamayla etkinleştirebilir veya devre dışı bırakabilirsiniz.',
  'plans-instruction-text-7':
    'Tebrikler, bir yatırım planı başarıyla başlattınız!',
  'plans-instruction-text-8':
    'Bu ayarı istediğiniz gibi değiştirebilirsiniz, hatta süresi başlamışken bile. Bu seçeneği etkinleştirdiyseniz, Yatırım Planı Süresi sonunda tüm sermaye, tüm karlar otomatik olarak yeniden yatırılır. Bu seçenek devre dışı bırakıldığında otomatik yeniden yatırım yoktur ve tüm sermaye karlarla birlikte Free Balance bakiyesine aktarılır.',
  'plans-instruction-text-9':
    "'Otomatik Yeniden Yatırım'ı etkinleştirdiyseniz, planın tüm sermayesi ve karları süresi dolana kadar sürekli olarak yeniden yatırılır, bu seçeneği devre dışı bırakana kadar. ",
  'plans-instruction-text-10':
    'Bu şekilde, sermayenizi minimal maliyetle uzun vadeli olarak tamamen otomatik olarak yatırabilirsiniz. Tüm sermayeyi çekmek için bu özelliği devre dışı bırakmanız gerekmektedir. Özellik devre dışı bırakıldığında, tüm sermaye, getirilerle birlikte mevcut yatırım planının sonunda Free Balance bakiyesine aktarılır. Bu seçeneği kullanırken ek ücretler alınmaz.',
  'plans-instruction-text-11':
    'Tüm Çalışan ve Tamamlanan Yatırım Planları ile ilgili bilgilere sayfanın altında bulunan "Plan Geçmişi" bölümünde ulaşabilirsiniz.',
  'popup-instruction-title-2': 'Adım adım rehber',
  'popup-instruction-text-1': 'CoinFuze Para Yatırma Talimatları',
  'popup-instruction-text-2':
    "Kişisel hesabınızda 'Para Yatırma' sayfasına gidin.",
  'popup-instruction-text-3':
    "Para yatırma işlemine başlamak için 'Para Yatırma' düğmesine tıklayın.",
  'popup-instruction-text-4':
    'Fonları göndermek için benzersiz bir cüzdan kimliği verilecektir. Lütfen bir ödeme yaparken bu tanımlayıcıyı kullanmış olduğunuzdan emin olun.',
  'popup-instruction-text-5':
    'Ayrıca, cüzdan kimliğinin solunda bulunan QR kodunu kullanma seçeneğiniz de bulunmaktadır. Cep telefonunuzla tarama yaparak veri girişini daha kolay hale getirebilirsiniz.',
  'popup-instruction-text-6':
    'Belirtilen cüzdana fon gönderdikten sonra, fonlar otomatik olarak Bakiyenize aktarılacaktır. Bu genellikle 1 dakika ile 2 saat arasında sürer, ancak zaman aralığı blok zinciri ve diğer faktörlere bağlı olarak değişebilir.',
  'popup-instruction-text-7':
    "Onaylanmış olan yatırımlar, 'Para Yatırma Geçmişi' sekmesi altında sayfanın altında görüntülenir. Burada tüm yatırım taleplerinizi takip edebilirsiniz.",
  'popup-instruction-text-8':
    'CoinFuze üzerinde yatırımınızı kolay ve güvenli bir şekilde yapmak için bu talimatları izleyin. Herhangi bir sorunuz varsa veya ek yardıma ihtiyacınız varsa, lütfen destek ekibimizle iletişime geçmekten çekinmeyin.',
  error: 'Hata',
  'partnership-text-31': 'Yönlendirmelerin Sayısı',
  'partnership-text-30': 'Yatırılan Tutar',
  'autorization-tab-1': 'Yetkilendirme',
  'autorization-tab-2': 'Kayıt',
  'autorization-login': 'Giriş',
  'autorization-username': 'Kullanıcı Adı/Email/Telefon',
  'autorization-password': 'Şifre',
  'autorization-forgot': 'Şifrenizi mi unuttunuz?',
  'autorization-btn-login': 'Hesaba giriş yap',
  'autorization-name': 'Ad',
  'autorization-surname': 'Soyad',
  'autorization-repeat-password': 'Şifreyi Onayla',
  'autorization-ypass': 'Şifreniz',
  'autorization-newpass': 'Yeni Şifreniz',
  'autorization-email': 'Email',
  'autorization-tel': 'Telefon',
  'autorization-promo': 'Promosyon Kodu',
  'autorization-text-0': 'Şunları kabul ediyorum:',
  'autorization-text-1': 'Kullanım Koşulları,',
  'autorization-text-2': 'Gizlilik Politikası',
  'autorization-text-3': 'AML Politikası',
  'autorization-text-4': '18 yaşında veya daha büyüküm',
  'autorization-text-5': 'Hizmet Şartları',
  'autorization-text-6': 'Beni Hatırla',
  'autorization-text-7': 'Hesabınız yok mu?',
  'autorization-text-8': 'Giriş yapın ve size e-postanıza talimat gönderelim:',
  'autorization-text-10': 'Zaten bir hesabınız mı var?',
  'autorization-text-11': 'Giriş yapın',
  'autorization-btn-register': 'Kayıt',
  'autorization-btn-cancel': 'Şifreyi sıfırla',
  'autorization-status-2': 'Belirtilen e-posta/telefon bulunamadı.',
  'autorization-status-1': 'Geçersiz giriş veya şifre',
  'autorization-status-name': 'Geçersiz ad',
  'autorization-status-surname': 'Geçersiz soyad',
  'autorization-status-password':
    'Şifre en az 7 karakter uzunluğunda olmalı ve en az 1 büyük ve küçük harf içermelidir.',
  'autorization-status-repeat-password': 'Şifreler uyuşmuyor',
  'autorization-status-email': 'Geçersiz e-posta formatı',
  'autorization-status-phone': 'Geçersiz telefon numarası',
  'autorization-status-agree-rules': 'Koşulları onaylayın',
  'autorization-status-agree-age': 'Koşulları onaylayın',
  'validation-1': 'Kaydolmak için 18 yaşından büyük olmalısınız',
  'validation-2': 'Kaydolmak için koşulları kabul etmelisiniz',
  'header-item-1': 'Toplam Bakiye',
  'header-item-2': 'Ücretsiz Bakiye:',
  'header-item-3': 'Aktif Bakiye:',
  'non-message': 'Yeni bildirim yok',
  'message-btn': 'Tüm bildirimleri göster',
  'header-item-4': 'Kişisel Bilgiler',
  'header-item-5': 'Arkadaşları Davet Et',
  'header-item-6': 'Ekle',
  'header-item-7': 'Çıkış Yap',
  'footer-item-2': 'Kullanım Koşulları',
  'footer-item-3': 'Gizlilik Politikası',
  'footer-item-1': 'AML Politikası',
  'sidebar-item-1': 'Kontrol Paneli',
  'sidebar-item-2': 'Bankacılık',
  'sidebar-item-3': 'Plan Yönetimi',
  'sidebar-item-4': 'Bağlı Ortaklık Programı',
  'sidebar-item-5': 'Çekimler',
  'sidebar-item-6': 'Kişisel Bilgiler',
  'sidebar-item-7': 'Haberler',
  'sidebar-item-8': 'Hesaptan Çık',
  'dashboard-subtitle-1': 'Hesap',
  'dashboard-subtitle-2': 'Finans',
  'dashboard-subtitle-3': 'Yatırımlar',
  'dashboard-subtitle-4': 'Bağlı Ortaklık Programı',
  'dashboard-subtitle-5': 'Dönem',
  'dashboard-subtitle-6': 'Mevcut Sonuç',
  'dashboard-subtitle-7': 'Finans Sonucu',
  'dashboard-text-1': 'Ücretsiz Bakiye',
  'dashboard-text-2': 'Aktif Fonlar',
  'dashboard-text-3': 'Doğrulandı',
  'dashboard-text-4': 'Katılımcı olduğunuz tarih',
  'dashboard-text-5': 'Toplam Kar Planları',
  'dashboard-text-6': 'Referans Geliri',
  'dashboard-text-7': 'Yatırım',
  'dashboard-text-8': 'Çekimler',
  'dashboard-text-9': 'Toplam Kar',
  'dashboard-text-10': 'Aktif Planlar',
  'dashboard-text-10-1': 'Kapanmış Planlar',
  'dashboard-text-11': 'Yeniden yatırım yapılacak planlar',
  'dashboard-text-12': 'Ortak Sayısı',
  'dashboard-text-13': '1. Seviye Ortaklar',
  'dashboard-text-14': '2. Seviye Ortaklar',
  'dashboard-text-15': '3. Seviye Ortaklar',
  'dashboard-text-16': 'Dönem',
  'dashboard-text-17': 'gün',
  'dashboard-text-18': 'Henüz haber yok',
  'dashboard-text-19': 'Şu anda',
  'dashboard-text-20': 'Grafik için yeterli veri yok',
  'dashboard-text-21': '- Aktif yatırımlar yok',
  'dashboard-text-22': 'Tüm haberleri oku',
  'dashboard-text-22-1': 'Tümünü Oku',
  'dashboard-text-23': 'Yatırım Planı',
  progressLabel: 'Yatırım planı ilerlemesi',
  daysLabel: 'Tamamlanma için Günler',
  'deposit-subtitle-1-1': 'Yatırım yapmadan önce,',
  'deposit-subtitle-1-2': 'Hesabınızı kaydedin.',
  'deposit-subtitle-1-3': 'Aksi takdirde, Para Yatıramazsınız.',
  'deposit-subtitle-2': 'CoinFuze ile Para Kazanın',
  'deposit-text-1':
    'Bakiyenizi yatırmak için, aşağıdaki "Hesabınıza Para Yatırın" düğmesine tıklayın.',
  'deposit-text-2':
    'Tüm para yatırma işlemleriniz, isteklerinizin durumunu takip edebileceğiniz "Geçmiş" bölümünde kaydedilir.',
  'deposit-text-3':
    "Bir istek 'Tamamlandı' durumunu aldığında, fonlar otomatik olarak 'Ücretsiz Bakiye'nize eklenir.",
  'deposit-text-4':
    'Şu anda sistemimiz için en istikrarlı kripto para birimi olan Tether USDT kullanıyoruz.',
  'deposit-text-5':
    'Bir depozito yaptıktan sonra CoinFuze ile işbirliği yapmanın tüm avantajlarının tadını çıkarabilirsiniz.',
  'deposit-text-6': 'Aylık ortalama kar - 6.8%*',
  'deposit-text-7': 'Yıllık kar - 98.7%',
  'deposit-text-8': 'Tam otomatik işlem sistemi',
  'deposit-text-9':
    'Yeniden yatırım imkanı ve yatırım sürelerini seçme esnekliği',
  'deposit-text-10':
    '*Karlılık verileri 2024 açık istatistiklerinden alınmıştır.',
  'deposit-text-11': 'Nasıl para yatırılacağına dair detaylı talimat',
  'deposit-text-12':
    'Para yatırma konusunda bir sorunuz mu var? Destek sohbetinde sorabilirsiniz.',
  'deposit-text-13':
    "Bakiyenizi yatırmak için, 'Hesabınıza Para Yatırın' düğmesine tıklayın.",
  'deposit-text-14':
    "Tüm para yatırma işlemleriniz, aşağıdaki 'Geçmiş' bölümünde kaydedilir ve isteklerinizin durumunu takip edebilirsiniz.",
  'deposit-text-15':
    "Bir istek 'Tamamlandı' durumunu aldığında, fonlar otomatik olarak 'Ücretsiz Bakiye'nize eklenir.",
  'deposit-text-16':
    'Şu anda sistemimiz için en istikrarlı kripto para birimi olan Tether USDT kullanıyoruz.',
  'deposit-text-17':
    'Bir depozito yaptıktan sonra CoinFuze ile işbirliği yapmanın tüm avantajlarının tadını çıkarabilirsiniz.',
  'deposit-text-18':
    'Para yatırma konusunda bir sorunuz mu var? Destek sohbetinde sorabilirsiniz.',
  'deposit-btn': 'Hesaba Para Yatır',
  notification: 'Kopyalandı',
  'deposit-popup-text-1': 'Kripto Para',
  'deposit-popup-text-2': 'Para Yatırma Kripto Parası',
  'deposit-popup-text-3': 'Bakiyeniz:',
  'deposit-popup-text-4': 'Ağ',
  'deposit-popup-text-5': 'Not:',
  'deposit-popup-text-6-1': 'Sadece',
  'deposit-popup-text-6-2':
    'bu para yatırma adresine gönderin. Coinler, ağ onaylarından sonra otomatik olarak alınır',
  'deposit-popup-text-6-3': '6 ağ onayı gerekiyor.',
  'deposit-popup-text-6-4':
    'Akıllı kontrat adresleri desteklenmiyor (bizimle iletişime geçin).',
  'deposit-popup-text-7': 'Para Yatırma Adresi:',
  'deposit-popup-text-8': 'Kopyala',
  history: 'Geçmiş',
  'deposit-history-text-1': 'Para Yatırma Tarihi',
  'deposit-history-text-2': 'Ödeme Yöntemi',
  'deposit-history-text-3': 'Para Yatırma Miktarı',
  'deposit-history-text-4': 'İşlem Durumu',
  'deposit-history-text-5': 'İşlem',
  'deposit-history-text-6': 'Henüz işlem yok',
  'deposit-history-text-7': 'Gösterilen',
  'deposit-history-text-8': 'ile',
  'plans-subtitle-1': 'Yatırım Yönetimi',
  'plans-subtitle-2': 'İlk Yatırımlar',
  'plans-text-1':
    'Bu bölümde, kullanılabilir planlardan planlar oluşturabilir veya tüm aktif veya kapalı planlarınızın finansal sonuçlarını görüntüleyebilirsiniz.',
  'plans-text-2':
    'Seçebileceğiniz altı farklı yatırım planı bulunmaktadır. Her biri yatırım süresi, beklenen getiri ve Coinfuze hizmet ücretleri konusunda farklı şartlara sahiptir.',
  'plans-text-3':
    "Seçilen planı etkinleştirmek için kullanıcılar belirli bir minimum depozito yapmalı ve 'Etkinleştir' düğmesine tıklamalıdır.",
  'plans-text-4':
    'Yatırım Planları ile nasıl çalışılacağına dair detaylı talimatlar.',
  'plans-text-5': 'Yatırımlar bölümüne ilişkin sıkça sorulan sorular.',
  'plans-text-6':
    "'Yatırım yapmaya başlamak için bir depozito yapmanız gerekiyor. Bunu sol menüdeki 'Depozito' sayfasında veya aşağıdaki düğme ile yapabilirsiniz.",
  'plans-text-7':
    'Depozitonuz işlendikten sonra seçtiğiniz yatırım planını etkinleştirin.',
  'plans-text-8':
    'CoinFuze, otomatik yeniden yatırım özelliği sunar. Bu, plan süresinin sonunda önceki dönemin tüm karlarının yeni planın başlangıç sermayesine otomatik olarak aktarıldığı anlamına gelir.',
  'plans-text-9': 'Depozito yap',
  'plans-text-10': 'Yatırım planını etkinleştir',
  'plans-text-11': 'Evet',
  'plans-text-12': 'Hayır',
  'plans-text-13': 'Aktif planlar',
  'plans-text-14': 'Başlangıç Tarihi',
  'plans-text-15': 'Bitiş Tarihi',
  'plans-text-16': 'Başlangıç sermayesi',
  'plans-text-17': '% finansal sonuç',
  'plans-text-18': 'Kar (USDT cinsinden)',
  'plans-text-19': 'Plan Durumu',
  'plans-text-20': 'Yeniden yatır',
  'plans-text-21': 'Kaydet',
  'plans-text-22': 'Kullanılabilir planlar',
  'plans-text-23': 'Min. Yatırım',
  'plans-text-24': 'Süre',
  'plans-text-25': 'Getiri',
  'plans-text-26': 'kadar',
  'plans-text-27': 'Komisyon',
  'plans-text-28': 'Başlangıç sermayesi miktarını girin:',
  'plans-text-29': 'Etkinleştir',
  'plans-text-30': 'Minimum miktarı girin',
  'plans-text-31': 'Ad',
  'plans-text-32': 'Başlangıç Sermayesi',
  'plans-text-33': 'Mevcut Sonuç',
  'plans-text-34': 'Kar',
  'plans-text-35': 'Komisyon',
  'plans-text-36': 'Aktif',
  'plans-text-37': 'Tamamlandı',
  'plans-text-38': 'Yatırım Planı',
  'plans-text-39': 'gün',
  'plans-text-40': 'Seç',
  'plans-text-41': 'Durum:',
  'partnership-subtitle-1': 'Referans Programı',
  'partnership-subtitle-2': 'Referans Bağlantınız',
  'partnership-subtitle-3': 'Davet Edilen Referanslar',
  'partnership-subtitle-4': '1. Seviye - Depozito ve Kar Üzerinden Komisyon',
  'partnership-subtitle-5': '2. Seviye - Depozito ve Kar Üzerinden Komisyon',
  'partnership-subtitle-6': '3. Seviye - Depozito ve Kar Üzerinden Komisyon',
  'partnership-text-1':
    'Arkadaşlarınıza bizi tavsiye edin ve kişisel hesabınızda bonuslar kazanın.',
  'partnership-text-2': 'Referans programı aracılığıyla pasif gelir elde edin.',
  'partnership-text-3': 'Referans bağlantınızı kopyalayın.',
  'partnership-text-4': 'Arkadaşlarınıza gönderin.',
  'partnership-text-5': 'Doldurdukları depozitolardan bonuslar alın',
  'partnership-text-6': 'Kazandıkları karlardan bonuslar alın',
  'partnership-text-7':
    "Her CoinFuze üyesi, dünyanın dört bir yanındaki arkadaşlarına göndermek için benzersiz bir referans bağlantısı ve promosyon kodu alır. Bu bağlantıyı kopyalayın ve CoinFuze'ye davet etmek istediğiniz kişilere gönderin.",
  'partnership-text-8': 'SİZİN PROMO',
  'partnership-text-9':
    'Referans bağlantınızı veya promosyon kodunuzu paylaşın.',
  'partnership-text-10': 'Tıklama istatistiklerini inceleyin',
  'partnership-text-11':
    'Bağlantınız aracılığıyla kaydolan kişi sayısını takip edin',
  'partnership-text-12': 'Kârlarınızın büyüdüğünü izleyin',
  'partnership-text-13': 'Toplam Davet Edilenler',
  'partnership-text-14': '1. Seviye Ortaklar',
  'partnership-text-15': '2. Seviye Ortaklar',
  'partnership-text-16': '3. Seviye Ortaklar',
  'partnership-text-17': 'Toplam Kazanç:',
  'partnership-text-18': 'Ara',
  'partnership-text-19':
    "Zaten bağlantınız aracılığıyla kaydolan ve ne kadar bonus aldığınızı görenleri görün. Üç seviyeli bir referans programımız olduğunu unutmayın. Sadece CoinFuze'ye davet ettiğiniz kişiler için değil, aynı zamanda yönlendirmelerinizin davet ettiği üyeler için de bonuslar alırsınız.",
  'partnership-text-20': 'Referansınızın adını girin',
  'partnership-text-21': 'Depozito Bonusları',
  'partnership-text-22': 'Kar Bonusları',
  'partnership-text-23': 'Ortaklık Geliri',
  'partnership-text-24': 'Kayıt Tarihi',
  'partnership-text-25': 'Böyle bir referans yok',
  'partnership-text-26': 'Ara...',
  'partnership-text-27': 'Referans yok',
  'partnership-text-28': 'Aşama',
  'partnership-text-29': 'Ciro Miktarı',
  'partnership-text-32': 'Bonuslar',
  'partnership-text-33': 'Geçilen',
  'partnership-text-34': 'Seviyeniz',
  'partnership-text-35': 'Kapat',
  'partnership-text-36': 'Referans bağlantısı:',
  'partnership-text-37': 'Promonuz:',
  'partnership-text-38': 'Aktif Takım Ciro:',
  'partnership-text-39': 'Depozito ve Kar Üzerinden Komisyon:',
  'partnership-text-40': 'Referanslar',
  'partnership-text-41': 'Takım Bonusları',
  'news-text-1': 'Henüz haber yok.',
  'news-text-2': 'Daha fazlasını oku',
  'news-text-3': 'Tanıtım materyalleri',
  'news-text-4':
    'Tanıtım materyallerimizi arkadaşlarınızla paylaşın ve kazanın!',
  'news-text-5':
    'Yeni CoinFuze üyelerini çekmek için kullanabileceğiniz tanıtım materyallerinin bir seçimini yaptık.',
  'news-text-6': 'Arkadaşlarınızla paylaşın ve kazanın!',
  'news-text-7':
    "Her CoinFuze üyesi, dünyanın dört bir yanındaki arkadaşlarına göndermek için benzersiz bir referans bağlantısı ve promosyon kodu alır. Bu bağlantıyı kopyalayın ve CoinFuze'ye davet etmek istediğiniz kişilere gönderin.",
  'news-text-8': 'SİZİN PROMO',
  'news-text-9': 'Bağlı kuruluş bağlantınızı veya promosyon kodunuzu paylaşın.',
  'news-text-10': 'Üzerinde tıklama istatistiklerini inceleyin',
  'news-text-11': 'Bağlantınız aracılığıyla kaydolan kişi sayısını takip edin',
  'news-text-12': 'Kârlarınızın büyüdüğünü izleyin.',
  'news-text-13': "%6'ya kadar referans bonusları kazanın",
  'withdrawal-text-1': 'Çekim Tarihi',
  'withdrawal-text-2': 'Ödeme Yöntemi',
  'withdrawal-text-3': 'Çekim Miktarı',
  'withdrawal-text-4': 'Fon çekmek için kimlik doğrulamanız gerekiyor',
  'withdrawal-text-5':
    'Çekim yapmadan önce kimlik belgelerinizi yükleyerek kimlik doğrulama sürecini tamamlamanız gerekmektedir.',
  'withdrawal-text-6': 'Doğrula',
  'withdrawal-text-7': 'Çekim',
  'withdrawal-text-8': 'E-posta adresinize gönderilen onay kodunu girin:',
  'withdrawal-text-9': 'Kodu girin',
  'withdrawal-text-10': 'Yeniden kod gönder:',
  'withdrawal-text-11': 'Kodu doğrula',
  'withdrawal-text-12': 'Yeniden gönder',
  'withdrawal-text-13': 'Doğrulandı',
  'withdrawal-text-14': 'Yeniden kod gönder:',
  'withdrawal-text-15': 'Mobil uygulama kodunu girin',
  'withdrawal-text-16': 'Kodu girin',
  'withdrawal-text-17': 'Bilgi:',
  'withdrawal-text-18':
    'Serbest bakiye, çekim için kullanılabilir bakiyenizdir. Eğer aktif yatırımlarınız varsa, bunlar yatırım süresi sona erene kadar kilitlendiğinden hesaplanmazlar.',
  'withdrawal-text-19': 'Fon Çekme',
  'withdrawal-text-19-1': 'Çekilebilir miktar:',
  'withdrawal-text-19-2': 'Çekim Başlat',
  'withdrawal-text-20':
    'Bu sayfada kripto para cüzdanınıza fon çekebilirsiniz. Çekimi mümkün olduğunca hızlı ve kolay hale getirmek için talimatları takip edin.',
  'withdrawal-text-21':
    'Çekimler, depozitolar gibi kripto para birimiyle yapılmaktadır',
  'withdrawal-text-22':
    'Çekim işlemi, kripto para ağında farklı hızlarda gerçekleştiğinden biraz zaman alabilir.',
  'withdrawal-text-23':
    'Çekim talepleri otomatik olarak kabul edilir ve kısa bir süre içinde işlenir.',
  'withdrawal-text-24': 'Çekimi başlat',
  'withdrawal-text-24-1': 'Ayrıntılı çekim talimatı',
  'withdrawal-text-25': 'Yatırımlarınızın karlılığını artırın!',
  'withdrawal-text-26':
    'Fonlarınızı yeniden yatırarak daha fazla kar elde edebilirsiniz.',
  'withdrawal-text-27':
    'Depozitonuzu ve elde ettiğiniz karları yeniden yatırın.',
  'withdrawal-text-28':
    'Yatırım dönemine büyük bir başlangıç sermayesiyle başlayın.',
  'withdrawal-text-29': 'Gelirinizin günlük olarak arttığını izleyin.',
  'withdrawal-text-30': 'Şimdi yeniden yatırın',
  'withdrawal-text-31': 'Çekim için doğru cüzdanı seçin',
  'withdrawal-text-32':
    'Fonları cüzdanınıza çekmek için onu hesabınıza ekleyin ve çekim talebi oluşturun.',
  'withdrawal-text-33': 'Buna çek',
  'withdrawal-text-34': 'Başvuruyu Gönder',
  'withdrawal-text-35': 'Bilgi:',
  'withdrawal-text-36':
    'Gösterilen bakiye, toplam fonlarınız değil, kullanılabilir bakiyenizdir. Eğer aktif yatırımlarınız varsa, bunlar yatırım süresi sona erene kadar hesaba katılmazlar.',
  'withdrawal-text-37': 'Çekim Miktarı',
  'personal-text-1': 'Kişisel veriler',
  'personal-text-2': 'İki faktörlü kimlik doğrulama',
  'personal-text-3': 'Parola',
  'personal-text-4': 'Doğrulama',
  'personal-text-5': 'Adınız',
  'personal-text-6': 'Doğru ilk adı girin',
  'personal-text-7': 'Soyadınız',
  'personal-text-8': 'Doğru soyadı girin',
  'personal-text-9': 'İptal',
  'personal-text-10': 'Kaydet',
  'personal-text-11': 'Etkinleştir/devre dışı bırak',
  'personal-text-12':
    'E-posta ile İki Faktörlü Kimlik Doğrulama (2FA) varsayılan olarak tüm hesaplar için etkinleştirilmiştir ve devre dışı bırakılamaz.',
  'personal-text-13':
    "CoinFuze'de e-posta adresinizi değiştirmek için destek ile iletişime geçmelisiniz. Destek talebinizi işledikten sonra yeni bir e-posta adresine başarıyla değiştirildiğinize dair bir bildirim alacaksınız.",
  'personal-text-14':
    'Veri koruma ve dolandırıcılığı önlemek için, destek kimliğinizi doğrulamak için ek bilgi veya belgeler isteyebilir.',
  'personal-text-15':
    '2FA devre dışı. Tüm müşterilere ek güvenlik için İki Faktörlü Kimlik Doğrulamayı (2FA) etkinleştirmelerini öneriyoruz.',
  'personal-text-16': 'Devre dışı bırak',
  'personal-text-17': 'Etkinleştir',
  'personal-text-18': 'Hesap',
  'personal-text-19':
    "Bu özellik, Google Authenticator veya Telegram hesabınızı iki faktörlü kimlik doğrulama için kullanmanıza olanak tanır. E-posta ile onayın yanı sıra, belirli işlemleri gerçekleştirmek için Google Authenticator veya Telegram'dan bir güvenlik kodu sağlamanız gerekecektir. Bu işlemler arasında Fon çekme, kişisel bilgileri değiştirme ve hesap parolası yer almaktadır. Bu, hesabınıza giriş yapmaya çalışan hackerlara karşı ek koruma sağlar, hatta şifrenize sahip olabilirlerse bile.",
  'personal-text-20':
    'Bu özellik, Google Authenticator veya FreeOTP gibi uyumlu bir uygulamayı (Google Authenticator veya benzeri bir uygulamayı) iki faktörlü kimlik doğrulama için kullanmanıza olanak tanır. Kullanıcı adı ve şifrenizin yanı sıra her 30 saniyede bir değişen altı haneli bir güvenlik kodu sağlamanız gerekecektir. Bu, hesabınıza giriş yapmaya çalışan hackerlara karşı ek koruma sağlar, hatta şifrenize sahip olabilirlerse bile.',
  'personal-text-21': 'Al',
  'personal-text-22':
    "Google Authenticator'u akıllı telefonunuz veya bilgisayarınıza indirin ve kurun.",
  'personal-text-23': 'Aşağıdaki adımlardan birini kullanın:',
  'personal-text-24':
    'Resmi Google Authenticator uygulamasıdır (Android, iOS ve BlackBerry). Cihazınızın saati zaman sunucusu ile senkronize ettiğinizden emin olun. Cihazınızda zaman kayması, kişisel hesabınıza giriş yapamamanıza neden olabilir.',
  'personal-text-25': 've',
  'personal-text-26':
    'Cihazınızın saati zaman sunucusu ile senkronize ettiğinizden emin olun. Cihazınızdaki zaman kayması, kişisel hesabınıza giriş yapamamanıza neden olabilir.',
  'personal-text-27': 'Kurulum.',
  'personal-text-28': 'Aşağıdaki QR kodunu tarayın',
  'personal-text-29': 'Özelleştirme Anahtarı',
  'personal-text-30':
    'Anahtarı değiştirmek isterseniz, iki faktörlü kimlik doğrulamayı devre dışı bırakın. Tekrar açmaya çalıştığınızda yeni bir anahtar oluşturacaktır.',
  'personal-text-31': 'İki faktörlü kimlik doğrulamayı etkinleştirin.',
  'personal-text-33': 'Güvenlik Kodu',
  'personal-text-34': 'Şifreniz',
  'personal-text-35': 'Etkinleştir',
  'personal-text-37': 'Geçerli Şifre',
  'personal-text-38': 'Yeni Şifre',
  'personal-text-39': 'Yeni şifreyi onayla',
  'personal-text-41': 'İndirilebilir belgeler',
  'personal-text-42': 'İndir',
  'personal-text-43': 'Kabul edilebilir format',
  'personal-text-44': 'Belgeleriniz doğrulama altındadır',
  'personal-text-45': 'Belgeleriniz doğrulandı',
  'personal-text-46': 'Belgeleriniz',
  'personal-text-47': 'Etkin oturumlar',
  'personal-text-48': 'Tarayıcı Oturumları',
  'personal-text-49':
    'Diğer tarayıcılarda ve cihazlarda etkin oturumlarınızı yönetin ve oturumları kapatın.',
  'personal-text-50':
    'Gerektiğinde tüm diğer tarayıcı oturumlarınızı ve cihazlarınızı oturumları kapatabilirsiniz. Bazı son oturumlarınız aşağıda listelenmiştir; ancak bu liste eksiksiz olmayabilir. Hesabınızın tehlikede olduğunu düşünüyorsanız, şifrenizi de güncellemeniz gerekebilir.',
  'personal-text-51': 'Bu cihaz',
  'personal-text-51-1': 'çıkış Yap',

  'personal-text-52': 'Diğer tarayıcı oturumlarından çıkış yapın',
  'personal-text-53': 'Sürücü belgesi',
  'personal-text-54': 'Banka makbuzu',
  file: 'Dosya seçilmedi',
  document: 'Pasaport',
  'personal-text-55':
    'Şifrenizi değiştirmek için iki faktörlü kimlik doğrulamadan (2FA) geçin',
  'personal-text-56':
    'Adınızı ve soyadınızı değiştirmek için iki faktörlü kimlik doğrulamadan (2FA) geçin.',
  'personal-text-57':
    'Bu hesapta şu anda İki Faktörlü Kimlik Doğrulama (2FA) etkinleştirilmiştir.',
  'personal-text-58':
    'Lütfen bu tek kullanımlık acil şifreyi kopyalayın ve güvenli bir yerde saklayın. İki faktörlü kimlik doğrulama cihazınıza erişiminiz yoksa, bu şifreyi düzenli güvenlik kodunuzun yerine kullanabilirsiniz. Bu şifre kullanıldığında hemen imha edilir. Bu şifreyi yazdırmanızı ve cüzdanınız veya kasaya koyabileceğiniz güvenli ve erişilebilir bir yerde saklamanızı öneririz.',
  'personal-text-59': 'İki faktörlü kimlik doğrulamayı devre dışı bırakma.',
  'personal-text-60': 'Lütfen iki faktörlü kimlik doğrulama kodunu girin.',
  'personal-text-61': 'Gönder',
  'personal-text-62': 'İptal',
  'text-63': 'Kişisel hesaba git',
  'text-64': 'Geri dön',
  'text-65': 'Bildirimler',
  'text-66': 'Tüm bildirimleri okundu olarak işaretle',
  'text-67': 'İşaretle',
  'text-68': 'Kaydınız neredeyse tamamlandı',
  'text-69':
    "Yatırım yapmadan önce kayıt işlemini tamamlamanız gerekmektedir. Bunun için 'Tamamla' düğmesine tıklayın ve e-postanıza gönderilen e-postayı onaylayarak kaydınızı tamamlayın.",
  'text-70': 'Kaydı tamamla',
  'text-71': 'Dil seç',

  'cookies-1': 'Bu siteyi kullanarak,',
  'cookies-2': 'gizlilik politikamızı kabul etmiş olursunuz.',
  ok: 'Tamam',
  'table-1': 'Giriş',
  'table-2': 'Bonus (Yatırım)',
  'table-3': 'Bonus (Kazanç)',
  'table-4': 'Yönlendirmelerin Sayısı',
  'table-5': 'Kayıt Tarihi',
  'table-6': 'İşlem Türü',
  'table-7': 'Tarih',
  'table-8': 'Yatırım Miktarı',
  'table-9': 'İşlem Durumu',
  'table-10': 'İşlem Kimliği',
  'profile-window-1': 'Giriş:',
  'profile-window-2': 'Telefon:',
  'profile-window-3': 'Dil:',
  'profile-window-4': 'Tema:',
  'profile-window-5': 'Arkadaşları Davet Et:',
  'two-factor-1': 'İki Faktörlü Doğrulama',
  'two-factor-2': 'E-postanıza gönderilen onay kodunu girin:',
  'two-factor-3': 'Yeniden Gönder',
  'two-factor-4': 'Doğrula',
  'two-factor-5': 'Doğrulandı',
  'two-factor-6': 'Yeniden yeni kodu gönder:',
  'two-factor-7': 'E-postanıza gönderilen onay kodunu girin:',
  'two-factor-8': 'Kodu Gir',
  back: 'Plan Yönetimine Geri Dön',
  'back-to-site': 'Siteye Geri Dön',
  'back-to-news': 'Haberlere Geri Dön',
  support: 'Destek',
  title: 'Başlık',
  step: 'Adım',
  'page-not-found': 'Sayfa Bulunamadı',
  home: 'Ana Sayfa',

  'staking-1': 'Plan Yönetimine Geri Dön',
  'staking-2': 'Kripto Staking',
  'staking-3':
    'Diğer tarayıcı oturumlarınızdan ve cihazlarınızdan çıkış yapabilirsiniz. Ancak, son oturumlarınızın bazıları aşağıda listelenmiştir; yine de bu liste eksiksiz olmayabilir. Hesabınızın tehlikede olduğunu düşünüyorsanız, şifrenizi de güncellemelisiniz.',
  'staking-4': 'Geçmek',
  'staking-4-1': 'Aktive etmek',
  'staking-5': 'Staking',
  'staking-6': 'Finans Sonucu:',
  'staking-7': 'Son kârın tarihi:',
  'staking-8': "Staking'de Yatırım:",
  'staking-9': 'Seviyeler',
  'staking-10': 'Son Yatırım:',
  'staking-11': 'Seviye',
  'staking-12': 'Dönüş Tutarı',
  'staking-13': 'Bonuslar',
  'staking-14': 'Geçildi',
  'staking-15': 'Sizin Seviyeniz',
  'staking-16': 'Kapat',
  'staking-17': 'Staking Seviyesi:',
  'staking-18': 'Aktif',
  'staking-19': 'Aktif Değil',
  'staking-20': 'Tamamlandı',
  'staking-table-1': 'Yatırım Planı',
  'staking-table-2': 'Başlangıç Tarihi',
  'staking-table-3': 'Bitiş Tarihi',
  'staking-table-4': 'Başlangıç Sermayesi',
  'staking-table-5': 'Mevcut Sonuç, USDT',
  'staking-table-6': 'Kar, USDT',
  'staking-table-7': 'Komisyon, USDT',
  'staking-table-8': 'Plan Durumu',

  'plan-modal-1': 'Yatırım Planı',
  'plan-modal-2': 'Başlangıç Tarihi',
  'plan-modal-3': 'Bitiş Tarihi',
  'plan-modal-4': 'Başlangıç Sermayesi',
  'plan-modal-5': 'Finansal Sonuç',
  'plan-modal-6': 'Kar (USDT cinsinden)',
  'plan-modal-7': 'Başlangıç sermayesi miktarını girin:',
  'plan-modal-8': 'Aktifleştir',

  'plan-page-1': 'Aktif Planlar',
  'plan-page-2': 'Kullanılabilir Planlar',

  done: 'Tamamlandı',
  purpose: 'Amaç',
  'password-reset': 'Şifre e-posta adresinize gönderildi',

  'table-next': 'Sonraki',
  'table-prev': 'Önceki',

  'tr_status-1': 'Beklemede',
  'tr_status-2': 'Manuel',
  'tr_status-3': 'Reddedildi',
  'tr_status-4': 'Onaylandı',
  'tr-type-0': 'Tüm',
  'tr-type-1': 'Yatırma',
  'tr-type-2': 'Çekme',
  'tr-type-3': 'Temettüler',
  'tr-type-4': 'Yatırma Bonusu',
  'tr-type-5': 'Referans Ödemesi',
  'tr-type-6': 'Takım Ciro Bonusu',
  'tr-type-7': 'Plan Aktivasyonu',
  'tr-type-8': 'Plan Kapanışı',
  'tr-type-9': 'Ceza',
  'tr-type-10': 'Komisyon',
  'document-1': 'Kimlik kartı',

  'deposit-benefits-1': 'Kazanın',
  'deposit-benefits-2': 'Ortalama aylık kar',
  'deposit-benefits-3': 'Yıllık kar',
  'deposit-benefits-4': 'Tamamen otomatikleştirilmiş ticaret sistemi.',
  'deposit-benefits-5':
    'Yeniden yatırım fırsatı ve yatırım koşullarını seçme konusunda esnek bir sistem.',

  'staking-1-1': 'Yatırım ürünü',
  'staking-1-2':
    'Stabil pasif gelir elde etmek isteyen kullanıcılara yönelik tasarlanmış, yönetmesi kolay bir araç, aynı zamanda günlük tahakkuklar ve kar çekme.',
  'staking-1-3':
    'Araçtaki 5 seviye sayesinde gelişme ve karlılık artışı potansiyeline sahip bir ürün.',

  copied: 'kopyalandı',
  'email-verification-1': 'Hesabınızı doğrulayın.',
  'email-verification-2':
    'Kayıt olurken onay butonu içeren bir doğrulama e-postası e-posta adresinize gönderildi. Eğer herhangi bir sebepten dolayı e-postayı bulamıyorsanız, aşağıdaki butona tıklayın ve biz e-postayı yeniden göndereceğiz.',
  'email-verification-3':
    'Hesabınızı doğrulamak için e-postadaki butona tıklayın.',
  'email-verification-4':
    "E-postanızı doğruladıktan sonra hesabınıza giriş yapabilir ve CoinFuze'un tüm özelliklerini kullanmaya başlayabilirsiniz.",
  'email-verification-5': 'Gönder',
  'email-verification-6': 'Doğrulama e-postasını yeniden gönder',

  'def-file-input': 'Dosya seçilmedi',
  'personal-text-16-1': "Telegram'ınızı bağlayın",
  'new-notification': 'Yeni bildirim',
  'personal-text-32':
    'Her şeyin doğru şekilde yapılandırıldığından emin olmak için, aşağıdaki alana Google Authenticator da aldığınız güvenlik kodunu ve hesap şifrenizi girin. Kod ve şifre doğruysa, Etkinleştir düğmesine tıklayın ve iki faktörlü kimlik doğrulama etkinleştirilecektir.',
  'personal-label-1': 'Güvenlik kodu',
  'personal-label-2': 'Şifreniz',
  'withdrawal-text-24-2': 'Para Çekme bölümünde Sıkça Sorulan Sorular'
}
