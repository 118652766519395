export default {
  'withdrawal-ph-1': 'Portemonnee-ID',
  'withdrawal-ph-2': 'Opnamebedrag',
  'confirm-modal-1': 'Bevestig de actie',
  'confirm-modal-2': 'Bevestigen',
  'confirm-modal-3': 'Annuleren',
  copy: 'Kopiëren',
  download: 'Downloaden',
  delete: 'Verwijderen',
  'modal-p-text-1':
    'Gedetailleerde instructies over het werken met Investering Plannen',
  'modal-p-text-2':
    'Zodra de storting op uw account is bijgeschreven, kunt u uw geld investeren in het geselecteerde plan.',
  'modal-p-text-3':
    'Volg de onderstaande instructies om uw Investeringsplan te activeren.',
  'modal-p-text-4':
    "Ga in uw persoonlijke account naar de pagina 'Planbeheer'. Hier vindt u gedetailleerde informatie over alle beleggingsproducten. Dit omvat investeringsperioden, servicekosten, evenals planrendement.",
  'modal-p-text-5':
    "Om het gewenste plan te activeren, klikt u op de knop 'Selecteren', voert u het bedrag aan initiële kapitaal in waarmee u het plan wilt starten, en klikt u op de knop 'Activeren'.",
  'modal-p-text-6':
    "Met de functie 'Automatisch Herinvesteren' kunt u automatisch uw geld investeren voor een lange periode en extra voordelen behalen.",
  'modal-p-text-7':
    'Direct na het starten van het investeringsplan kunt u de optie met één klik activeren of deactiveren.',
  'modal-p-text-8':
    'Gefeliciteerd, u heeft succesvol een investeringsplan gelanceerd!',
  'modal-p-text-9':
    'U kunt deze instelling naar eigen inzicht wijzigen, zelfs nadat de uitvoeringsperiode is begonnen. Als u deze optie heeft geactiveerd, wordt bij het verstrijken van de termijn van het investeringsplan automatisch al het kapitaal, inclusief alle winst, opnieuw geïnvesteerd. Als deze optie is uitgeschakeld, vindt er geen automatische herinvestering plaats en wordt al het kapitaal met winst bijgeschreven op de Vrije Balans.',

  update: 'Uploaden',
  'update-avatar': 'Upload uw avatar',
  save: 'Opslaan',
  'modal-d-text-1': 'Instructies voor het Storten van Geld op CoinFuze',
  'modal-d-text-2': "Ga naar de 'Storten' pagina in je account.",
  'modal-d-text-3':
    "Om het stortingsproces te starten, klik op de knop 'Geld toevoegen'.",
  'modal-d-text-4':
    'Je krijgt een unieke portefeuille-identificatie om geld naar toe te sturen. Zorg ervoor dat je deze identificatie gebruikt bij het maken van de betaling.',
  'modal-d-text-5':
    'Je hebt ook de mogelijkheid om de QR-code te gebruiken die links van de portefeuille-identificatie staat. Je kunt deze scannen met je mobiele telefoon voor gemakkelijkere invoer van gegevens.',
  'modal-d-text-6':
    'Nadat je geld naar de opgegeven portefeuille hebt gestuurd, wordt het geld automatisch bijgeschreven op je saldo. Dit duurt meestal tussen de 1 minuut en 2 uur, maar de tijd kan variëren afhankelijk van de blockchain en andere factoren.',
  'modal-d-text-7':
    "Bevestigde stortingen worden onderaan de pagina weergegeven in het tabblad 'Stortingsgeschiedenis'. Hier kun je al je stortingsverzoeken volgen.",
  'modal-d-text-8':
    'Volg deze handleiding om eenvoudig en veilig geld te storten op je CoinFuze-account. Als je vragen hebt of meer hulp nodig hebt, neem dan contact op met onze ondersteuning.',

  'modal-w-text-1': 'Om het opnameproces te starten, volg deze instructies:',
  'modal-w-text-2':
    "Ga in het linkermenu van je account naar de 'Opname' pagina.",
  'modal-w-text-3': 'Voer het bedrag in USDT in dat je wilt opnemen.',
  'modal-w-text-4':
    'Geef je USDT TRC20-portefeuille op waar je de fondsen wilt ontvangen.',
  'modal-w-text-5':
    'Bevestig de transactie door de bevestigingscode in te voeren die naar je e-mail wordt gestuurd, evenals de wachtwoordcode (2FA) voor tweefactorauthenticatie indien ingeschakeld.',
  'modal-w-text-6':
    'Na bevestiging wordt je opnameverzoek verwerkt. Dit duurt meestal van enkele minuten tot 24 uur, afhankelijk van de belasting van het systeem.',
  'modal-w-text-7':
    'Let op dat het minimum opnamebedrag 10 USDT is en er een kleine netwerkvergoeding is. Daarnaast moet je ervoor zorgen dat de looptijd van je investeringsplan is verstreken en de winst is bijgeschreven op je Vrije Balans om fondsen op te nemen.',
  validation: {
    nameValidFormat: '{attribute} mag geen cijfers of symbolen bevatten',
    twoCharactersInRow:
      '{attribute} mag niet meer dan twee identieke tekens achter elkaar hebben',
    email: 'Ongeldig e-mailformaat',
    required: '{attribute} is verplicht',
    minValue: 'Minimale waarde voor {attribute} mag niet minder zijn dan {min}',
    maxValue: 'Maximale waarde voor {attribute} mag niet groter zijn dan {max}',
    minLength: '{attribute} moet minimaal {min} tekens bevatten',
    maxLength: '{attribute} mag niet meer dan {max} tekens bevatten',
    validFormat:
      '{attribute} moet de eerste hoofdletter bevatten en mag alleen tekens bevatten',
    validPassword:
      '{attribute} moet ten minste één cijfer en een hoofdletter bevatten',
    sameAsPassword: 'Wachtwoorden moeten identiek zijn',
    numeric: '{attribute} is ongeldig. Moet een cijfer zijn',
    minimumValue: 'Het bedrag mag niet minder zijn dan 100 USDT'
  },
  Country: 'Land',
  'phone-input-placeholder': 'Voer uw telefoonnummer in',

  'withdrawal-instruction': 'Gedetailleerde instructies voor opname',
  'withdrawal-instruction-text-1':
    'Volg deze instructies om het opnameproces te starten:',
  'withdrawal-instruction-text-2':
    'Ga in uw persoonlijke account naar de pagina "Fondsen opnemen" in het linker menu.',
  'withdrawal-instruction-text-3':
    'Voer het bedrag in USDT in dat u wilt opnemen.',
  'withdrawal-instruction-text-4':
    'Geef uw USDT TRC20-portemonnee op waarop u de fondsen wilt ontvangen.',
  'withdrawal-instruction-text-5':
    'Bevestig de transactie door de bevestigingscode in te voeren die naar uw e-mail wordt gestuurd, evenals de wachtwoordcode (2FA) van tweestapsverificatie als u deze hebt aangesloten.',
  'withdrawal-instruction-text-6':
    'Na bevestiging wordt uw opnameverzoek verwerkt. Dit duurt meestal van enkele minuten tot 24 uur, afhankelijk van de systeembelasting.',
  'withdrawal-instruction-text-7':
    'Houd er rekening mee dat het minimumbedrag om op te nemen 10 USDT is en er een kleine netwerkvergoeding is. Bovendien moet u ervoor zorgen dat uw investeringsplan is verlopen en de winst is bijgeschreven op Free Balance om op te nemen.',
  'plans-instruction-text-1':
    'Zodra de storting op uw account is bijgeschreven, kunt u uw fondsen investeren in het gekozen plan.',
  'plans-instruction-text-2':
    'Volg de onderstaande instructies om uw investeringsplan te activeren.',
  'plans-instruction-text-3':
    "Ga naar de pagina 'Investment Plans' in uw persoonlijke account. Hier vindt u gedetailleerde informatie over elk investeringsplan. Dit omvat de investeringsvoorwaarden, commissies en ook het rendement van het plan.",
  'plans-instruction-text-4':
    "Om het gewenste plan te activeren, klikt u op 'Plus' en voert u het gewenste startkapitaal in het veld 'Startkapitaal' in en klikt u op de knop 'Activeren'.",
  'plans-instruction-text-5':
    "Met de functie 'Automatisch herinvesteren' kunt u automatisch uw fondsen voor de lange termijn investeren en extra voordelen behalen.",
  'plans-instruction-text-6':
    'Direct na het starten van een investeringsplan kunt u de optie met één klik activeren of deactiveren.',
  'plans-instruction-text-7':
    'Gefeliciteerd, u heeft met succes een investeringsplan gelanceerd!',
  'plans-instruction-text-8':
    'U kunt deze instelling naar wens wijzigen, zelfs wanneer de vervaldatum al is begonnen. Als u deze optie heeft geactiveerd, worden aan het einde van de looptijd van het investeringsplan automatisch al het kapitaal, inclusief alle winsten, opnieuw geïnvesteerd. Als deze optie is uitgeschakeld, is er geen automatische herinvestering en wordt al het kapitaal met winsten bijgeschreven op de Free Balance.',
  'plans-instruction-text-9':
    'Als u "Automatisch herinvesteren" heeft geactiveerd, wordt al het kapitaal en alle winsten van het plan permanent opnieuw geïnvesteerd na afloop, tenzij u deze optie uitschakelt.',
  'plans-instruction-text-10':
    'Hierdoor kunt u uw kapitaal volledig automatisch voor de lange termijn investeren tegen minimale kosten. Om het volledige kapitaal uit te betalen, moet u deze functie uitschakelen. Zodra de functie is uitgeschakeld, wordt het volledige kapitaal, inclusief de opbrengsten, aan het einde van de huidige looptijd van het investeringsplan bijgeschreven op de Free Balance. Er worden geen extra kosten in rekening gebracht bij het gebruik van deze optie.',
  'plans-instruction-text-11':
    "Alle informatie over het werken met actieve en voltooide investeringsplannen is te vinden in de sectie 'Plan History' onderaan de pagina.",
  'popup-instruction-title-2': 'Stapsgewijze handleiding',
  'popup-instruction-text-1': 'CoinFuze Stortingsinstructies',
  'popup-instruction-text-2':
    "Ga naar de pagina 'Deposit' in uw persoonlijke account.",
  'popup-instruction-text-3':
    "Om het stortingsproces te starten, klikt u op de knop 'Storten'.",
  'popup-instruction-text-4':
    'U krijgt een unieke portemonnee-ID om geld naar toe te sturen. Zorg ervoor dat u deze identifier gebruikt bij het doen van een betaling.',
  'popup-instruction-text-5':
    'U heeft ook de optie om de QR-code te gebruiken die links van de portemonnee-ID staat. U kunt deze scannen met uw mobiele telefoon voor gemakkelijker invoeren van gegevens.',
  'popup-instruction-text-6':
    'Nadat u geld naar de opgegeven portemonnee heeft gestuurd, wordt het geld automatisch bijgeschreven op uw Balans. Dit duurt meestal tussen de 1 minuut en 2 uur, maar de tijd kan variëren afhankelijk van de blockchain en andere factoren.',
  'popup-instruction-text-7':
    "Bevestigde stortingen worden weergegeven onder het tabblad 'Stortingsgeschiedenis' onderaan de pagina. Hier kunt u al uw stortingsverzoeken bijhouden.",
  'popup-instruction-text-8':
    'Volg deze instructies om gemakkelijk en veilig uw storting op CoinFuze te doen. Als u vragen heeft of extra hulp nodig heeft, neem dan gerust contact op met ons ondersteuningsteam.',
  error: 'Fout',
  'partnership-text-31': 'Aantal verwijzingen',
  'partnership-text-30': 'Bedrag van stortingen',
  'autorization-tab-1': 'Autorisatie',
  'autorization-tab-2': 'Registratie',
  'autorization-login': 'Login',
  'autorization-username': 'Login/Email/Telefoon',
  'autorization-password': 'Wachtwoord',
  'autorization-forgot': 'Wachtwoord vergeten?',
  'autorization-btn-login': 'Inloggen op account',
  'autorization-name': 'Naam',
  'autorization-surname': 'Achternaam',
  'autorization-repeat-password': 'Bevestig wachtwoord',
  'autorization-ypass': 'Uw wachtwoord',
  'autorization-newpass': 'Uw wachtwoord',
  'autorization-email': 'Email',
  'autorization-tel': 'Telefoon',
  'autorization-promo': 'Promocode',
  'autorization-text-0': 'Ik accepteer de',
  'autorization-text-1': 'Algemene Voorwaarden,',
  'autorization-text-2': 'Privacybeleid',
  'autorization-text-3': 'AML-beleid',
  'autorization-text-4': 'Ik ben 18 jaar of ouder',
  'autorization-text-5': 'Gebruiksvoorwaarden',
  'autorization-text-6': 'Onthoud mij',
  'autorization-text-7': 'Heeft u nog geen account?',
  'autorization-text-8':
    'Voer uw login in en we sturen instructies naar uw e-mail:',
  'autorization-text-10': 'Heeft u al een account?',
  'autorization-text-11': 'Inloggen',
  'autorization-btn-register': 'Registratie',
  'autorization-btn-cancel': 'Wachtwoord opnieuw instellen',
  'autorization-status-2':
    'De opgegeven e-mail/telefoonnummer is niet gevonden.',
  'autorization-status-1': 'Ongeldige login of wachtwoord',
  'autorization-status-name': 'Ongeldige voornaam',
  'autorization-status-surname': 'Ongeldige achternaam',
  'autorization-status-password':
    'Wachtwoord moet minimaal 7 tekens lang zijn en minimaal 1 hoofdletter en kleine letter bevatten',
  'autorization-status-repeat-password': 'Wachtwoorden komen niet overeen',
  'autorization-status-email': 'Ongeldige e-mailindeling',
  'autorization-status-phone': 'Ongeldig telefoonnummer',
  'autorization-status-agree-rules': 'Bevestig voorwaarden',
  'autorization-status-agree-age': 'Bevestig voorwaarden',
  'validation-1': 'Om te registreren, moet u ouder zijn dan 18 jaar',
  'validation-2':
    'Om te registreren, moet u de algemene voorwaarden accepteren',
  'header-item-1': 'Totaal saldo',
  'header-item-2': 'Vrij saldo:',
  'header-item-3': 'Actief saldo:',
  'non-message': 'Geen nieuwe meldingen',
  'message-btn': 'Toon alle meldingen',
  'header-item-4': 'Persoonlijke informatie',
  'header-item-5': 'Vrienden uitnodigen',
  'header-item-6': 'Toevoegen',
  'header-item-7': 'Uitloggen',
  'footer-item-2': 'Gebruiksvoorwaarden',
  'footer-item-3': 'Privacybeleid',
  'footer-item-1': 'AML-beleid',
  'sidebar-item-1': 'Dashboard',
  'sidebar-item-2': 'Bankieren',
  'sidebar-item-3': 'Planbeheer',
  'sidebar-item-4': 'Affiliate Programma',
  'sidebar-item-5': 'Opnames',
  'sidebar-item-6': 'Persoonlijke informatie',
  'sidebar-item-7': 'Nieuws',

  'sidebar-item-8': 'Uitloggen',
  'dashboard-subtitle-1': 'Account',
  'dashboard-subtitle-2': 'Financiën',
  'dashboard-subtitle-3': 'Investeringen',
  'dashboard-subtitle-4': 'Affiliate Programma',
  'dashboard-subtitle-5': 'Termijn',
  'dashboard-subtitle-6': 'Huidig Resultaat',
  'dashboard-subtitle-7': 'Financieel Resultaat',
  'dashboard-text-1': 'Vrij Saldo',
  'dashboard-text-2': 'Actieve Fondsen',
  'dashboard-text-3': 'Geverifieerd',
  'dashboard-text-4': 'Deelnemer sinds',
  'dashboard-text-5': 'Totale Winstplannen',
  'dashboard-text-6': 'Verwijzingsinkomen',
  'dashboard-text-7': 'Storting',
  'dashboard-text-8': 'Opnames',
  'dashboard-text-9': 'Totale Winst',
  'dashboard-text-10': 'Actieve Plannen',
  'dashboard-text-10-1': 'Afgesloten Plannen',
  'dashboard-text-11': 'Plannen voor herinvestering',
  'dashboard-text-12': 'Aantal partners',
  'dashboard-text-13': 'Partners van de 1e lijn',
  'dashboard-text-14': 'Partners van de 2e lijn',
  'dashboard-text-15': 'Partners van de 3e lijn',
  'dashboard-text-16': 'Periode',
  'dashboard-text-17': 'dagen',
  'dashboard-text-18': 'Nog geen nieuws',
  'dashboard-text-19': 'Op dit moment',
  'dashboard-text-20': 'Niet genoeg gegevens voor de grafiek',
  'dashboard-text-21': '- Geen actieve investeringen',
  'dashboard-text-22': 'Lees alle nieuws',
  'dashboard-text-22-1': 'Lees alles',
  'dashboard-text-23': 'Investeringsplan',
  progressLabel: 'Voortgang Inv. plan',
  daysLabel: 'Dagen tot voltooiing',
  'deposit-subtitle-1-1': 'Voordat u een storting doet, zorg ervoor dat u het',
  'deposit-subtitle-1-2': 'Registratie van uw account.',
  'deposit-subtitle-1-3': 'Anders kunt u geen storting doen.',
  'deposit-subtitle-2': 'Geld verdienen met CoinFuze',
  'deposit-text-1':
    "Klik op de knop 'Uw account financieren' om uw saldo te storten.",
  'deposit-text-2':
    "Al uw stortingstransacties worden opgeslagen in het gedeelte 'Geschiedenis' hieronder, waar u de status van uw verzoeken kunt volgen.",
  'deposit-text-3':
    "Wanneer een verzoek de status 'Voltooid' krijgt, worden de fondsen automatisch toegevoegd aan uw 'Vrij Saldo'.",
  'deposit-text-4':
    'Op dit moment gebruiken we de meest stabiele cryptocurrency voor ons systeem - Tether USDT.',
  'deposit-text-5':
    'Na het doen van een storting kunt u genieten van alle voordelen van samenwerking met CoinFuze.',
  'deposit-text-6': 'Gemiddelde maandelijkse winst - 6,8%*',
  'deposit-text-7': 'Jaarlijkse winst - 98,7%',
  'deposit-text-8': 'Volledig geautomatiseerd handelssysteem',
  'deposit-text-9':
    'Mogelijkheid tot herinvestering en flexibel systeem voor het kiezen van investeringsvoorwaarden',
  'deposit-text-10':
    '*De gegevens over de winstgevendheid zijn gebaseerd op de open statistieken van het algoritme voor 2024.',
  'deposit-text-11': 'Gedetailleerde instructies over hoe u kunt storten',
  'deposit-text-12':
    'Heeft u een vraag over storten? Stel het in de ondersteuningschat.',
  'deposit-text-13':
    "Om uw saldo te storten, klikt u op de knop 'Storten op uw account'.",
  'deposit-text-14':
    'Al uw stortingstransacties worden opgeslagen in het gedeelte "Geschiedenis" hieronder, waar u de status van uw verzoeken kunt volgen.',
  'deposit-text-15':
    'Wanneer een verzoek de status "Voltooid" krijgt, worden de fondsen automatisch toegevoegd aan uw "Vrij Saldo".',
  'deposit-text-16':
    'Op dit moment gebruiken we de meest stabiele cryptocurrency voor ons systeem - Tether USDT.',
  'deposit-text-17':
    'Na het doen van een storting kunt u genieten van alle voordelen van samenwerking met CoinFuze.',
  'deposit-text-18':
    'Heeft u een vraag over storten? Stel het in de ondersteuningschat.',
  'deposit-btn': 'Storten naar account',
  notification: 'Gekopieerd',
  'deposit-popup-text-1': 'Cryptocurrency',
  'deposit-popup-text-2': 'Stortingsvaluta',
  'deposit-popup-text-3': 'Uw saldo:',
  'deposit-popup-text-4': 'Netwerk',
  'deposit-popup-text-5': 'Opmerking:',
  'deposit-popup-text-6-1': 'Stuur alleen',
  'deposit-popup-text-6-2':
    'naar dit stortingsadres. Munten worden automatisch bijgeschreven na',
  'deposit-popup-text-6-3': '6 netwerkbevestigingen.',
  'deposit-popup-text-6-4':
    'Slimme contractadressen worden niet ondersteund (neem contact met ons op).',
  'deposit-popup-text-7': 'Stortingsadres:',
  'deposit-popup-text-8': 'Kopie',
  history: 'Geschiedenis',
  'deposit-history-text-1': 'Stortingsdatum',
  'deposit-history-text-2': 'Betalingsmethode',
  'deposit-history-text-3': 'Stortingsbedrag',
  'deposit-history-text-4': 'Transactiestatus',
  'deposit-history-text-5': 'Transactie',
  'deposit-history-text-6': 'Geen transacties',
  'deposit-history-text-7': 'Getoond',
  'deposit-history-text-8': 'van',
  'plans-subtitle-1': 'Investeringsbeheer',
  'plans-subtitle-2': 'Eerste investeringen',
  'plans-text-1':
    'In dit gedeelte kunt u plannen maken op basis van de beschikbare plannen of financiële resultaten bekijken van al uw actieve of gesloten plannen.',
  'plans-text-2':
    'Er zijn zes investeringsplannen beschikbaar om uit te kiezen, elk met verschillende voorwaarden met betrekking tot de investeringsperiode, verwachte opbrengsten en Coinfuze servicekosten.',
  'plans-text-3':
    "Om het geselecteerde plan te activeren, moeten gebruikers een minimumbedrag storten en op de knop 'Activeren' klikken.",
  'plans-text-4':
    'Gedetailleerde instructies over hoe u met de Investeringplannen kunt werken.',
  'plans-text-5': 'Veelgestelde vragen over het gedeelte Investeringen.',
  'plans-text-6':
    "Om te beginnen met investeren, moet u een storting maken. Dit kunt u doen op de 'Storten' pagina in het menu aan de linkerkant of via de onderstaande knop.",
  'plans-text-7':
    'Nadat uw storting is verwerkt, activeert u het geselecteerde investeringsplan.',
  'plans-text-8':
    'CoinFuze biedt een automatische herinvesteringsoptie aan. Dit betekent dat aan het einde van de planningsperiode alle winsten uit de vorige periode automatisch worden overgebracht naar het startkapitaal van het nieuwe plan.',
  'plans-text-9': 'Ga naar storting',
  'plans-text-10': 'Activeer investeringsplan',
  'plans-text-11': 'Ja',
  'plans-text-12': 'Nee',
  'plans-text-13': 'Actieve plannen',
  'plans-text-14': 'Startdatum',
  'plans-text-15': 'Einddatum',
  'plans-text-16': 'Startkapitaal',
  'plans-text-17': '% financieel resultaat',
  'plans-text-18': 'Winst in',
  'plans-text-19': 'Planstatus',
  'plans-text-20': 'Herinvesteer',
  'plans-text-21': 'Opslaan',
  'plans-text-22': 'Beschikbare plannen',
  'plans-text-23': 'Minimale investering',
  'plans-text-24': 'Termijn',
  'plans-text-25': 'Opbrengst',
  'plans-text-26': 'tot',
  'plans-text-27': 'Commissie',
  'plans-text-28': 'Voer het bedrag van het startkapitaal in:',
  'plans-text-29': 'Activeren',
  'plans-text-30': 'Voer het minimumbedrag in',
  'plans-text-31': 'Naam',
  'plans-text-32': 'Startkapitaal',
  'plans-text-33': 'Huidig resultaat',
  'plans-text-34': 'Winst',
  'plans-text-35': 'Commissie',
  'plans-text-36': 'Actief',
  'plans-text-37': 'Voltooid',
  'plans-text-38': 'Investeringsplan',
  'plans-text-39': 'dagen',
  'plans-text-40': 'Selecteer',
  'plans-text-41': 'Status:',
  'partnership-subtitle-1': 'Verwijsprogramma',
  'partnership-subtitle-2': 'Jouw verwijzingslink',
  'partnership-subtitle-3': 'Uitgenodigde Verwijzingen',
  'partnership-subtitle-4': 'Niveau 1 - commissie op storting en winst',
  'partnership-subtitle-5': 'Niveau 2 - commissie op storting en winst',
  'partnership-subtitle-6': 'Niveau 3 - commissie op storting en winst',
  'partnership-text-1': 'Beveel ons aan bij je vrienden.',
  'partnership-text-2':
    'Genereer passief inkomen via het verwijzingsprogramma.',
  'partnership-text-3': 'Kopieer je verwijzingslink.',
  'partnership-text-4': 'Stuur deze naar je vrienden.',
  'partnership-text-5': 'Ontvang bonussen voor hun stortingen',
  'partnership-text-6': 'Ontvang bonussen over hun winsten',
  'partnership-text-7':
    'Elk CoinFuze-lid krijgt een unieke verwijzingslink en promotiecode om te delen met vrienden van over de hele wereld. Kopieer gewoon deze link en stuur deze naar degenen die je wilt uitnodigen voor CoinFuze.',
  'partnership-text-8': 'JOUE PROMO',
  'partnership-text-9': 'Deel je affiliate link of promotiecode.',
  'partnership-text-10': 'Bestudeer de statistieken van doorkliks',
  'partnership-text-11':
    'Houd bij hoeveel mensen zich hebben geregistreerd via jouw link',
  'partnership-text-12': 'Zie je winsten groeien',
  'partnership-text-13': 'Totaal uitgenodigd',
  'partnership-text-14': 'Partners van niveau 1',
  'partnership-text-15': 'Partners van niveau 2',
  'partnership-text-16': 'Partners van niveau 3',
  'partnership-text-17': 'Totaalverdiensten:',
  'partnership-text-18': 'Zoeken',
  'partnership-text-19':
    'Kijk wie zich al heeft geregistreerd via jouw verwijzingslink en hoeveel bonussen je hebt ontvangen. Vergeet niet dat we een verwijzingsprogramma van drie niveaus hebben. Je krijgt bonussen niet alleen voor degenen die je hebt uitgenodigd voor CoinFuze, maar ook voor de leden die jouw verwijzingen hebben uitgenodigd.',
  'partnership-text-20': 'Voer de naam van je verwijzing in',
  'partnership-text-21': 'Stortingsbonussen',
  'partnership-text-22': 'Winstbonussen',
  'partnership-text-23': 'Inkomsten uit partnerschap',
  'partnership-text-24': 'Datum van inschrijving',
  'partnership-text-25': 'Geen dergelijke verwijzing',
  'partnership-text-26': 'Zoeken...',
  'partnership-text-27': 'Geen verwijzingen',
  'partnership-text-28': 'Fase',
  'partnership-text-29': 'Omvang van omzet',
  'partnership-text-32': 'Bonussen',
  'partnership-text-33': 'Geslaagd',
  'partnership-text-34': 'Jouw Niveau',
  'partnership-text-35': 'Sluiten',
  'partnership-text-36': 'Verwijzingslink:',
  'partnership-text-37': 'Jouw promotie:',
  'partnership-text-38': 'Actieve Team Omzet:',
  'partnership-text-39': 'Commissie op storting en op winst:',
  'partnership-text-40': 'Verwijzingen',
  'partnership-text-41': 'Team Bonussen',
  'news-text-1': 'Nog geen nieuws.',
  'news-text-2': 'Lees meer',
  'news-text-3': 'Promotioneel materiaal',
  'news-text-4': 'Deel ons promotiemateriaal met je vrienden en verdien!',
  'news-text-5':
    'We hebben een selectie van promotiematerialen voor je gemaakt om nieuwe CoinFuze-leden aan te trekken.',
  'news-text-6': 'Deel en verdien met je vrienden!',
  'news-text-7':
    'Elk CoinFuze-lid krijgt een unieke verwijzingslink en promotiecode om te delen met vrienden van over de hele wereld. Kopieer gewoon deze link en stuur deze naar degenen die je wilt uitnodigen voor CoinFuze.',
  'news-text-8': 'JOUE PROMO',
  'news-text-9': 'Deel je affiliate link of promotiecode.',
  'news-text-10': 'Bestudeer de statistieken van doorkliks erop',
  'news-text-11':
    'Houd bij hoeveel mensen zich hebben geregistreerd via jouw link',
  'news-text-12': 'Zie je winsten groeien.',
  'news-text-13': 'Verdien tot 6% verwijzingsbonussen',
  'withdrawal-text-1': 'Datum van opname',
  'withdrawal-text-2': 'Betalingsmethode',
  'withdrawal-text-3': 'Opnamebedrag',
  'withdrawal-text-4': 'Je moet je identiteit verifiëren om geld op te nemen',
  'withdrawal-text-5':
    'Je moet het verificatieproces voltooien door je identiteitsdocumenten te uploaden voordat je kunt opnemen.',
  'withdrawal-text-6': 'Verifiëren',
  'withdrawal-text-7': 'Opname',
  'withdrawal-text-8':
    'Voer de bevestigingscode in die naar je e-mail is gestuurd:',
  'withdrawal-text-9': 'Voer code in',
  'withdrawal-text-10': 'Stuur de code opnieuw via:',
  'withdrawal-text-11': 'Code verifiëren',
  'withdrawal-text-12': 'Opnieuw verzenden',
  'withdrawal-text-13': 'Geverifieerd',
  'withdrawal-text-14': 'Stuur de code opnieuw via:',
  'withdrawal-text-15': 'Voer de code in van de mobiele app',
  'withdrawal-text-16': 'Voer de code in van',
  'withdrawal-text-17': 'Informatie:',
  'withdrawal-text-18':
    'Vrij saldo is je beschikbare saldo voor opname. Als je actieve investeringen hebt, worden deze niet meegeteld omdat ze zijn vergrendeld tot het einde van de investeringstermijn.',
  'withdrawal-text-19': 'Opname van fondsen',
  'withdrawal-text-19-1': 'Beschikbaar voor opname:',
  'withdrawal-text-19-2': 'Begin Opnames',
  'withdrawal-text-20':
    'Op deze pagina kun je geld opnemen naar je cryptocurrency-portemonnee. Volg de aanwijzingen om de opname zo snel en gemakkelijk mogelijk te maken.',
  'withdrawal-text-21':
    'Opnames, net als stortingen, zijn beschikbaar in cryptocurrency',
  'withdrawal-text-22':
    'Het opnemen kan enige tijd duren, aangezien transacties in het cryptocurrency-netwerk op verschillende snelheden plaatsvinden.',
  'withdrawal-text-23':
    'Opnameverzoeken worden automatisch geaccepteerd en verwerkt in korte tijd.',
  'withdrawal-text-24': 'Begin opname',
  'withdrawal-text-24-1': 'Gedetailleerde opname-instructie',
  'withdrawal-text-25': 'Verhoog de winstgevendheid van je investeringen!',
  'withdrawal-text-26':
    'Je kunt nog meer winst maken door je fondsen opnieuw te investeren.',
  'withdrawal-text-27': 'Herinvesteer je storting en de winst die je maakt.',
  'withdrawal-text-28':
    'Begin de investeringsperiode met een groot startkapitaal.',
  'withdrawal-text-29': 'Zie je inkomen dagelijks toenemen.',
  'withdrawal-text-30': 'Herinvesteer nu',
  'withdrawal-text-31': 'Kies de juiste portemonnee voor opname',
  'withdrawal-text-32':
    'Om fondsen naar je portemonnee op te nemen, voeg je deze toe aan je account en maak je een opnameverzoek.',
  'withdrawal-text-33': 'Hier naar overmaken',
  'withdrawal-text-34': 'Aanvraag indienen',
  'withdrawal-text-35': 'Informatie:',
  'withdrawal-text-36':
    'Het getoonde saldo is je beschikbare saldo, niet je totale middelen. Als je actieve investeringen hebt, worden deze niet meegeteld omdat ze vergrendeld zijn tot het einde van de investeringsperiode.',
  'withdrawal-text-37': 'Opnamebedrag',
  'personal-text-1': 'Persoonlijke gegevens',
  'personal-text-2': 'Tweefactorauthenticatie',
  'personal-text-3': 'Wachtwoord',
  'personal-text-4': 'Verificatie',
  'personal-text-5': 'Jouw naam',
  'personal-text-6': 'Voer de juiste voornaam in',
  'personal-text-7': 'Je achternaam',
  'personal-text-8': 'Voer een juiste achternaam in',
  'personal-text-9': 'Annuleren',
  'personal-text-10': 'Opslaan',
  'personal-text-11': 'In-/uitschakelen',
  'personal-text-12':
    'Tweefactorauthenticatie (2FA) via e-mail is standaard ingeschakeld voor alle accounts en kan niet worden uitgeschakeld.',
  'personal-text-13':
    'Om je e-mailadres in CoinFuze te wijzigen, moet je contact opnemen met de klantenservice. Zodra de klantenservice je verzoek heeft verwerkt, ontvang je een melding dat je adres succesvol is gewijzigd naar een nieuw adres.',
  'personal-text-14':
    'Het is belangrijk om te onthouden dat om fraude te voorkomen en je gegevens te beschermen, de klantenservice aanvullende informatie of documenten kan vragen om je identiteit te verifiëren.',
  'personal-text-15':
    '2FA is uitgeschakeld. We raden alle klanten aan tweefactorauthenticatie (2FA) in te schakelen voor extra beveiliging.',
  'personal-text-16': 'Uitschakelen',
  'personal-text-17': 'Inschakelen',
  'personal-text-18': 'Account',
  'personal-text-19':
    'Deze functie stelt je in staat om Google Authenticator of je Telegram-account te gebruiken voor tweefactorauthenticatie. Naast de bevestiging van de e-mail naar je e-mailadres, moet je ook een beveiligingscode verstrekken, afkomstig van Google Authenticator of Telegram, om bepaalde handelingen in je persoonlijke account uit te voeren. Deze handelingen omvatten: geld opnemen, persoonlijke informatie wijzigen en je accountwachtwoord. Dit biedt extra bescherming tegen hackers die inloggen op je account, zelfs als ze je wachtwoord hebben kunnen bemachtigen.',
  'personal-text-20':
    'Deze functie stelt je in staat om Google Authenticator of een compatibele toepassing zoals FreeOTP te gebruiken voor tweefactorauthenticatie. Naast je gebruikersnaam en wachtwoord moet je ook een zescijferige beveiligingscode verstrekken om in te kunnen loggen op deze site. De beveiligingscode verandert elke 30 seconden. Dit biedt extra bescherming tegen hackers die inloggen op je account, zelfs als ze je wachtwoord hebben kunnen bemachtigen.',
  'personal-text-21': 'Ontvang',
  'personal-text-22':
    'Download en installeer Google Authenticator op je smartphone of computer.',
  'personal-text-23': 'Gebruik een van de volgende stappen:',
  'personal-text-24':
    'De officiële Google Authenticator-app voor Android, iOS en BlackBerry. Zorg ervoor dat je de klok van je apparaat synchroniseert met de tijdserver. Een tijdsverschil op je apparaat kan resulteren in het onvermogen om in te loggen op je persoonlijke account.',
  'personal-text-25': 'en',
  'personal-text-26':
    'Zorg ervoor dat de klok van je apparaat is gesynchroniseerd met de tijdserver. Een tijdsverschuiving op je apparaat kan ertoe leiden dat je niet kunt inloggen op je persoonlijke account.',
  'personal-text-27': 'Installatie.',
  'personal-text-28': 'Scan de volgende QR-code op',
  'personal-text-29': 'Aanpassingssleutel',
  'personal-text-30':
    'Als je de sleutel wilt wijzigen, schakel dan tweefactorauthenticatie uit. Wanneer je probeert het weer in te schakelen, wordt er een nieuwe sleutel gegenereerd.',
  'personal-text-31': 'Activeer tweefactorauthenticatie.',
  'personal-text-33': 'Beveiligingscode',
  'personal-text-34': 'Je wachtwoord',
  'personal-text-35': 'Inschakelen',
  'personal-text-37': 'Huidig wachtwoord',
  'personal-text-38': 'Nieuw wachtwoord',
  'personal-text-39': 'Bevestig nieuw wachtwoord',
  'personal-text-41': 'Downloadbare documenten',
  'personal-text-42': 'Downloaden',
  'personal-text-43': 'Acceptabel formaat',
  'personal-text-44': 'Je documenten worden geverifieerd',
  'personal-text-45': 'Je documenten zijn geverifieerd',
  'personal-text-46': 'Je documenten',
  'personal-text-47': 'Actieve sessies',
  'personal-text-48': 'Browsersessies',
  'personal-text-49':
    'Beheer en log uit van je actieve sessies op andere browsers en apparaten.',
  'personal-text-50':
    'Je kunt uitloggen uit al je andere browsersessies op al je apparaten indien nodig. Hieronder staan enkele van je recente sessies vermeld, maar deze lijst is mogelijk niet volledig. Als je denkt dat je account is gecompromitteerd, moet je ook je wachtwoord bijwerken.',
  'personal-text-51': 'Dit apparaat',
  'personal-text-51-1': 'uitloggen',

  'personal-text-52': 'Uitloggen bij andere browsersessies',
  'personal-text-53': 'Rijbewijs',
  'personal-text-54': 'Bankafschrift',
  file: 'Bestand niet geselecteerd',
  document: 'Paspoort',
  'personal-text-55':
    'Om je wachtwoord te wijzigen, doorloop je tweefactorauthenticatie (2FA)',
  'personal-text-56':
    'Om je voornaam en achternaam te wijzigen, doorloop je tweefactorauthenticatie (2FA).',
  'personal-text-57':
    'Tweefactorauthenticatie (2FA) is momenteel ingeschakeld voor dit account.',
  'personal-text-58':
    'Kopieer deze eenmalige noodwachtwoord en bewaar het op een veilige plaats. Als je geen toegang hebt tot een tweefactorauthenticatie-apparaat, kun je dit wachtwoord gebruiken in plaats van je normale beveiligingscode. Dit wachtwoord wordt onmiddellijk vernietigd wanneer het wordt gebruikt. We raden aan om dit wachtwoord af te drukken en een afdruk op een veilige en toegankelijke plaats te bewaren, zoals je portemonnee of kluis.',
  'personal-text-59': 'Uitschakelen van tweefactorauthenticatie.',
  'personal-text-60': 'Voer alstublieft de tweefactorauthenticatiecode in van.',
  'personal-text-61': 'Indienen',
  'personal-text-62': 'Annuleren',
  'text-63': 'Ga naar persoonlijk account',
  'text-64': 'Terug',
  'text-65': 'Meldingen',
  'text-66': 'Markeer alle meldingen als gelezen',
  'text-67': 'Markeer',
  'text-68': 'Uw registratie is bijna voltooid',
  'text-69':
    "U moet het registratieproces voltooien voordat u gaat investeren. Klik op de knop 'Voltooien' en bevestig uw registratie vanuit de e-mail die naar uw e-mail is verzonden.",
  'text-70': 'Registratie voltooien',
  'text-71': 'Selecteer taal',

  'cookies-1': 'Door deze site te bezoeken, gaat u akkoord met',
  'cookies-2': 'ons privacybeleid',
  ok: 'Ok',
  'table-1': 'Inloggen',
  'table-2': 'Bonus (Storting)',
  'table-3': 'Bonus (Winsten)',
  'table-4': 'Aantal verwijzingen',
  'table-5': 'Inschrijfdatum',
  'table-6': 'Transactietype',
  'table-7': 'Datum',
  'table-8': 'Stortingsbedrag',
  'table-9': 'Transactiestatus',
  'table-10': 'ID Transactie',

  'profile-window-1': 'Inloggen:',
  'profile-window-2': 'Telefoon:',
  'profile-window-3': 'Taal:',
  'profile-window-4': 'Thema:',
  'profile-window-5': 'Nodig vrienden uit:',

  'two-factor-1': 'Tweefactorvalidatie',
  'two-factor-2': 'Voer de bevestigingscode in die naar uw e-mail is gestuurd:',
  'two-factor-3': 'Opnieuw verzenden',
  'two-factor-4': 'Valideren',
  'two-factor-5': 'Gevalideerd',
  'two-factor-6': 'Opnieuw nieuwe code verzenden via:',
  'two-factor-7': 'Voer de bevestigingscode in die naar uw',
  'two-factor-8': 'Voer code in',
  back: 'Terug naar Planbeheer',
  'back-to-site': 'Terug naar Site',
  'back-to-news': 'Terug naar Nieuws',
  support: 'ondersteuning',
  title: 'Titel',
  step: 'Stap',
  'page-not-found': 'Pagina niet gevonden',
  home: 'Home',
  'staking-1': 'Terug naar Planbeheer',
  'staking-2': 'crypto-staking',
  'staking-3':
    'U kunt uitloggen uit al uw andere browsersessies op al uw apparaten indien nodig. Hieronder worden enkele van uw recente sessies weergegeven; deze lijst is echter mogelijk niet volledig. Als u denkt dat uw account is gecompromitteerd, moet u ook uw wachtwoord bijwerken.',
  'staking-4': 'Gaan naar',
  'staking-4-1': 'Activeren',
  'staking-5': 'Staking',
  'staking-6': 'Financieel resultaat:',
  'staking-7': 'Datum laatste winst:',
  'staking-8': 'Storting bij staking:',
  'staking-9': 'Niveaus',
  'staking-10': 'Laatste investering:',
  'staking-11': 'niveau',
  'staking-12': 'Omzetbedrag',
  'staking-13': 'Bonussen',
  'staking-14': 'Doorgegeven',
  'staking-15': 'Uw niveau',
  'staking-16': 'Sluiten',
  'staking-17': 'Staking lvl:',
  'staking-18': 'Actief',
  'staking-19': 'Inactief',
  'staking-20': 'Voltooid',
  'staking-table-1': 'Investeeringsplan',
  'staking-table-2': 'Startdatum',
  'staking-table-3': 'Einddatum',
  'staking-table-4': 'Startkapitaal',
  'staking-table-5': 'Huidig resultaat, USDT',
  'staking-table-6': 'Winst, USDT',
  'staking-table-7': 'Commissie, USDT',
  'staking-table-8': 'Planstatus',
  'plan-modal-1': 'Investeeringsplan',
  'plan-modal-2': 'Startdatum',
  'plan-modal-3': 'Einddatum',
  'plan-modal-4': 'Startkapitaal',
  'plan-modal-5': 'Financieel resultaat',
  'plan-modal-6': 'Winst in USDT',
  'plan-modal-7': 'Voer het bedrag van het startkapitaal in:',
  'plan-modal-8': 'Activeren',
  'plan-page-1': 'Actieve plannen',
  'plan-page-2': 'Beschikbare plannen',
  done: 'Klaar',
  purpose: 'Doel',
  'password-reset': 'Het wachtwoord is naar uw e-mailadres verzonden',

  'table-next': 'Volgende',
  'table-prev': 'Vorige',

  'tr_status-1': 'In afwachting',
  'tr_status-2': 'Handmatig',
  'tr_status-3': 'Afgewezen',
  'tr_status-4': 'Goedgekeurd',
  'tr-type-0': 'Alle',
  'tr-type-1': 'Storting',
  'tr-type-2': 'Opname',
  'tr-type-3': 'Dividenden',
  'tr-type-4': 'Stortingsbonus',
  'tr-type-5': 'Doorverwijzingsbetaling',
  'tr-type-6': 'Teamomzetbonus',
  'tr-type-7': 'Planactivering',
  'tr-type-8': 'Plansluiting',
  'tr-type-9': 'Boete',
  'tr-type-10': 'Commissie',
  'document-1': 'Identiteitskaart',

  'deposit-benefits-1': 'Verdien met',
  'deposit-benefits-2': 'Gemiddelde maandelijkse winst',
  'deposit-benefits-3': 'Jaarlijkse winst',
  'deposit-benefits-4': 'Volledig geautomatiseerd handelssysteem.',
  'deposit-benefits-5':
    'Mogelijkheid tot herinvestering en een flexibel systeem voor het kiezen van investeringstermijnen.',

  'staking-1-1': 'Investeringsproduct',
  'staking-1-2':
    'ontworpen en gericht op gebruikers die een stabiel passief inkomen willen verdienen, een eenvoudig te beheren instrument, evenals dagelijkse toekenningen en winstuitkering.',
  'staking-1-3':
    'een product met het potentieel voor ontwikkeling en groei in winstgevendheid, dankzij de 5 niveaus van het instrument.',

  copied: 'gekopieerd',
  'email-verification-1': 'Bevestig uw account.',
  'email-verification-2':
    'Er is een bevestigingsmail naar uw email gestuurd bij registratie met een verificatieknop. Als u om een of andere reden de email niet kunt vinden, klik dan op de knop hieronder en we zullen de email opnieuw versturen.',
  'email-verification-3':
    'Om uw account te bevestigen, klik op de knop in de email.',
  'email-verification-4':
    'Na het bevestigen van uw email kunt u inloggen op uw account en beginnen met het gebruiken van alle functies van CoinFuze.',
  'email-verification-5': 'Versturen',
  'email-verification-6': 'Bevestigingsmail opnieuw versturen',

  'def-file-input': 'Bestand niet geselecteerd',
  'personal-text-16-1': 'Verbind uw telegram',
  'new-notification': 'Nieuwe melding',
  'personal-text-32':
    'Om ervoor te zorgen dat alles correct is ingesteld, voert u de beveiligingscode in die u hebt ontvangen in Google Authenticator en het wachtwoord voor uw account in het onderstaande veld in. Als de code en het wachtwoord juist zijn, klikt u op de knop "Inschakelen" en wordt tweefactorauthenticatie geactiveerd.',
  'personal-label-1': 'Beveiligingscode',
  'personal-label-2': 'Uw wachtwoord',
  'withdrawal-text-24-2': 'Veelgestelde vragen in het Opnamegedeelte'
}
