export default {
  'withdrawal-ph-1': 'Geldbörsen-ID',
  'withdrawal-ph-2': 'Auszahlungsbetrag',
  'confirm-modal-1': 'Bestätigen Sie die Aktion',
  'confirm-modal-2': 'Bestätigen',
  'confirm-modal-3': 'Abbrechen',
  copy: 'Kopieren',
  delete: 'Löschen',

  download: 'Herunterladen',
  'modal-p-text-1':
    'Detaillierte Anweisungen zur Arbeit mit Investitionsplänen',
  'modal-p-text-2':
    'Sobald die Einzahlung auf Ihr Konto gutgeschrieben wurde, können Sie Ihre Gelder in den ausgewählten Plan investieren.',
  'modal-p-text-3':
    'Befolgen Sie die folgenden Anweisungen, um Ihren Investitionsplan zu aktivieren.',
  'modal-p-text-4':
    "Gehen Sie in Ihrem persönlichen Konto zur Seite 'Planverwaltung'. Hier finden Sie detaillierte Informationen zu allen Anlageprodukten. Dazu gehören Anlagezeiträume, Servicegebühren sowie die Rentabilität des Plans.",
  'modal-p-text-5':
    "Um den gewünschten Plan zu aktivieren, klicken Sie auf die Schaltfläche 'Auswählen', geben Sie den Betrag des Anfangskapitals ein, mit dem Sie den Plan starten möchten, und klicken Sie auf die Schaltfläche 'Aktivieren'.",
  'modal-p-text-6':
    "Mit der Funktion 'Automatische Wiederanlage' können Sie Ihre Gelder automatisch über einen langen Zeitraum investieren und zusätzliche Vorteile erzielen.",
  'modal-p-text-7':
    'Direkt nach dem Start des Investitionsplans können Sie die Option mit einem einzigen Klick aktivieren oder deaktivieren.',
  'modal-p-text-8':
    'Herzlichen Glückwunsch, Sie haben erfolgreich einen Investitionsplan gestartet!',
  'modal-p-text-9':
    'Sie können diese Einstellung nach eigenem Ermessen ändern, selbst wenn der Ausführungszeitraum bereits begonnen hat. Wenn Sie diese Option aktiviert haben, wird bei Ablauf der Laufzeit des Investitionsplans das gesamte Kapital einschließlich aller Gewinne automatisch wieder angelegt. Ist diese Option deaktiviert, erfolgt keine automatische Wiederanlage, und das gesamte Kapital mit Gewinn wird dem freien Guthaben gutgeschrieben.',

  update: 'Hochladen',
  'update-avatar': 'Laden Sie Ihr Avatar hoch',
  save: 'Speichern',
  'modal-d-text-1': 'Anleitung zum Aufladen des Kontos bei CoinFuze',
  'modal-d-text-2':
    "Navigieren Sie auf die 'Einzahlung' Seite in Ihrem Benutzerkonto.",
  'modal-d-text-3':
    "Um den Aufladevorgang zu starten, klicken Sie auf die Schaltfläche 'Guthaben aufladen'.",
  'modal-d-text-4':
    'Sie erhalten eine eindeutige Wallet-ID, um Gelder zu senden. Stellen Sie sicher, dass Sie diese ID verwenden, wenn Sie die Zahlung durchführen.',
  'modal-d-text-5':
    'Sie haben auch die Möglichkeit, den QR-Code links neben der Wallet-ID zu verwenden. Sie können ihn mit Ihrem Handy scannen, um die Daten bequemer einzugeben.',
  'modal-d-text-6':
    'Nachdem Sie die Mittel an die angegebene Wallet gesendet haben, werden die Mittel automatisch Ihrem Guthaben gutgeschrieben. Dies dauert normalerweise zwischen 1 Minute und 2 Stunden, aber die Zeitrahmen können je nach Blockchain und anderen Faktoren variieren.',
  'modal-d-text-7':
    "Bestätigte Einzahlungen werden am unteren Rand der Seite im Tab 'Einzahlungsverlauf' angezeigt. Hier können Sie alle Ihre Einzahlungsanträge verfolgen.",
  'modal-d-text-8':
    'Folgen Sie dieser Anleitung, um Ihr Guthaben bei CoinFuze einfach und sicher aufzuladen. Bei Fragen oder benötigter weiterer Unterstützung wenden Sie sich bitte an unseren Support.',

  'modal-w-text-1':
    'Um den Auszahlungsvorgang zu starten, folgen Sie dieser Anleitung:',
  'modal-w-text-2':
    "Gehen Sie in Ihrem Benutzerkonto im linken Menü zur Seite 'Auszahlung'.",
  'modal-w-text-3':
    'Geben Sie den Betrag in USDT ein, den Sie abheben möchten.',
  'modal-w-text-4':
    'Geben Sie Ihre USDT TRC20 Wallet an, auf die Sie die Mittel erhalten möchten.',
  'modal-w-text-5':
    'Bestätigen Sie die Transaktion, indem Sie den Bestätigungscode eingeben, der an Ihre E-Mail-Adresse gesendet wird, sowie den Passwortcode (2FA) für die Zwei-Faktor-Authentifizierung, falls Sie diese aktiviert haben.',
  'modal-w-text-6':
    'Nach Bestätigung wird Ihre Auszahlungsanfrage bearbeitet. Dies dauert normalerweise zwischen wenigen Minuten und 24 Stunden, abhängig von der Auslastung des Systems.',
  'modal-w-text-7':
    'Beachten Sie, dass der Mindestbetrag für Auszahlungen 10 USDT beträgt und eine geringe Netzwerkgebühr anfällt. Außerdem müssen Sie sicherstellen, dass die Laufzeit Ihres Investitionsplans abgelaufen ist und die Gewinne Ihrem Freien Guthaben gutgeschrieben wurden, um eine Auszahlung vornehmen zu können.',
  validation: {
    nameValidFormat: '{attribute} darf keine Ziffern oder Symbole enthalten',
    twoCharactersInRow:
      '{attribute} darf nicht mehr als zwei identische Zeichen hintereinander haben',
    email: 'E-Mail-Format ist ungültig',
    required: '{attribute} ist erforderlich',
    minValue:
      'Der Mindestwert von {attribute} darf nicht weniger als {min} sein',
    maxValue: 'Der Höchstwert von {attribute} darf nicht mehr als {max} sein',
    minLength: '{attribute} muss mindestens {min} Zeichen lang sein',
    maxLength: '{attribute} darf nicht länger als {max} Zeichen sein',
    validFormat:
      '{attribute} muss mit einem Großbuchstaben beginnen und darf nur Buchstaben enthalten',
    validPassword:
      '{attribute} muss mindestens eine Ziffer und einen Großbuchstaben enthalten',
    sameAsPassword: 'Passwörter müssen identisch sein',
    numeric: '{attribute} ist ungültig. Muss eine Ziffer sein'
  },
  Country: 'Land',
  'phone-input-placeholder': 'Geben Sie Ihre Telefonnummer ein',
  'withdrawal-instruction': 'Detaillierte Auszahlungsanleitung',
  'withdrawal-instruction-text-1':
    'Um den Auszahlungsprozess zu starten, folgen Sie diesen Anweisungen:',
  'withdrawal-instruction-text-2':
    'Gehen Sie in Ihrem persönlichen Bereich im linken Menü zur Seite "Auszahlung von Geldern".',
  'withdrawal-instruction-text-3':
    'Geben Sie den Betrag in USDT ein, den Sie abheben möchten.',
  'withdrawal-instruction-text-4':
    'Geben Sie Ihre USDT TRC20-Wallet an, auf die Sie die Gelder erhalten möchten.',
  'withdrawal-instruction-text-5':
    'Bestätigen Sie die Transaktion, indem Sie den Bestätigungscode eingeben, der an Ihre E-Mail gesendet wird, sowie den Passwortcode (2FA) der Zwei-Faktor-Authentifizierung, falls Sie diese verbunden haben.',
  'withdrawal-instruction-text-6':
    'Nach der Bestätigung wird Ihr Auszahlungsantrag bearbeitet. Dies dauert in der Regel von einigen Minuten bis zu 24 Stunden, abhängig von der Systemlast.',
  'withdrawal-instruction-text-7':
    'Bitte beachten Sie, dass der Mindestabhebungsbetrag 10 USDT beträgt und eine geringe Netzwerkgebühr anfällt. Außerdem müssen Sie sicherstellen, dass Ihr Investitionsplan abgelaufen ist und Gewinne auf das Freie Guthaben gutgeschrieben wurden, um abheben zu können.',
  'plans-instruction-text-1':
    'Sobald die Einzahlung Ihrem Konto gutgeschrieben wurde, können Sie Ihre Mittel in den gewählten Plan investieren.',
  'plans-instruction-text-2':
    'Folgen Sie den unten stehenden Anweisungen, um Ihren Investitionsplan zu aktivieren.',
  'plans-instruction-text-3':
    'Gehen Sie in Ihrem persönlichen Bereich zur Seite "Investitionspläne". Hier finden Sie detaillierte Informationen zu jedem Investitionsplan. Dies umfasst die Investitionsbedingungen, Provisionen sowie die Rendite des Plans.',
  'plans-instruction-text-4':
    'Um den gewünschten Plan zu aktivieren, klicken Sie auf "Plus" und geben Sie im Feld "Startkapital" den Betrag ein, mit dem Sie den Plan starten möchten, und klicken Sie auf die Schaltfläche "Aktivieren".',
  'plans-instruction-text-5':
    'Mit der Funktion "Automatisches Reinvestieren" können Sie Ihre Mittel langfristig automatisch investieren und zusätzliche Vorteile erhalten.',
  'plans-instruction-text-6':
    'Unmittelbar nach Start eines Investitionsplans können Sie die Option mit einem einzigen Klick aktivieren oder deaktivieren.',
  'plans-instruction-text-7':
    'Herzlichen Glückwunsch, Sie haben erfolgreich einen Investitionsplan gestartet!',
  'plans-instruction-text-8':
    'Sie können diese Einstellung nach Belieben ändern, auch wenn das Fälligkeitsdatum bereits begonnen hat. Wenn Sie diese Option aktiviert haben, werden am Ende der Laufzeit des Investitionsplans das gesamte Kapital einschließlich aller Gewinne automatisch reinvestiert. Wenn diese Option deaktiviert ist, erfolgt keine automatische Reinvestition und das gesamte Kapital mit Gewinnen wird dem Freien Guthaben gutgeschrieben.',
  'plans-instruction-text-9':
    'Wenn Sie "Automatisches Reinvestieren" aktiviert haben, wird das gesamte Kapital und die Gewinne des Plans nach Ablauf ständig reinvestiert, bis Sie diese Option deaktivieren.',
  'plans-instruction-text-10':
    'So können Sie Ihr Kapital langfristig mit minimalen Kosten vollautomatisch investieren. Um das gesamte Kapital auszuzahlen, müssen Sie diese Funktion deaktivieren. Sobald die Funktion deaktiviert ist, wird das gesamte Kapital einschließlich der Erträge am Ende der aktuellen Laufzeit des Investitionsplans dem Freien Guthaben gutgeschrieben. Bei Nutzung dieser Option fallen keine zusätzlichen Gebühren an.',
  'plans-instruction-text-11':
    'Alle Informationen zur Arbeit mit Aktiven und Abgeschlossenen Investitionsplänen finden Sie im Abschnitt "Planverlauf" am unteren Rand der Seite.',
  'popup-instruction-title-2': 'Schritt-für-Schritt-Anleitung',
  'popup-instruction-text-1':
    'Anleitung für CoinFuze-EinzAnleitung zur Einzahlung bei CoinFuze',

  'popup-instruction-text-2':
    "Gehe zur 'Einzahlung' Seite in deinem persönlichen Konto.",
  'popup-instruction-text-3':
    'Um den Einzahlungsvorgang zu starten, klicke auf die Schaltfläche "Einzahlung".',
  'popup-instruction-text-4':
    'Ihnen wird eine eindeutige Wallet-ID zur Verfügung gestellt, an die Sie die Mittel senden können. Stellen Sie sicher, dass Sie diese Kennung verwenden, wenn Sie eine Zahlung vornehmen.',
  'popup-instruction-text-5':
    'Sie haben auch die Möglichkeit, den QR-Code zu verwenden, der sich links von der Wallet-ID befindet. Sie können ihn mit Ihrem Handy scannen, um die Dateneingabe zu erleichtern.',
  'popup-instruction-text-6':
    'Nach dem Senden von Mitteln an die angegebene Wallet werden die Mittel automatisch Ihrem Guthaben gutgeschrieben. Dies dauert normalerweise zwischen 1 Minute und 2 Stunden, kann aber je nach Blockchain und anderen Faktoren variieren.',
  'popup-instruction-text-7':
    'Bestätigte Einzahlungen werden unten auf der Seite unter dem Tab "Einzahlungsverlauf" angezeigt. Hier können Sie alle Ihre Einzahlungsanfragen verfolgen.',
  'popup-instruction-text-8':
    'Befolgen Sie diese Anweisungen, um Ihre Einzahlung auf CoinFuze einfach und sicher zu tätigen. Wenn Sie Fragen haben oder zusätzliche Unterstützung benötigen, zögern Sie nicht, sich an unser Support-Team zu wenden.',
  error: 'Fehler',
  'partnership-text-31': 'Anzahl der Empfehlungen',
  'partnership-text-30': 'Betrag der Einzahlungen',
  'autorization-tab-1': 'Autorisierung',
  'autorization-tab-2': 'Registrierung',
  'autorization-login': 'Login',
  'autorization-username': 'Login/E-Mail/Telefon',
  'autorization-password': 'Passwort',
  'autorization-forgot': 'Passwort vergessen?',
  'autorization-btn-login': 'Anmelden',
  'autorization-name': 'Name',
  'autorization-surname': 'Nachname',
  'autorization-repeat-password': 'Passwort bestätigen',
  'autorization-ypass': 'Ihr Passwort',
  'autorization-newpass': 'Ihr Passwort',
  'autorization-email': 'E-Mail',
  'autorization-tel': 'Telefon',
  'autorization-promo': 'Promo-Code',
  'autorization-text-0': 'Ich akzeptiere die',
  'autorization-text-1': 'Geschäftsbedingungen,',
  'autorization-text-2': 'Datenschutzrichtlinie',
  'autorization-text-3': 'AML-Richtlinie',
  'autorization-text-4': 'Ich bin 18 Jahre oder älter',
  'autorization-text-5': 'Nutzungsbedingungen',
  'autorization-text-6': 'Erinnere dich an mich',

  'autorization-text-7': 'Haben Sie noch kein Konto?',
  'autorization-text-8':
    'Geben Sie Ihren Benutzernamen ein, und wir senden Ihnen eine Anleitung an Ihre E-Mail-Adresse:',
  'autorization-text-10': 'Haben Sie bereits ein Konto?',
  'autorization-text-11': 'Anmelden',
  'autorization-btn-register': 'Registrierung',
  'autorization-btn-cancel': 'Passwort zurücksetzen',
  'autorization-status-2':
    'Die angegebene E-Mail/Telefonnummer wurde nicht gefunden.',
  'autorization-status-1': 'Ungültige Anmeldung oder Passwort',
  'autorization-status-name': 'Ungültiger Vorname',
  'autorization-status-surname': 'Ungültiger Nachname',
  'autorization-status-password':
    'Das Passwort muss mindestens 7 Zeichen lang sein und mindestens 1 Großbuchstaben und 1 Kleinbuchstaben enthalten',
  'autorization-status-repeat-password': 'Die Passwörter stimmen nicht überein',
  'autorization-status-email': 'Falsches E-Mail-Format',
  'autorization-status-phone': 'Falsche Telefonnummer',
  'autorization-status-agree-rules': 'Bestätigen Sie die Bedingungen',
  'autorization-status-agree-age': 'Bestätigen Sie die Bedingungen',
  'validation-1': 'Um sich zu registrieren, müssen Sie über 18 Jahre alt sein',
  'validation-2':
    'Um sich zu registrieren, müssen Sie die Geschäftsbedingungen akzeptieren',
  'header-item-1': 'Gesamtguthaben',
  'header-item-2': 'Freies Guthaben:',
  'header-item-3': 'Aktives Guthaben:',
  'non-message': 'Keine neuen Benachrichtigungen',
  'message-btn': 'Alle Benachrichtigungen anzeigen',
  'header-item-4': 'Persönliche Informationen',
  'header-item-5': 'Freunde einladen',
  'header-item-6': 'Hinzufügen',
  'header-item-7': 'Abmelden',
  'footer-item-2': 'Nutzungsbedingungen',
  'footer-item-3': 'Datenschutzrichtlinie',
  'footer-item-1': 'AML-Richtlinie',
  'sidebar-item-1': 'Dashboard',
  'sidebar-item-2': 'Bankwesen',
  'sidebar-item-3': 'Planverwaltung',
  'sidebar-item-4': 'Partnerprogramm',
  'sidebar-item-5': 'Auszahlungen',
  'sidebar-item-6': 'Persönliche Informationen',
  'sidebar-item-7': 'Nachrichten',
  'sidebar-item-8': 'Abmelden',
  'dashboard-subtitle-1': 'Konto',
  'dashboard-subtitle-2': 'Finanzen',
  'dashboard-subtitle-3': 'Investitionen',
  'dashboard-subtitle-4': 'Partnerprogramm',
  'dashboard-subtitle-5': 'Laufzeit',
  'dashboard-subtitle-6': 'Aktuelles Ergebnis',
  'dashboard-subtitle-7': 'Finanzergebnis',
  'dashboard-text-1': 'Freies Guthaben',
  'dashboard-text-2': 'Aktive Gelder',
  'dashboard-text-3': 'Verifiziert',
  'dashboard-text-4': 'Teilnehmer seit',
  'dashboard-text-5': 'Gesamtertragspläne',
  'dashboard-text-6': 'Empfehlungseinkommen',
  'dashboard-text-7': 'Einlage',
  'dashboard-text-8': 'Abhebungen',
  'dashboard-text-9': 'Gesamtgewinn',
  'dashboard-text-10': 'Aktive Pläne',
  'dashboard-text-10-1': 'Abgeschlossene Pläne',
  'dashboard-text-11': 'Pläne zur Wiederanlage',
  'dashboard-text-12': 'Anzahl der Partner',
  'dashboard-text-13': 'Partner der 1. Ebene',
  'dashboard-text-14': 'Partner der 2. Ebene',
  'dashboard-text-15': 'Partner der 3. Ebene',
  'dashboard-text-16': 'Laufzeit',
  'dashboard-text-17': 'Tage',
  'dashboard-text-18': 'Noch keine Nachrichten',
  'dashboard-text-19': 'Derzeit',
  'dashboard-text-20': 'Nicht genügend Daten für die Grafik',
  'dashboard-text-21': '- Keine aktiven Investitionen',
  'dashboard-text-22': 'Alle Nachrichten lesen',
  'dashboard-text-22-1': 'Alle lesen',
  'dashboard-text-23': 'Investitionsplan',
  progressLabel: 'Fortschritt Inv. Plan',
  daysLabel: 'Tage bis zur Fertigstellung',
  'deposit-subtitle-1-1':
    'Bevor Sie eine Einzahlung vornehmen, stellen Sie sicher, dass Sie die',
  'deposit-subtitle-1-2': 'Registrierung Ihres Kontos abgeschlossen haben.',
  'deposit-subtitle-1-3': 'Andernfalls können Sie keine Einzahlung vornehmen.',
  'deposit-subtitle-2': 'Geld verdienen mit CoinFuze',
  'deposit-text-1':
    'Um Ihr Guthaben einzuzahlen, klicken Sie auf die Schaltfläche "Konto aufladen".',
  'deposit-text-2':
    'Alle Ihre Einzahlungstransaktionen werden im Bereich "Verlauf" unten gespeichert, wo Sie den Status Ihrer Anfragen verfolgen können.',
  'deposit-text-3':
    'Wenn eine Anfrage den Status "Abgeschlossen" erhält, werden die Mittel automatisch Ihrem "Freien Guthaben" gutgeschrieben.',
  'deposit-text-4':
    'Derzeit verwenden wir die stabilste Kryptowährung für unser System - Tether USDT.',
  'deposit-text-5':
    'Nach einer Einzahlung können Sie alle Vorteile der Zusammenarbeit mit CoinFuze genießen.',
  'deposit-text-6': 'Durchschnittlicher monatlicher Gewinn - 6,8 %*',
  'deposit-text-7': 'Jahresgewinn - 98,7 %',
  'deposit-text-8': 'Vollautomatisches Handelssystem',
  'deposit-text-9':
    'Möglichkeit zur Wiederanlage und flexibles System zur Auswahl der Anlagebedingungen',
  'deposit-text-10':
    '*Die Daten zur Rentabilität stammen aus der offenen Statistik der Algorithmusarbeit für 2024.',
  'deposit-text-11': 'Ausführliche Anleitung zur Einzahlung',
  'deposit-text-12':
    'Haben Sie Fragen zur Einzahlung? Stellen Sie sie im Support-Chat',
  'deposit-text-13':
    'Um Ihr Guthaben einzuzahlen, klicken Sie auf die Schaltfläche "Konto aufladen".',
  'deposit-text-14':
    'Alle Ihre Einzahlungstransaktionen werden im Bereich "Verlauf" unten gespeichert, wo Sie den Status Ihrer Anfragen verfolgen können.',
  'deposit-text-15':
    'Wenn eine Anfrage den Status "Abgeschlossen" erhält, werden die Mittel automatisch Ihrem "Freien Guthaben" gutgeschrieben.',
  'deposit-text-16':
    'Derzeit verwenden wir die stabilste Kryptowährung für unser System - Tether USDT.',
  'deposit-text-17':
    'Nach einer Einzahlung können Sie alle Vorteile der Zusammenarbeit mit CoinFuze genießen.',
  'deposit-text-18':
    'Haben Sie Fragen zur Einzahlung? Stellen Sie sie im Support-Chat.',
  'deposit-btn': 'Auf Konto einzahlen',
  notification: 'Kopiert',
  'deposit-popup-text-1': 'Kryptowährung',
  'deposit-popup-text-2': 'Einzahlungswährung',
  'deposit-popup-text-3': 'Ihr Guthaben:',
  'deposit-popup-text-4': 'Netzwerk',
  'deposit-popup-text-5': 'Hinweis:',
  'deposit-popup-text-6-1': 'Senden Sie nur',
  'deposit-popup-text-6-2':
    'an diese Einzahlungsadresse. Münzen werden automatisch gutgeschrieben, nachdem',
  'deposit-popup-text-6-3': '6 Netzwerkbestätigungen.',
  'deposit-popup-text-6-4':
    'Smart Contract-Adressen werden nicht unterstützt (Kontaktieren Sie uns).',
  'deposit-popup-text-7': 'Einzahlungsadresse:',
  'deposit-popup-text-8': 'Kopieren',
  history: 'Verlauf',
  'deposit-history-text-1': 'Einzahldatum',
  'deposit-history-text-2': 'Zahlungsmethode',
  'deposit-history-text-3': 'Einzahlungsbetrag',
  'deposit-history-text-4': 'Transaktionsstatus',
  'deposit-history-text-5': 'Transaktion',
  'deposit-history-text-6': 'Keine Transaktionen',
  'deposit-history-text-7': 'Angezeigt',
  'deposit-history-text-8': 'von',
  'plans-subtitle-1': 'Investitionsmanagement',
  'plans-subtitle-2': 'Erstinvestitionen',
  'plans-text-1':
    'In diesem Abschnitt können Sie Pläne aus den verfügbaren Plänen erstellen oder finanzielle Ergebnisse für alle Ihre aktiven oder geschlossenen Pläne anzeigen.',
  'plans-text-2':
    'Es gibt sechs Investmentpläne zur Auswahl, jeder mit unterschiedlichen Bedingungen in Bezug auf die Anlagedauer, erwartete Renditen und die Servicegebühren von Coinfuze.',
  'plans-text-3':
    "Um den ausgewählten Plan zu aktivieren, müssen Benutzer eine Mindesteinzahlung tätigen und auf die Schaltfläche 'Aktivieren' klicken.",
  'plans-text-4':
    'Detaillierte Anweisungen zur Arbeit mit den Investmentplänen.',
  'plans-text-5': 'Häufig gestellte Fragen im Bereich Investitionen.',
  'plans-text-6':
    "Um mit der Investition zu beginnen, müssen Sie eine Einzahlung vornehmen. Dies können Sie auf der 'Einzahlung'-Seite im linken Menü oder über die unten stehende Schaltfläche tun.",
  'plans-text-7':
    'Nachdem Ihre Einzahlung bearbeitet wurde, aktivieren Sie den ausgewählten Investmentplan.',
  'plans-text-8':
    'CoinFuze bietet eine automatische Wiederanlage-Funktion an. Das bedeutet, dass am Ende der Laufzeit eines Plans alle Gewinne aus dem vorherigen Zeitraum automatisch dem Startkapital des neuen Plans hinzugefügt werden.',

  'plans-text-9': 'Gehe zur Einzahlung',
  'plans-text-10': 'Investitionsplan aktivieren',
  'plans-text-11': 'Ja',
  'plans-text-12': 'Nein',
  'plans-text-13': 'Aktive Pläne',
  'plans-text-14': 'Startdatum',
  'plans-text-15': 'Enddatum',
  'plans-text-16': 'Startkapital',
  'plans-text-17': '% finanzielles Ergebnis',
  'plans-text-18': 'Gewinn in',
  'plans-text-19': 'Planstatus',
  'plans-text-20': 'Wiederanlage',
  'plans-text-21': 'Speichern',
  'plans-text-22': 'Verfügbare Pläne',
  'plans-text-23': 'Mindestinvestition',
  'plans-text-24': 'Laufzeit',
  'plans-text-25': 'Rendite',
  'plans-text-26': 'bis zu',
  'plans-text-27': 'Kommission',
  'plans-text-28': 'Geben Sie den Betrag des Startkapitals ein:',
  'plans-text-29': 'Aktivieren',
  'plans-text-30': 'Mindestbetrag eingeben',
  'plans-text-31': 'Name',
  'plans-text-32': 'Startkapital',
  'plans-text-33': 'Aktuelles Ergebnis',
  'plans-text-34': 'Gewinn',
  'plans-text-35': 'Kommission',
  'plans-text-36': 'Aktiv',
  'plans-text-37': 'Abgeschlossen',
  'plans-text-38': 'Investitionsplan',
  'plans-text-39': 'Tage',
  'plans-text-40': 'Auswählen',
  'plans-text-41': 'Status:',

  'partnership-subtitle-1': 'Empfehlungsprogramm',
  'partnership-subtitle-2': 'Ihr Empfehlungslink',
  'partnership-subtitle-3': 'Eingeladene Empfehlungen',
  'partnership-subtitle-4': 'Stufe 1 - Provision auf Einzahlung und Gewinn',
  'partnership-subtitle-5': 'Stufe 2 - Provision auf Einzahlung und Gewinn',
  'partnership-subtitle-6': 'Stufe 3 - Provision auf Einzahlung und Gewinn',
  'partnership-text-1': 'Empfehlen Sie uns Ihren Freunden.',
  'partnership-text-2':
    'Erzielen Sie passives Einkommen durch das Empfehlungsprogramm.',
  'partnership-text-3': 'Kopieren Sie Ihren Empfehlungslink.',
  'partnership-text-4': 'Senden Sie ihn an Ihre Freunde.',
  'partnership-text-5': 'Erhalten Sie Boni für deren Einzahlungen',
  'partnership-text-6': 'Erhalten Sie Boni auf deren Gewinne.',
  'partnership-text-7':
    'Jedes CoinFuze-Mitglied erhält einen eindeutigen Empfehlungslink und Promo-Code, den Sie mit Freunden aus der ganzen Welt teilen können. Kopieren Sie einfach diesen Link und senden Sie ihn an diejenigen, die Sie zu CoinFuze einladen möchten.',
  'partnership-text-8': 'IHR PROMO',
  'partnership-text-9': 'Teilen Sie Ihren Partnerlink oder Promo-Code.',
  'partnership-text-10': 'Studieren Sie die Statistik der Klicks',
  'partnership-text-11':
    'Verfolgen Sie, wie viele Personen sich über Ihren Link registriert haben',
  'partnership-text-12': 'Sehen Sie zu, wie Ihre Gewinne wachsen',
  'partnership-text-13': 'Insgesamt eingeladen',
  'partnership-text-14': '1. Ebene Partner',
  'partnership-text-15': '2. Ebene Partner',
  'partnership-text-16': '3. Ebene Partner',
  'partnership-text-17': 'Gesamteinnahmen:',
  'partnership-text-18': 'Suche',
  'partnership-text-19':
    'Sehen Sie nach, wer bereits über Ihren Partnerlink registriert ist. Vergessen Sie nicht, dass wir ein dreistufiges Empfehlungsprogramm haben. Sie erhalten Boni nicht nur für diejenigen, die Sie zu CoinFuze eingeladen haben, sondern auch für die Mitglieder, die Ihre Empfehlungen eingeladen haben.',
  'partnership-text-20': 'Geben Sie den Namen Ihres Empfehlungsgebers ein',
  'partnership-text-21': 'Einzahlungsboni',
  'partnership-text-22': 'Gewinnboni',
  'partnership-text-23': 'Einkommen aus Partnerschaft',
  'partnership-text-24': 'Datum der Gutschrift',
  'partnership-text-25': 'Keine solche Empfehlung',
  'partnership-text-26': 'Suche...',
  'partnership-text-27': 'Keine Empfehlungen',

  'partnership-text-28': 'Stufe',
  'partnership-text-29': 'Umsatzbetrag',
  'partnership-text-32': 'Boni',
  'partnership-text-33': 'Erfolgt',
  'partnership-text-34': 'Ihre Stufe',
  'partnership-text-35': 'Schließen',
  'partnership-text-36': 'Empfehlungslink:',
  'partnership-text-37': 'Ihr Promo-Code:',
  'partnership-text-38': 'Aktiver Teamumsatz:',
  'partnership-text-39': 'Provision auf Einzahlung und Gewinn:',
  'partnership-text-40': 'Empfehlungen',
  'partnership-text-41': 'Team-Boni',
  'news-text-1': 'Keine Nachrichten bisher.',
  'news-text-2': 'Mehr lesen',
  'news-text-3': 'Werbematerial',
  'news-text-4':
    'Teilen Sie unsere Werbematerialien mit Ihren Freunden und verdienen Sie!',
  'news-text-5':
    'Wir haben eine Auswahl an Werbematerialien für Sie zusammengestellt, die Sie verwenden können, um neue CoinFuze-Mitglieder anzuziehen.',
  'news-text-6': 'Teilen und verdienen Sie mit Ihren Freunden!',
  'news-text-7':
    'Jedes CoinFuze-Mitglied erhält einen eindeutigen Empfehlungslink und Promo-Code, den Sie mit Freunden aus der ganzen Welt teilen können. Kopieren Sie einfach diesen Link und senden Sie ihn an diejenigen, die Sie zu CoinFuze einladen möchten.',
  'news-text-8': 'IHR PROMO',
  'news-text-9': 'Teilen Sie Ihren Partnerlink oder Promo-Code.',
  'news-text-10': 'Studieren Sie die Statistik der Klicks darauf',
  'news-text-11':
    'Verfolgen Sie, wie viele Personen sich über Ihren Link registriert haben',
  'news-text-12': 'Beobachten Sie, wie Ihre Gewinne wachsen.',
  'news-text-13': 'Verdienen Sie bis zu 6% Empfehlungsboni',
  'withdrawal-text-1': 'Abhebungsdatum',
  'withdrawal-text-2': 'Zahlungsmethode',
  'withdrawal-text-3': 'Abhebungsbetrag',
  'withdrawal-text-4':
    'Sie müssen Ihre Identität verifizieren, um Geld abzuheben.',
  'withdrawal-text-5':
    'Sie müssen den Verifizierungsprozess abschließen, indem Sie Ihre Identifikationsdokumente hochladen, bevor Sie eine Abhebung vornehmen können.',
  'withdrawal-text-6': 'Überprüfen',
  'withdrawal-text-7': 'Abhebung',
  'withdrawal-text-8':
    'Geben Sie den per E-Mail gesendeten Bestätigungscode ein:',
  'withdrawal-text-9': 'Code eingeben',
  'withdrawal-text-10': 'Code erneut senden über:',
  'withdrawal-text-11': 'Code überprüfen',
  'withdrawal-text-12': 'Erneut senden',
  'withdrawal-text-13': 'Überprüft',
  'withdrawal-text-14': 'Erneut senden über:',
  'withdrawal-text-15': 'Code von der mobilen App eingeben',
  'withdrawal-text-16': 'Code eingeben von',
  'withdrawal-text-17': 'Information:',
  'withdrawal-text-18':
    'Freies Guthaben ist Ihr verfügbares Guthaben für Abhebungen. Wenn Sie aktive Investitionen haben, werden diese nicht berücksichtigt, da sie gesperrt sind, bis die Investition abgeschlossen ist.',
  'withdrawal-text-19': 'Abhebung von Geldern',
  'withdrawal-text-19-1': 'Verfügbar für die Abhebung:',
  'withdrawal-text-19-2': 'Abhebung starten',
  'withdrawal-text-20':
    'Auf dieser Seite können Sie Gelder auf Ihre Kryptowährungsbrieftasche abheben. Befolgen Sie die Anweisungen, um die Abhebung so schnell und bequem wie möglich durchzuführen.',
  'withdrawal-text-21':
    'Abhebungen, wie Einzahlungen, sind in Kryptowährung möglich',
  'withdrawal-text-22':
    'Die Abhebung kann einige Zeit in Anspruch nehmen, da Transaktionen im Kryptowährungsnetzwerk mit unterschiedlichen Geschwindigkeiten stattfinden.',
  'withdrawal-text-23':
    'Abhebungsaufträge werden automatisch akzeptiert und in kurzer Zeit bearbeitet.',
  'withdrawal-text-24': 'Abhebung starten',
  'withdrawal-text-24-1': 'Detaillierte Abhebungsanleitung',
  'withdrawal-text-25': 'Steigern Sie die Rentabilität Ihrer Investitionen!',
  'withdrawal-text-26':
    'Sie können noch mehr Gewinne erzielen, indem Sie Ihre Gelder reinvestieren.',
  'withdrawal-text-27':
    'Reinvestieren Sie Ihre Einzahlung und die Gewinne, die Sie erzielen.',
  'withdrawal-text-28':
    'Starten Sie den Investitionszeitraum mit einem großen Startkapital.',
  'withdrawal-text-29': 'Beobachten Sie, wie Ihr Einkommen täglich steigt.',
  'withdrawal-text-30': 'Jetzt reinvestieren',
  'withdrawal-text-31': 'Wählen Sie die richtige Brieftasche für die Abhebung',
  'withdrawal-text-32':
    'Um Gelder auf Ihre Brieftasche abzuheben, fügen Sie sie Ihrem Konto hinzu und erstellen Sie eine Abhebungsanfrage.',
  'withdrawal-text-33': 'Auf dieses Konto auszahlen',
  'withdrawal-text-34': 'Antrag stellen',
  'withdrawal-text-35': 'Information:',
  'withdrawal-text-36':
    'Der angezeigte Saldo ist Ihr verfügbarer Saldo, nicht Ihr Gesamtsaldo. Wenn Sie aktive Investitionen haben, werden sie nicht berücksichtigt, da sie bis zum Ende der Investitionsperiode gesperrt sind.',
  'withdrawal-text-37': 'Abhebungsbetrag',
  'personal-text-1': 'Persönliche Daten',
  'personal-text-2': 'Zwei-Faktor-Authentifizierung',
  'personal-text-3': 'Passwort',
  'personal-text-4': 'Verifizierung',
  'personal-text-5': 'Ihr Name',
  'personal-text-6': 'Geben Sie den richtigen Vornamen ein',
  'personal-text-7': 'Ihr Nachname',
  'personal-text-8': 'Geben Sie einen korrekten Nachnamen ein',
  'personal-text-9': 'Abbrechen',
  'personal-text-10': 'Speichern',
  'personal-text-11': 'Ein-/Ausschalten',
  'personal-text-12':
    'Die Zwei-Faktor-Authentifizierung (2FA) per E-Mail ist standardmäßig für alle Konten aktiviert und kann nicht deaktiviert werden.',
  'personal-text-13':
    'Um Ihre E-Mail-Adresse in CoinFuze zu ändern, sollten Sie sich an den Support wenden. Sobald der Support Ihre Anfrage bearbeitet hat, erhalten Sie eine Benachrichtigung, dass Ihre Adresse erfolgreich auf eine neue Adresse geändert wurde.',
  'personal-text-14':
    'Es ist wichtig zu beachten, dass der Support möglicherweise zusätzliche Informationen oder Dokumente zur Überprüfung Ihrer Identität anfordert, um Betrug zu vermeiden und Ihre Daten zu schützen.',
  'personal-text-15':
    '2FA ist deaktiviert. Wir empfehlen allen Kunden, die Zwei-Faktor-Authentifizierung (2FA) für zusätzliche Sicherheit zu aktivieren.',
  'personal-text-16': 'Deaktivieren',
  'personal-text-17': 'Aktivieren',
  'personal-text-18': 'Konto',
  'personal-text-19':
    'Mit dieser Funktion können Sie Google Authenticator oder Ihren Telegramm-Account für die Zwei-Faktor-Authentifizierung verwenden. Neben der Bestätigung von der E-Mail an Ihre E-Mail-Adresse müssen Sie auch einen Sicherheitscode von Google Authenticator oder Telegram bereitstellen, um bestimmte Aktionen in Ihrem persönlichen Konto durchführen zu können. Diese Aktionen umfassen: Abheben von Geldern, Ändern persönlicher Informationen sowie Ihres Kontopassworts. Dies bietet zusätzlichen Schutz vor Hackern, die sich in Ihr Konto einloggen, selbst wenn sie Ihr Passwort bekommen haben.',
  'personal-text-20':
    'Mit dieser Funktion können Sie Google Authenticator oder eine kompatible Anwendung wie FreeOTP für die Zwei-Faktor-Authentifizierung verwenden. Neben Ihrem Benutzernamen und Passwort müssen Sie auch einen sechsstelligen Sicherheitscode bereitstellen, um sich auf dieser Website anmelden zu können. Der Sicherheitscode ändert sich alle 30 Sekunden. Dies bietet zusätzlichen Schutz vor Hackern, die sich in Ihr Konto einloggen, selbst wenn sie Ihr Passwort bekommen haben.',
  'personal-text-21': 'Erhalten',
  'personal-text-22':
    'Laden Sie Google Authenticator auf Ihr Smartphone oder Ihren Computer herunter und installieren Sie ihn.',
  'personal-text-23': 'Verwenden Sie eine der folgenden Methoden:',
  'personal-text-24':
    'Die offizielle Google Authenticator-App für Android, iOS und BlackBerry. Stellen Sie sicher, dass die Uhr Ihres Geräts mit dem Zeitserver synchronisiert ist. Eine Zeitverschiebung auf Ihrem Gerät kann dazu führen, dass Sie sich nicht in Ihr persönliches Konto einloggen können.',
  'personal-text-25': 'und',
  'personal-text-26':
    'Vergessen Sie nicht, die Uhr Ihres Geräts mit dem Zeitserver zu synchronisieren. Eine Zeitschwankung auf Ihrem Gerät kann dazu führen, dass Sie sich nicht in Ihr persönliches Konto einloggen können.',
  'personal-text-27': 'Setup.',
  'personal-text-28': 'Scannen Sie den folgenden QR-Code bei',
  'personal-text-29': 'Anpassungsschlüssel',
  'personal-text-30':
    'Wenn Sie den Schlüssel ändern möchten, deaktivieren Sie die Zwei-Faktor-Authentifizierung. Wenn Sie versuchen, sie wieder einzuschalten, wird ein neuer Schlüssel generiert.',
  'personal-text-31': 'Aktivieren Sie die Zwei-Faktor-Authentifizierung.',
  'personal-text-33': 'Sicherheitscode',
  'personal-text-34': 'Ihr Passwort',
  'personal-text-35': 'Aktivieren',
  'personal-text-37': 'Aktuelles Passwort',
  'personal-text-38': 'Neues Passwort',
  'personal-text-39': 'Neues Passwort bestätigen',
  'personal-text-41': 'Herunterladbare Dokumente',
  'personal-text-42': 'Herunterladen',
  'personal-text-43': 'Akzeptables Format',
  'personal-text-44': 'Ihre Dokumente werden überprüft',
  'personal-text-45': 'Ihre Dokumente wurden überprüft',
  'personal-text-46': 'Ihre Dokumente',
  'personal-text-47': 'Aktive Sitzungen',
  'personal-text-48': 'Browser-Sitzungen',
  'personal-text-49':
    'Verwalten Sie aktive Sitzungen in anderen Browsern und Geräten und melden Sie sich ab.',
  'personal-text-50':
    'Sie können sich bei Bedarf von allen anderen Browsersitzungen auf allen Ihren Geräten abmelden. Einige Ihrer letzten Sitzungen sind unten aufgeführt, jedoch ist diese Liste möglicherweise nicht vollständig. Wenn Sie glauben, dass Ihr Konto gefährdet ist, sollten Sie auch Ihr Passwort aktualisieren.',
  'personal-text-51': 'Dieses Gerät',
  'personal-text-51-1': 'ausloggen',
  'personal-text-52': 'Abmelden von anderen Browsersitzungen',
  'personal-text-53': 'Führerschein',
  'personal-text-54': 'Bankbeleg',
  file: 'Datei nicht ausgewählt',
  document: 'Reisepass',
  'personal-text-55':
    'Um Ihr Passwort zu ändern, führen Sie die Zwei-Faktor-Authentifizierung (2FA) durch.',
  'personal-text-56':
    'Um Ihren Vornamen und Nachnamen zu ändern, führen Sie die Zwei-Faktor-Authentifizierung (2FA) durch.',
  'personal-text-57':
    'Die Zwei-Faktor-Authentifizierung (2FA) ist derzeit auf diesem Konto aktiviert.',
  'personal-text-58':
    'Bitte kopieren Sie dieses einmalige Notfallpasswort und bewahren Sie es an einem sicheren Ort auf. Wenn Sie keinen Zugriff auf ein Zwei-Faktor-Authentifizierungsgerät haben, können Sie dieses Passwort anstelle Ihres regulären Sicherheitscodes verwenden. Dieses Passwort wird sofort zerstört, wenn es verwendet wird. Wir empfehlen, dieses Passwort auszudrucken und eine Kopie an einem sicheren und zugänglichen Ort aufzubewahren, z. B. in Ihrer Brieftasche oder Ihrem Safe.',
  'personal-text-59': 'Deaktivieren der Zwei-Faktor-Authentifizierung.',
  'personal-text-60':
    'Bitte geben Sie den Zwei-Faktor-Authentifizierungscode ein.',
  'personal-text-61': 'Einreichen',
  'personal-text-62': 'Stornieren',
  'text-63': 'Zum persönlichen Konto gehen',
  'text-64': 'Rückkehr',
  'text-65': 'Benachrichtigungen',
  'text-66': 'Alle Benachrichtigungen als gelesen markieren',
  'text-67': 'Markieren',
  'text-68': 'Ihre Registrierung ist fast abgeschlossen',
  'text-69':
    'Sie müssen den Registrierungsprozess abschließen, bevor Sie investieren können. Klicken Sie dazu auf die Schaltfläche "Abschließen" und bestätigen Sie Ihre Registrierung über die an Ihre E-Mail gesendete E-Mail.',
  'text-70': 'Registrierung abschließen',
  'text-71': 'Sprache auswählen',

  'cookies-1': 'Durch das Surfen auf dieser Website stimmen Sie',
  'cookies-2': 'unserer Datenschutzrichtlinie zu',
  ok: 'Ok',
  'table-1': 'Anmeldung',
  'table-2': 'Bonus (Einlage)',
  'table-3': 'Bonus (Gewinne)',
  'table-4': 'Anzahl der Empfehlungen',
  'table-5': 'Datum der Einschreibung',
  'table-6': 'Transaktionstyp',
  'table-7': 'Datum',
  'table-8': 'Einlagebetrag',
  'table-9': 'Transaktionsstatus',
  'table-10': 'ID der Transaktion',

  'profile-window-1': 'Anmeldung:',
  'profile-window-2': 'Telefon:',
  'profile-window-3': 'Sprache:',
  'profile-window-4': 'Thema:',
  'profile-window-5': 'Freunde einladen:',

  'two-factor-1': 'Zwei-Faktor-Validierung',
  'two-factor-2':
    'Geben Sie den Bestätigungscode ein, der an Ihre E-Mail gesendet wurde:',
  'two-factor-3': 'Erneut senden',
  'two-factor-4': 'Validieren',
  'two-factor-5': 'Bestätigt',
  'two-factor-6': 'Erneut neuen Code senden über:',
  'two-factor-7': 'Geben Sie den Bestätigungscode ein, der an Ihre',
  'two-factor-8': 'Code eingeben',

  back: 'Zurück zur Planverwaltung',
  'back-to-site': 'Zurück zur Website',
  'back-to-news': 'Zurück zu den Nachrichten',
  support: 'Support',
  title: 'Titel',
  step: 'Schritt',
  'page-not-found': 'Seite nicht gefunden',
  home: 'Startseite',

  'staking-1': 'Zurück zur Planverwaltung',
  'staking-2': 'Krypto-Staking',
  'staking-3':
    'Sie können sich von allen anderen Browsersitzungen auf allen Ihren Geräten abmelden, wenn dies erforderlich ist. Einige Ihrer letzten Sitzungen werden unten aufgeführt; Diese Liste ist jedoch möglicherweise nicht vollständig. Wenn Sie glauben, dass Ihr Konto kompromittiert wurde, sollten Sie auch Ihr Passwort aktualisieren.',
  'staking-4': 'Wechseln zu',
  'staking-4-1': 'Aktivieren',
  'staking-5': 'Staking',
  'staking-6': 'Finanzergebnis:',
  'staking-7': 'Datum des letzten Gewinns:',
  'staking-8': 'Staking-Einzahlung:',
  'staking-9': 'Stufen',
  'staking-10': 'Letzte Investition:',
  'staking-11': 'Ebene',
  'staking-12': 'Umsatzbetrag',
  'staking-13': 'Boni',
  'staking-14': 'Bestanden',
  'staking-15': 'Ihre Stufe',
  'staking-16': 'Schließen',
  'staking-17': 'Staking-Ebene:',
  'staking-18': 'Aktiv',
  'staking-19': 'Inaktiv',
  'staking-20': 'Abgeschlossen',
  'staking-table-1': 'Investitionsplan',
  'staking-table-2': 'Startdatum',
  'staking-table-3': 'Enddatum',
  'staking-table-4': 'Startkapital',
  'staking-table-5': 'Aktuelles Ergebnis, USDT',
  'staking-table-6': 'Gewinn, USDT',
  'staking-table-7': 'Provision, USDT',
  'staking-table-8': 'Planstatus',

  'plan-modal-1': 'Investitionsplan',
  'plan-modal-2': 'Startdatum',
  'plan-modal-3': 'Enddatum',
  'plan-modal-4': 'Startkapital',
  'plan-modal-5': 'Finanzielles Ergebnis in %',
  'plan-modal-6': 'Gewinn in USDT',
  'plan-modal-7': 'Geben Sie den Betrag des Startkapitals ein:',
  'plan-modal-8': 'Aktivieren',

  'plan-page-1': 'Aktive Pläne',
  'plan-page-2': 'Verfügbare Pläne',
  done: 'Erledigt',
  purpose: 'Zweck',
  'password-reset': 'Das Passwort wurde an Ihre E-Mail gesendet',

  'table-next': 'Weiter',
  'table-prev': 'Vorherige',

  'tr_status-1': 'In Wartestellung',
  'tr_status-2': 'Manuell',
  'tr_status-3': 'Abgelehnt',
  'tr_status-4': 'Genehmigt',
  'tr-type-0': 'Alle',
  'tr-type-1': 'Einzahlung',
  'tr-type-2': 'Auszahlung',
  'tr-type-3': 'Dividenden',
  'tr-type-4': 'Einzahlungsbonus',
  'tr-type-5': 'Empfehlungsprämie',
  'tr-type-6': 'Teamumsatzbonus',
  'tr-type-7': 'Planaktivierung',
  'tr-type-8': 'Planschließung',
  'tr-type-9': 'Strafe',
  'tr-type-10': 'Kommission',
  'document-1': 'Personalausweis',

  copied: 'kopiert',

  'email-verification-1': 'Bestätigen Sie Ihr Konto.',
  'email-verification-2':
    'Eine Bestätigungsmail wurde bei der Registrierung an Ihre E-Mail-Adresse gesendet. Wenn Sie die E-Mail aus irgendeinem Grund nicht finden können, klicken Sie auf den Button unten und wir werden die E-Mail erneut senden.',
  'email-verification-3':
    'Um Ihr Konto zu bestätigen, klicken Sie auf den Button in der E-Mail.',
  'email-verification-4':
    'Nach der Bestätigung Ihrer E-Mail können Sie sich in Ihr Konto einloggen und alle Funktionen von CoinFuze nutzen.',
  'email-verification-5': 'Senden',
  'email-verification-6': 'Bestätigungsmail erneut senden',

  'def-file-input': 'Datei nicht ausgewählt',
  'personal-text-16-1': 'Verbinden Sie Ihr Telegramm',
  'new-notification': 'Neue Benachrichtigung',
  'personal-text-32':
    'Um sicherzustellen, dass alles richtig eingerichtet ist, geben Sie unten das Sicherheitscode ein, den Sie in Google Authenticator erhalten haben, und das Passwort für Ihr Konto ein. Wenn der Code und das Passwort korrekt sind, klicken Sie auf die Schaltfläche "Aktivieren", und die Zwei-Faktor-Authentifizierung wird aktiviert.',
  'personal-label-1': 'Sicherheitscode',
  'personal-label-2': 'Ihr Passwort',
  'withdrawal-text-24-2': 'Häufig gestellte Fragen im Auszahlungsbereich'
}
