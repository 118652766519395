export default {
  'withdrawal-ph-1': 'Wallet ID',
  'withdrawal-ph-2': 'Withdrawal amount',
  'confirm-modal-1': 'Сonfirm the action',
  'confirm-modal-2': 'Confirm',
  'confirm-modal-3': 'Cancel',

  copy: 'Copy',
  delete: 'Delete',
  download: 'Download',
  'modal-p-text-1': 'Detailed instructions on working with Investment Plans',
  'modal-p-text-2':
    'Once the deposit has been credited to your account, you can invest your funds in the selected plan.',
  'modal-p-text-3':
    'Follow the instructions below to activate your Investment plan.',
  'modal-p-text-4':
    "In your personal account, go to the 'Plan Management' page. Here you will find detailed information on all investment products. This includes investment periods, service fees, as well as plan profitability.",
  'modal-p-text-5':
    "To activate the desired plan, click the 'Select' button, enter the amount of initial capital you want to start the plan with, and click the 'Activate' button.",
  'modal-p-text-6':
    "With the 'Automatic Reinvestment' feature, you can automatically invest your funds for a long period and gain additional benefits.",
  'modal-p-text-7':
    'Immediately after launching the investment plan, you can activate or deactivate the option with a single click.',
  'modal-p-text-8':
    'Congratulations, you have successfully launched an investment plan!',
  'modal-p-text-9':
    'You can change this setting at your discretion, even after the execution period has begun. If you have activated this option, then upon expiration of the Investment plan term, all capital, including all profit, will be reinvested automatically. If this option is disabled, automatic reinvestment does not occur, and all capital with profit will be credited to the Free Balance.',

  update: 'Upload',
  'update-avatar': 'Upload your avatar',
  save: 'Save',
  'modal-d-text-1': 'Instructions for Depositing Funds on CoinFuze',
  'modal-d-text-2': "Navigate to the 'Deposit' page in your account.",
  'modal-d-text-3':
    "To initiate the deposit process, click on the 'Add Funds' button.",
  'modal-d-text-4':
    'You will be provided with a unique wallet identifier to send funds to. Please ensure you use this identifier when making the payment.',
  'modal-d-text-5':
    'You also have the option to use the QR code located to the left of the wallet identifier. You can scan it with your mobile phone for easier input of data.',
  'modal-d-text-6':
    'After sending funds to the specified wallet, the funds will be automatically credited to your Balance. This usually takes between 1 minute and 2 hours, but timeframes may vary depending on the blockchain and other factors.',
  'modal-d-text-7':
    "Confirmed deposits will be displayed at the bottom of the page in the 'Deposit History' tab. Here you can track all your deposit requests.",
  'modal-d-text-8':
    'Follow this guide to easily and securely deposit funds into your CoinFuze account. If you have any questions or need further assistance, please contact our support service.',

  'modal-w-text-1':
    'To initiate the withdrawal process, follow these instructions:',
  'modal-w-text-2':
    "In your account's left menu, navigate to the 'Withdrawal' page.",
  'modal-w-text-3': 'Enter the amount of USDT you wish to withdraw.',
  'modal-w-text-4':
    'Specify your USDT TRC20 wallet to which you want to receive the funds.',
  'modal-w-text-5':
    'Confirm the transaction by entering the confirmation code that will be sent to your email, as well as the password code (2FA) for two-factor authentication if you have it enabled.',
  'modal-w-text-6':
    'After confirmation, your withdrawal request will be processed. This usually takes from a few minutes to 24 hours, depending on system load.',
  'modal-w-text-7':
    'Please note that the minimum withdrawal amount is 10 USDT and there is a small network fee. Additionally, you need to ensure that your investment plan term has expired and the profit has been credited to your Free Balance in order to withdraw funds.',
  validation: {
    nameValidFormat: '{attribute} must not contain digits or symbols',
    twoCharactersInRow:
      '{attribute} must not have more than two identical characters in a row',
    email: 'Email format is invalid',
    required: '{attribute} is required',
    minValue: '{attribute} min value can`t be less {min}',
    maxValue: '{attribute} max value can`t be greater {max}',
    minLength: '{attribute} must be at least {min} characters',
    maxLength: '{attribute} must be no more than {max} characters',
    validFormat:
      '{attribute} must contain the first capital letter, must be contain only characters',
    validPassword:
      '{attribute} must contain at least one digit and uppercase character',
    sameAsPassword: 'Passwords must be identical',
    numeric: '{attribute} is invalid. Must be a digit',
    minimumValue: 'The amount should not be less than 100 USDT'
  },
  Country: 'Country',
  'phone-input-placeholder': 'Enter your phone number',

  'withdrawal-instruction': 'Detailed withdrawal-instruction',
  'withdrawal-instruction-text-1':
    'To start the withdrawal process follow these instructions:',
  'withdrawal-instruction-text-2':
    "In your personal cabinet in the left menu go to the 'Withdrawal of funds' page.",
  'withdrawal-instruction-text-3':
    'Enter the amount of USDT you want to withdraw.',
  'withdrawal-instruction-text-4':
    'Specify your USDT TRC20 wallet to which you want to receive the funds.',
  'withdrawal-instruction-text-5':
    'Confirm the transaction by entering the confirmation code that will be sent to your e-mail, as well as the password code (2FA) of two-factor authentication if you have it connected.',
  'withdrawal-instruction-text-6':
    'After confirmation, your withdrawal request will be processed. This usually takes from a few minutes to 24 hours, depending on system load.',
  'withdrawal-instruction-text-7':
    'Please note that the minimum amount to withdraw is 10 USDT and there is a small network fee. In addition, you will need to make sure that your investment plan has expired and profits are credited to Free Balance to be able to withdraw.',
  'plans-instruction-text-1':
    'Once the deposit has been credited to your account you can invest your funds in the chosen plan.',

  'plans-instruction-text-2':
    'Follow the instructions below to activate your investment plan.',
  'plans-instruction-text-3':
    "Go to the 'Investment Plans' page in your personal cabinet. Here you will find detailed information on each investment plan. This is the investment terms, commissions and also the return of the plan.",
  'plans-instruction-text-4':
    "To activate the desired plan, click on 'Plus' and enter in the 'Start Capital' field the amount you want to start the plan for and click on the 'Activate' button.",
  'plans-instruction-text-5':
    "With the 'Automatic Reinvestment' feature, you can automatically invest your funds for the long term and get additional benefits.",
  'plans-instruction-text-6':
    'Immediately after starting an investment plan, you can activate or deactivate the option with a single click. ',
  'plans-instruction-text-7':
    'Congratulations, you have successfully launched an investment plan! ',
  'plans-instruction-text-8':
    'You can change this setting as you wish, even when the due date has already started. If you have activated this option, at the end of the Investment Plan Term, all capital, including all profits are reinvested automatically. If this option is disabled, there is no automatic reinvestment and all capital with profits will be credited to the Free Balance. ',
  'plans-instruction-text-9':
    "If you have 'Automatic Reinvestment' activated, all capital and profits of the plan will be permanently reinvested after maturity until you disable this option. ",
  'plans-instruction-text-10':
    'Thus, you can fully automatically invest your capital for a long term with minimal cost. In order to pay out the entire capital, you must disable this feature. Once the feature is disabled, the entire capital, including returns, will be credited to the Free Balance at the end of the current investment plan term. No additional fees will be charged when using this option.',
  'plans-instruction-text-11':
    "All information on working with Active and Completed Investment Plans can be found in the 'Plan History' section at the bottom of the page.",
  'popup-instruction-title-2': 'Step-by-step guide',
  'popup-instruction-text-1': 'CoinFuze Deposit Instructions',

  'popup-instruction-text-2':
    "Go to the 'Deposit' page in your personal account.",
  'popup-instruction-text-3':
    "To start the deposit process, click the 'Deposit' button.",
  'popup-instruction-text-4':
    'You will be provided with a unique wallet ID to send funds. Please make sure that you use this identifier when making a payment.',
  'popup-instruction-text-5':
    'You also have the option to use the QR code located to the left of the wallet ID. You can scan it with your cell phone for easier data entry.',
  'popup-instruction-text-6':
    'After sending funds to the specified wallet, the funds will be automatically credited to your Balance. This usually takes between 1 minute and 2 hours, but the time frame may vary depending on the blockchain and other factors.',
  'popup-instruction-text-7':
    "Confirmed deposits will be displayed at the bottom of the page under the 'Deposit History' tab. Here you will be able to keep track of all your deposit requests.",
  'popup-instruction-text-8':
    'Follow these instructions to easily and securely make your deposit on CoinFuze. If you have any questions or need additional assistance, please feel free to contact our support team.',
  error: 'Error',
  'partnership-text-31': 'Number of referrals',
  'partnership-text-30': 'Amount of Deposits',
  'autorization-tab-1': 'Authorization',
  'autorization-tab-2': 'Registration',
  'autorization-login': 'Login',
  'autorization-username': 'Login/Email/Phone',
  'autorization-password': 'Password',
  'autorization-forgot': 'Forgot password?',
  'autorization-btn-login': 'Login to account',
  'autorization-name': 'Name',
  'autorization-surname': 'Last Name',
  'autorization-repeat-password': 'Confirm password',
  'autorization-ypass': 'Current password',
  'autorization-newpass': 'New password',
  'autorization-email': 'Email',
  'autorization-tel': 'Phone',
  'autorization-promo': 'Promo code',
  'autorization-text-0': 'I accept the',
  'autorization-text-1': 'Terms and Conditions,',
  'autorization-text-2': 'Privacy Policy',
  'autorization-text-3': 'AML Policy',
  'autorization-text-4': 'I am 18 years old or older',
  'autorization-text-5': 'Terms of Service',
  'autorization-text-6': 'Remember me',

  'autorization-text-7': "Don't have an account?",
  'autorization-text-8':
    'Enter your login and we send instruction to your email:',
  'autorization-text-10': 'Do you already have an account?',
  'autorization-text-11': 'Sign in',
  'autorization-btn-register': 'Registration',
  'autorization-btn-cancel': 'Reset password',
  'autorization-status-2': 'The specified email/phone is not found.',
  'autorization-status-1': 'Invalid login or password',
  'autorization-status-name': 'Incorrect first name',
  'autorization-status-surname': 'Incorrect last name',
  'autorization-status-password':
    'Password must be at least 7 characters long and contain at least 1 capital and small letter',
  'autorization-status-repeat-password': 'Passwords do not match',
  'autorization-status-email': 'Incorrect mail format',
  'autorization-status-phone': 'Incorrect phone number',
  'autorization-status-agree-rules': 'Confirm conditions',
  'autorization-status-agree-age': 'Confirm conditions',
  'validation-1': 'To register you must be over 18 years old',
  'validation-2': 'To register you must accept the terms and conditions',
  'header-item-1': 'Total Balance',
  'header-item-2': 'Free Balance:',
  'header-item-3': 'Active Balance:',
  'non-message': 'No new notifications',
  'message-btn': 'Show all notifications',
  'header-item-4': 'Personal Information',
  'header-item-5': 'Invite Friends',
  'header-item-6': 'Add',
  'header-item-7': 'Log out',
  'footer-item-2': 'Terms of Use',
  'footer-item-3': 'Privacy Policy',
  'footer-item-1': 'Policy AML',
  'sidebar-item-1': 'Dashboard',
  'sidebar-item-2': 'Banking',
  'sidebar-item-3': 'Plan Management',
  'sidebar-item-4': 'Affiliate Program',
  'sidebar-item-5': 'Withdrawals',
  'sidebar-item-6': 'Personal Information',
  'sidebar-item-7': 'News',

  'sidebar-item-8': 'Sign out of account',
  'dashboard-subtitle-1': 'Account',
  'dashboard-subtitle-2': 'Finance',
  'dashboard-subtitle-3': 'Investments',
  'dashboard-subtitle-4': 'Affiliate Program',
  'dashboard-subtitle-5': 'Term',
  'dashboard-subtitle-6': 'Сurrent Result',
  'dashboard-subtitle-7': 'Finance Result',
  'dashboard-text-1': 'Free Balance',
  'dashboard-text-2': 'Active Funds',
  'dashboard-text-3': 'Verified',
  'dashboard-text-4': 'Participant since',
  'dashboard-text-5': 'Total Profit Plans',
  'dashboard-text-6': 'Referral Income',
  'dashboard-text-7': 'Deposit',
  'dashboard-text-8': 'Withdrawal',
  'dashboard-text-9': 'Total Profit',
  'dashboard-text-10': 'Active Plans',
  'dashboard-text-10-1': 'Closed Plans',
  'dashboard-text-11': 'Plans on reinvestment',
  'dashboard-text-12': 'Number of partners',
  'dashboard-text-13': '1st Line Partners',
  'dashboard-text-14': '2nd line partners',
  'dashboard-text-15': '3rd Line Partners',
  'dashboard-text-16': 'Period',
  'dashboard-text-17': 'days',
  'dashboard-text-18': 'No news yet',
  'dashboard-text-19': 'Currently',
  'dashboard-text-20': 'Not enough data for the graph',
  'dashboard-text-21': '- No active investments',
  'dashboard-text-22': 'Read all news',
  'dashboard-text-22-1': 'Read all',
  'dashboard-text-23': 'Investment Plan',
  progressLabel: 'Inv. plan progress',
  daysLabel: 'Days to Completion',
  'deposit-subtitle-1-1':
    'Before making a deposit, make sure you have completed the',

  'deposit-subtitle-1-2': 'Registration of your account.',
  'deposit-subtitle-1-3': 'Otherwise you will not be able to make a Deposit.',
  'deposit-subtitle-2': 'Make money with CoinFuze',
  'deposit-text-1':
    "To deposit your balance, click on the button below 'Fund your Account'.",
  'deposit-text-2':
    "All your deposit transactions will be saved in the 'History' section below, where you can track the status of your requests.",
  'deposit-text-3':
    "When a request receives the status 'Completed', the funds will be automatically added to your 'Free Balance'.",
  'deposit-text-4':
    'At the moment we use the most stable cryptocurrency for our system - Tether USDT.',
  'deposit-text-5':
    'After making a deposit you will be able to enjoy all the benefits of cooperation with CoinFuze.',
  'deposit-text-6': 'Average monthly profit - 6.8%*',
  'deposit-text-7': 'Annual profit - 98.7%',
  'deposit-text-8': 'Fully automated trading system',
  'deposit-text-9':
    'Reinvestment possibility and flexible system of choosing investment terms',
  'deposit-text-10':
    "*The data on profitability is taken from the open statistics of the algorithm's work for 2024.",
  'deposit-text-11': 'Detailed instruction on how to deposit',
  'deposit-text-12':
    'Do you have a question about depositing? Ask it in the support chat',

  'deposit-text-13':
    "To deposit your balance, click on the button below 'Fund yourAccount'.",
  'deposit-text-14':
    "All your deposit transactions will be saved in the 'History' section below, where you can track the status of your requests.",
  'deposit-text-15':
    "When a request receives the status 'Completed', the funds will be automatically added to your 'Free Balance'.",
  'deposit-text-16':
    'At the moment we use the most stable cryptocurrency for our system - Tether USDT.',
  'deposit-text-17':
    'After making a deposit you will be able to enjoy all the benefits of cooperation with CoinFuze.',
  'deposit-text-18':
    'Do you have a question about depositing? Ask it in the support chat.',
  'deposit-btn': 'Deposit to account',
  notification: 'Copied',
  'deposit-popup-text-1': 'Cryptocurrency',
  'deposit-popup-text-2': 'Deposit currency',
  'deposit-popup-text-3': 'Your balance:',
  'deposit-popup-text-4': 'Network',
  'deposit-popup-text-5': 'Note:',
  'deposit-popup-text-6-1': 'Send only',
  'deposit-popup-text-6-2':
    'to this deposit address. Coins will be credited automatically after',
  'deposit-popup-text-6-3': '6 network confirmations.',
  'deposit-popup-text-6-4':
    'Smart contract addresses are not supported (contact us).',
  'deposit-popup-text-7': 'Deposit address:',
  'deposit-popup-text-8': 'Copy',
  history: 'History',
  'deposit-history-text-1': 'Deposit Date',
  'deposit-history-text-2': 'Payment Method',
  'deposit-history-text-3': 'Deposit Amount',
  'deposit-history-text-4': 'Transaction Status',
  'deposit-history-text-5': 'Transaction',
  'deposit-history-text-6': 'No transactions',
  'deposit-history-text-7': 'Shown',
  'deposit-history-text-8': 'of',
  'plans-subtitle-1': 'Investment Management',
  'plans-subtitle-2': 'First Investments',

  'plans-text-1':
    'In this section, you can create plans from the available plans or view financial results for all of your active or closed plans.',
  'plans-text-2':
    'There are six investment plans available for you to choose from, each with different terms and conditions regarding investment term, expected returns and Coinfuze service fees.',
  'plans-text-3':
    "To activate the selected plan, users must make a minimum deposit and click the 'Activate' button.",
  'plans-text-4':
    'Detailed instruction on how to work with the Investment Plans.',
  'plans-text-5': 'Frequently asked questions on the Investments section.',
  'plans-text-6':
    "To start investing, you need to make a deposit. You can do this on the 'Deposit' page in the menu on the left or on the button below.",
  'plans-text-7':
    'After your deposit has been processed, activate the selected investment plan.',
  'plans-text-8':
    'CoinFuze offers an automatic reinvestment feature. This means that at the end of the plan term, all profits from the previous period are automatically transferred to the starting capital of the new plan.',
  'plans-text-9': 'Go to deposit',
  'plans-text-10': 'Activate investment plan',
  'plans-text-11': 'Yes',
  'plans-text-12': 'No',
  'plans-text-13': 'Active plans',
  'plans-text-14': 'Start Date',
  'plans-text-15': 'End Date',
  'plans-text-16': 'Start-up capital',
  'plans-text-17': '% financial result',
  'plans-text-18': 'Profit in',
  'plans-text-19': 'Plan Status',
  'plans-text-20': 'Reinvest',
  'plans-text-21': 'Save',
  'plans-text-22': 'Available plans',
  'plans-text-23': 'Min Investment',
  'plans-text-24': 'Term',
  'plans-text-25': 'Yield',
  'plans-text-26': 'up to',
  'plans-text-27': 'Commission',
  'plans-text-28': 'Enter the amount of starting capital:',
  'plans-text-29': 'Activate',
  'plans-text-30': 'Enter minimum amount',
  'plans-text-31': 'Name',
  'plans-text-32': 'Start Capital',
  'plans-text-33': 'Current Result',
  'plans-text-34': 'Profit',
  'plans-text-35': 'Commission',
  'plans-text-36': 'Active',
  'plans-text-37': 'Completed',
  'plans-text-38': 'Investment plan',
  'plans-text-39': 'days',
  'plans-text-40': 'Select',
  'plans-text-41': 'Status:',

  'partnership-subtitle-1': 'Referral Program',
  'partnership-subtitle-2': 'Your referral link',
  'partnership-subtitle-3': 'Invited Referrals',
  'partnership-subtitle-4': 'Level 1 - commission on deposit and on profit',
  'partnership-subtitle-5': 'Level 2 - commission on deposit and on profit',
  'partnership-subtitle-6': 'Level 3 - commission on deposit and on profit',
  'partnership-text-1': 'Recommend us to your friends.',
  'partnership-text-2': 'Get passive income through the referral program.',
  'partnership-text-3': 'Copy your referral link.',
  'partnership-text-4': 'Send to your friends.',
  'partnership-text-5': 'Get bonuses for their deposit refills',
  'partnership-text-6': 'Get bonuses on their profits',
  'partnership-text-7':
    'Every CoinFuze member gets a unique referral link and promo code to share with friends from all over the world. Just copy this link and send it to those you want to invite to CoinFuze.',
  'partnership-text-8': 'YOUR PROMO',
  'partnership-text-9': 'Share your affiliate link or promo code.',
  'partnership-text-10': 'Study the statistics of click-throughs',
  'partnership-text-11':
    'Keep track of how many people have registered through your link',
  'partnership-text-12': 'Watch your profits grow',
  'partnership-text-13': 'Total invited',
  'partnership-text-14': '1st Line Partners',
  'partnership-text-15': '2d Line Partners',
  'partnership-text-16': '3d Line Partners',
  'partnership-text-17': 'Total Earnings:',
  'partnership-text-18': 'Search',
  'partnership-text-19':
    "Look who has already registered through your affiliate link and how many bonuses you have received. Don't forget that we have a three-level referral program. You get bonuses not only for those you have invited to CoinFuze, but also for the members your referrals have invited.",
  'partnership-text-20': "Enter your referral's name",
  'partnership-text-21': 'Deposit Bonuses',
  'partnership-text-22': 'Profit Bonuses',
  'partnership-text-23': 'Partnership Income',
  'partnership-text-24': 'Date of enrollment',
  'partnership-text-25': 'No such referral',
  'partnership-text-26': 'Search...',
  'partnership-text-27': 'No referrals',

  'partnership-text-28': 'Stage',
  'partnership-text-29': 'Turnover Amount',
  'partnership-text-32': 'Bonuses',
  'partnership-text-33': 'Passed',
  'partnership-text-34': 'Your Level',
  'partnership-text-35': 'Close',
  'partnership-text-36': 'Referral link:',
  'partnership-text-37': 'Your promo:',
  'partnership-text-38': 'Active Team Turnover:',
  'partnership-text-39': 'Commission on deposit and on profit:',
  'partnership-text-40': 'Referrals',
  'partnership-text-41': 'Team Bonuses',
  'news-text-1': 'No news yet.',
  'news-text-2': 'Read more',
  'news-text-3': 'Promotional materials',
  'news-text-4': 'Share our promotional materials with your friends and earn!',
  'news-text-5':
    'We have made a selection of promotional materials for you to use to attract new CoinFuze members.',
  'news-text-6': 'Share and earn with your friends!',
  'news-text-7':
    'Every CoinFuze member gets a unique referral link and promo code to share with friends from all over the world. Just copy this link and send it to those you want to invite to CoinFuze.',
  'news-text-8': 'YOUR PROMO',
  'news-text-9': 'Share your affiliate link or promo code.',
  'news-text-10': 'Study the statistics of click-throughs on it',
  'news-text-11':
    'Keep track of how many people have registered through your link',
  'news-text-12': 'Watch your profits grow.',
  'news-text-13': 'Earn up to 6% referral bonuses',
  'withdrawal-text-1': 'Withdrawal Date',
  'withdrawal-text-2': 'Payment Method',
  'withdrawal-text-3': 'Withdrawal Amount',
  'withdrawal-text-4': 'You need to verify your identity to withdraw funds',
  'withdrawal-text-5':
    'You need to complete the verification process by uploading your identity documents before withdrawal.',
  'withdrawal-text-6': 'Verify',
  'withdrawal-text-7': 'Withdrawal',
  'withdrawal-text-8': 'Enter the confirmation code sent to your email:',
  'withdrawal-text-9': 'Enter code',
  'withdrawal-text-10': 'Send new code again via:',
  'withdrawal-text-11': 'Verify code',
  'withdrawal-text-12': 'Re-send',
  'withdrawal-text-13': 'Verified',
  'withdrawal-text-14': 'Resend new code again via:',
  'withdrawal-text-15': 'Enter code from mobile app',
  'withdrawal-text-16': 'Enter code from',
  'withdrawal-text-17': 'Information:',
  'withdrawal-text-18':
    'Free balance is your available balance for withdrawal. If you have active investments, they are not counted because they are locked until the investment ends.',
  'withdrawal-text-19': 'Withdrawal of Funds',
  'withdrawal-text-19-1': 'Available for withdrawal:',
  'withdrawal-text-19-2': 'Start Withdrawals',
  'withdrawal-text-20':
    'On this page, you can withdraw funds to your cryptocurrency wallet. Follow the prompts to make withdrawal as fast and convenient as possible.',
  'withdrawal-text-21':
    'Withdrawal, like deposits are available in cryptocurrency',
  'withdrawal-text-22':
    'Withdrawal may take some time, as transactions in the cryptocurrency network take place at different speeds.',
  'withdrawal-text-23':
    'Withdrawal requests are accepted and processed automatically in a short time.',
  'withdrawal-text-24': 'Start withdrawal',
  'withdrawal-text-24-1': 'Detailed withdrawal-instruction',
  'withdrawal-text-25': 'Increase the profitability of your investments!',
  'withdrawal-text-26':
    'You can get even more profits by reinvesting your funds.',
  'withdrawal-text-27': 'Reinvest your deposit and the profits you make.',
  'withdrawal-text-28':
    'Start the investment period with a large starting capital.',
  'withdrawal-text-29': 'Watch your income increase daily.',
  'withdrawal-text-30': 'Reinvest now',
  'withdrawal-text-31': 'Choose the right wallet for withdrawal',
  'withdrawal-text-32':
    'To withdraw funds to your wallet, add it to your account and create a withdrawal request.',

  'withdrawal-text-33': 'Withdraw to this one',
  'withdrawal-text-34': 'Submit application',
  'withdrawal-text-35': 'Information:',
  'withdrawal-text-36':
    'The balance shown is your available balance, not your total funds. If you have active investments, they are not counted because they are locked in until the end of the investment period.',
  'withdrawal-text-37': 'Withdrawal Amount',
  'personal-text-1': 'Personal data',
  'personal-text-2': 'Two-factor authentication',
  'personal-text-3': 'Password',
  'personal-text-4': 'Verification',
  'personal-text-5': 'Your Name',
  'personal-text-6': 'Enter correct first name',
  'personal-text-7': 'Your last name',
  'personal-text-8': 'Enter a correct last name',
  'personal-text-9': 'Cancel',
  'personal-text-10': 'Save',
  'personal-text-11': 'Enable/disable',
  'personal-text-12':
    'Two-Factor Authentication (2FA) by Email is enabled by default for all accounts and cannot be disabled.',
  'personal-text-13':
    'To change your email address in CoinFuze, you should contact support. Once support has processed your request, you will receive a notification that your address has been successfully changed to a new one.',
  'personal-text-14':
    'It is important to remember that in order to avoid fraud and protect your data, support may request additional information or documents to verify your identity.',
  'personal-text-15':
    '2FA is disabled. We advise all customers to enable two-factor authentication (2FA) for added security.',
  'personal-text-16': 'Disable',
  'personal-text-17': 'Enable',
  'personal-text-18': 'Account',
  'personal-text-19':
    'This feature allows you to use Google Authenticator or your Telegram account for two-factor authentication. In addition to the confirmation from the email to your Email, you will also need to provide a security code, from Google Authenticator or Telegram, to be able to perform certain actions in your personal account. These actions include: Withdrawing funds, changing personal information as well as your account password. This provides additional protection against hackers logging into your account, even if they were able to get a hold of your password.',
  'personal-text-20':
    'This feature allows you to use Google Authenticator or a compatible application such as FreeOTP for two-factor authentication. In addition to your username and password, you will also need to provide a six-digit security code to be able to log into this site. The security code changes every 30 seconds. This provides additional protection against hackers logging into your account, even if they were able to get hold of your password.',
  'personal-text-21': 'Get',
  'personal-text-22':
    'Download and install Google Authenticator on your smartphone or computer.',
  'personal-text-23': 'Use one of the following steps:',
  'personal-text-24':
    "The official Google Authenticator app for Android, iOS and BlackBerry.Remember to synchronize your device's clock with the time server. A time shift on your device may result in your inability to log in to your personal account.",
  'personal-text-25': 'and',

  'personal-text-26':
    "Remember to synchronize your device's clock with the time server. A time shift on your device may result in your inability to log in to your personal account.",
  'personal-text-27': 'Setup.',
  'personal-text-28': 'Scan the following QR code at',
  'personal-text-29': 'Customization Key',
  'personal-text-30':
    'If you want to change the key, disable two-factor authentication. When you try to turn it back on, it will generate a new key.',
  'personal-text-31': 'Activate two-factor authentication.',
  'personal-text-33': 'Security Code',
  'personal-text-34': 'Your Password',
  'personal-text-35': 'Enable',
  'personal-text-37': 'Current Password',
  'personal-text-38': 'New Password',
  'personal-text-39': 'Confirm new password',
  'personal-text-41': 'Downloadable documents',
  'personal-text-42': 'Upload',
  'personal-text-43': 'Acceptable format',
  'personal-text-44': 'Your documents are under verification',
  'personal-text-45': 'Your documents have been verified',
  'personal-text-46': 'Your documents',
  'personal-text-47': 'Active sessions',
  'personal-text-48': 'Browser Sessions',
  'personal-text-49':
    'Manage and log out of your active sessions on other browsers and devices.',
  'personal-text-50':
    'You can log out of all your other browser sessions on all your devices if necessary. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you believe your account has been compromised, you should also update your password.',
  'personal-text-51': 'This device',
  'personal-text-51-1': 'logout',
  'personal-text-52': 'Log out of other browser sessions',
  'personal-text-53': "Driver's license",
  'personal-text-54': 'Bank receipt',
  file: 'File not selected',
  document: 'Passport',
  'personal-text-55':
    'In order to change your password, go through two-factor authentication (2FA)',
  'personal-text-56':
    'To change your first and last name, pass two-factor authentication (2FA).',
  'personal-text-57':
    'Two-Factor Authentication (2FA) is currently enabled on this account.',
  'personal-text-58':
    'Please copy this one-time emergency password and store it in a safe place. If you do not have access to a two-factor authentication device, you may use this password in place of your regular security code. This password is immediately destroyed when used. We recommend that you print this password and keep a printout in a safe and accessible place, such as your wallet or safe.',
  'personal-text-59': 'Disabling two-factor authentication.',

  'personal-text-60': 'Please enter the two-factor authentication code from.',
  'personal-text-61': 'Submit',
  'personal-text-62': 'Cancel',
  'text-63': 'Go to personal account',
  'text-64': 'Return',
  'text-65': 'Notifications',
  'text-66': 'Mark all notifications as read',
  'text-67': 'Mark',
  'text-68': 'Your registration is almost complete',
  'text-69':
    "You must complete the registration process before investing. To do this, click the 'Complete' button and confirm your registration from the email sent to your mail.",
  'text-70': 'Complete registration',
  'text-71': 'Select language',

  'cookies-1': 'By browsing this site, you agree to',
  'cookies-2': 'our privacy policy',
  ok: 'Ok',

  'table-1': 'Login',
  'table-2': 'Bonus (Deposit)',
  'table-3': 'Bonus (Profits)',
  'table-4': 'Number of referrals',
  'table-5': 'Date of enrollment',
  'table-6': 'Transaction type',
  'table-7': 'Date',
  'table-8': 'Amount',
  'table-9': 'Transaction Status',
  'table-10': 'ID Transaction',

  'profile-window-1': 'Login:',
  'profile-window-2': 'Phone:',
  'profile-window-3': 'Language:',
  'profile-window-4': 'Theme:',
  'profile-window-5': 'Invite friends:',

  'two-factor-1': 'Two-factor validation',
  'two-factor-2': 'Enter the confirmation code sent to your email:',
  'two-factor-3': 'Resend',
  'two-factor-4': 'Validate',
  'two-factor-5': 'Validated',
  'two-factor-6': 'Resend new code again via:',
  'two-factor-7': 'Enter the confirmation code sent to your',
  'two-factor-8': 'Enter code',

  back: 'Back to Plan Management',
  'back-to-site': 'Back to Site',
  'back-to-news': 'Back to News',
  support: 'support',
  title: 'Title',
  step: 'Step',
  'page-not-found': 'Page not found',
  home: 'Home',
  'staking-1': 'Back to Plan Management',
  'staking-2': 'crypto staking',
  'staking-3':
    'You can log out of all your other browser sessions on all your devices if necessary. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you believe your account has been compromised, you should also update your password.',
  'staking-4': 'Go to',
  'staking-4-1': 'Activate',
  'staking-5': 'Staking',
  'staking-6': 'Finance Result:',
  'staking-8': 'Deposit on staking:',
  'staking-9': 'Levels',
  'staking-10': 'Last investment:',
  'staking-11': 'level',
  'staking-12': 'Turnover Amount',
  'staking-13': 'Bonuses',
  'staking-14': 'Passed',
  'staking-15': 'Your Level',
  'staking-16': 'Close',
  'staking-17': 'Staking lvl:',
  'staking-18': 'Active',
  'staking-19': 'Inactive',
  'staking-20': 'Complited',
  'staking-table-1': 'Investment plan',
  'staking-table-2': 'Start Date',
  'staking-table-3': 'End Date',
  'staking-table-4': 'Start Capital',
  'staking-table-5': 'Current Result, USDT',
  'staking-table-6': 'Profit, USDT',
  'staking-table-7': 'Commission, USDT',
  'staking-table-8': 'Plan Status',
  'staking-table-9': 'Staking Status',
  'plan-modal-1': 'Investment plan',
  'plan-modal-2': 'Start Date',
  'plan-modal-3': 'End Date',
  'plan-modal-4': 'Start-up capital',
  'plan-modal-5': 'financial result',
  'plan-modal-6': 'Profit in usdt',
  'plan-modal-7': 'Enter the amount of starting capital:',
  'plan-modal-8': 'Activate',
  'plan-page-1': 'Active plans',
  'plan-page-2': 'Available plans',
  done: 'Done',
  purpose: 'Target',

  'password-reset': 'The password has been sent to your email',

  'table-next': 'Next',
  'table-prev': 'Prev',
  'tr_status-1': 'Pending',
  'tr_status-2': 'Manual',
  'tr_status-3': 'Rejected',
  'tr_status-4': 'Approved',
  'tr-type-0': 'All',
  'tr-type-1': 'Deposit',
  'tr-type-2': 'Withdrawal',
  'tr-type-3': 'Dividends',
  'tr-type-4': 'Deposit Bonus',
  'tr-type-5': 'Referral Payment',
  'tr-type-6': 'Team Turnover Bonus',
  'tr-type-7': 'Plan Activation',
  'tr-type-8': 'Plan Closure',
  'tr-type-9': 'Penalty',
  'tr-type-10': 'Commission',

  'document-1': 'ID-card',

  'deposit-benefits-1': 'Earn with',
  'deposit-benefits-2': 'Average monthly profit',
  'deposit-benefits-3': 'Annual profit',
  'deposit-benefits-4': 'Fully automated trading system.',
  'deposit-benefits-5':
    'Opportunity for reinvestment and a flexible system for choosing investment terms.',

  'staking-1-1': 'Investment product',
  'staking-1-2':
    'designed and focused on users who want to earn a stable passive income, an easy-to-manage tool, as well as daily accruals and profit withdrawal.',
  'staking-1-3':
    'a product with the potential for development and growth in profitability, thanks to 5 levels of the tool.',

  copied: 'copied',
  'staking-7': 'Date of last profit:',

  'email-verification-1': 'Verify your account.',
  'email-verification-2':
    'A confirmation email was sent to your email upon registration with a verification button. If for some reason you cannot find the email, click the button below and we will resend the email.',
  'email-verification-3':
    'To confirm your account, click the button in the email.',
  'email-verification-4':
    'After confirming your email, you can log in to your account and start using all the features of CoinFuze.',
  'email-verification-5': 'Send',
  'email-verification-6': 'Resend confirmation email',

  'def-file-input': 'File not selected',
  'personal-text-16-1': 'Connect your telegram',
  'new-notification': 'New notification',
  'personal-text-32':
    'To ensure that everything is set up correctly, enter the security code received in Google Authenticator and the password for your account in the field below. If the code and password are correct, click the "Enable" button and two-factor authentication will be activated.',
  'personal-label-1': 'Security code',
  'personal-label-2': 'Your password',

  'withdrawal-text-24-2': 'Frequently Asked Questions in the Withdrawal section'
}
