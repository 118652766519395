<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import axios from 'axios'
export default {
  created() {
    this.checkLocation()
    document.documentElement.setAttribute('lang', this.$i18n.locale)
    this.setTheme(this.$store.getters['app/getAppTheme'])
  },
  mounted() {
    this.appHeight()
    window.addEventListener('resize', this.appHeight)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.appHeight)
  },
  methods: {
    checkLocation() {
      axios
        .get('https://ipapi.co/json/')
        .then((response) => {
          const countryCode = response.data.country
          console.log('countryCode', countryCode)
          // if (countryCode === 'ES') {
          //   window.location.href = '/block-page';
          // } else {
          //   // Пользователь из другой страны, продолжить выполнение приложения
          // }
        })
        .catch((error) => {
          console.error('Error fetching location data:', error)
          // В случае ошибки продолжить выполнение приложения
        })
    },
    setTheme(theme) {
      const body = document.querySelector('body')
      body.setAttribute('data-theme', theme)
    },
    appHeight() {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/styles/styles.scss';

:root {
  --app-height: 100%;
}
</style>
