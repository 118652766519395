<template>
  <div>
    <loading-component v-if="loading" />
    <form v-else class="app__start-content" @submit.prevent="login">
      <div class="app__start-title text-center text-accent fw-600">
        {{ $t('autorization-tab-1') }}
      </div>
      <div class="app__start-inner">
        <div class="input-group">
          <input
            class="input-control"
            type="text"
            :placeholder="$t('autorization-login')"
            v-model="formLogin.login"
            required
          />
        </div>
        <div class="input-group">
          <input
            class="input-control"
            type="password"
            :placeholder="$t('autorization-password')"
            v-model="formLogin.password"
            required
          />
        </div>
        <div class="input-group pb-3">
          <div class="row">
            <div class="col-6">
              <label class="input-checkbox">
                <input type="checkbox" v-model="formLogin.remember_me" />
                <span>
                  {{ $t('autorization-text-6') }}
                </span>
              </label>
            </div>
            <div class="col-6 text-end">
              <a style="cursor: pointer" @click="openPassResetForm">
                {{ $t('autorization-forgot') }}
              </a>
            </div>
          </div>
        </div>
        <div class="input-group pb-2">
          <button type="submit" class="button w-100">
            <span>{{ $t('autorization-btn-login') }}</span>
          </button>
        </div>
        <div class="input-group">
          <div class="input-meta text-center">
            {{ $t('autorization-text-7') }}
            <router-link to="register">
              {{ $t('autorization-btn-register') }}
            </router-link>
          </div>
        </div>
      </div>
      <password-reset
        v-if="passResetForm"
        :show="passResetForm"
        @close-modal="closePassresetForm"
      />
      <div class="auth-btns">
        <o-auth-2
          :type="'yandex'"
          :clientId="oauth.yandex.client_id"
          :redirectUri="oauth.yandex.redirect_uri"
          parentId="ya-auth-container"
          @token="handleToken"
          @error="handleError"
        />
        <o-auth-2
          :type="'google'"
          :clientId="oauth.google.client_id"
          :redirectUri="oauth.google.redirect_uri"
          parentId="g-auth-container"
          @token="handleToken"
          @error="handleError"
        />
      </div>
    </form>
  </div>
</template>

<script>
import LoadingComponent from '../global/preloaders/LoadingComponent.vue'
import PasswordReset from './PasswordReset.vue'
// import FormFieldWrapper from '../global/FormFieldWrapper.vue'
import ValidateRules from '@/mixins/ValidateRules.vue'
import OAuth2 from './OAuth2.vue'
export default {
  name: 'LoginForm',
  mixins: [ValidateRules],
  components: {
    PasswordReset,
    LoadingComponent,
    OAuth2
    // FormFieldWrapper
  },
  data() {
    return {
      loading: false,
      passResetForm: false,
      email: '',
      formLogin: {
        login: '',
        password: '',
        remember_me: false
      },
      oauth: {
        yandex: {
          client_id: '79d2e53dc8804c58945b5c343433c92c',
          response_type: 'token',
          redirect_uri: 'https://coin-fuze.info/auth/yandex'
        },
        google: {
          redirect_uri: 'https://coin-fuze.info/auth/login',
          client_id:
            '195634556164-rfnvpu0cct6gqek321v838vi26mffr7e.apps.googleusercontent.com'
        }
      }
    }
  },
  mounted() {
    if (Object.keys(this.$route.query).length > 0) {
      Object.entries(this.$route.query).forEach(([key, value]) => {
        if (key === 'locale') {
          this.$i18n.locale = value
          document.documentElement.setAttribute('lang', value)
          this.$store.dispatch('app/setLocale', value)
        }
      })
    }
  },
  methods: {
    handleToken(tokenData) {
      // Обработка полученного токена
      console.log('Токен получен: ', tokenData)
      this.loading = true
      this.$store
        .dispatch('app/oauth', tokenData)
        .then((response) => {
          console.log('res', response)
          if (response.data.new_registration) {
            this.$router.push({
              name: 'Register',
              params: { data: response.data }
            })
          } else {
            this.$i18n.locale = response.data.user.default_languege
            const loginRedirect = localStorage.getItem('loginRedirect') || '/'
            this.$router.push(loginRedirect)
            localStorage.removeItem('loginRedirect')
          }
        })
        .catch((error) => console.log('error', error))
        .finally(() => this.loading.false)
    },
    handleError(error) {
      // Обработка ошибки
      console.error('Ошибка при авторизации: ', error)
    },
    login() {
      this.loading = true
      this.$store
        .dispatch('app/login', this.formLogin)
        .then((response) => {
          this.$i18n.locale = response.data.user.default_languege
          const loginRedirect = localStorage.getItem('loginRedirect') || '/'
          this.$router.push(loginRedirect)
          localStorage.removeItem('loginRedirect')
        })
        .catch((error) => this.$showError(error.detail))
        .finally(() => (this.loading = false))
    },
    closePassresetForm() {
      this.passResetForm = false
    },
    openPassResetForm() {
      this.passResetForm = true
    }
  }
}
</script>
<style scoped lang="scss">
.auth-btns {
  display: flex;
  gap: 3rem;
  justify-content: space-around;
  align-items: center;
}
</style>
