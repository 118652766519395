<template>
  <div class="app__content flex-fill">
    <page-title />
    <div class="app__content-item">
      <div class="row gy-base">
        <dashboard-card
          v-for="(card, idx) in cardList"
          :key="idx + card.cardTitle"
          :cardTitle="card.cardTitle"
          :subtitle="card.subtitle"
          :subTitleVal="card.subTitleVal"
          :statsItems="card.statsItems"
        />
      </div>
    </div>
    <div class="app__content-item">
      <div class="row gy-base">
        <charts-card />
        <div
          class="col-lg-3 col-md-3 app__content-col d-none d-lg-flex"
          v-if="!newsLoading"
        >
          <div class="app__content-header">
            <h2 class="app__content-title h3">{{ $t('sidebar-item-7') }}</h2>
          </div>
          <news-card :news="latestNews" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LoadingPage from '../global/preloaders/LoadingPage.vue'
import ChartsCard from './ChartsCard.vue'
import DashboardCard from './DashboardCard.vue'
import NewsCard from '@/components/news/NewsCard.vue'
import { mapGetters } from 'vuex'
import PageTitle from '../main/PageTitle.vue'
// import Vue from 'vue'
export default {
  components: {
    // LoadingPage,
    PageTitle,
    ChartsCard,
    DashboardCard,
    NewsCard
  },
  name: 'DashboardPage',
  data() {
    return {
      loading: false,
      newsLoading: false
    }
  },
  created() {
    this.getUserData()
    this.getLatestNews()
    this.getBalance()
  },
  mounted() {
    this.scrollToTop()
  },
  computed: {
    ...mapGetters({
      locale: 'app/getLocale',
      balance: 'user/getBalance',
      user: 'user/getUser',
      transactions: 'user/getPaymentHystory',
      plans: 'user/getInvestHystory',
      latestNews: 'news/getLatestNews'
    }),
    cardList() {
      return [
        {
          cardTitle: this.$t('dashboard-subtitle-1'),
          subtitle: this.$t('header-item-1') + ':',
          subTitleVal: this.formatBalance(this.balance.total) + ' USDT',
          statsItems: [
            {
              title: this.$t('dashboard-text-1'),
              val: this.formatBalance(this.balance.free)
            },
            {
              title: this.$t('dashboard-text-2'),
              val: this.formatBalance(this.balance.active)
            },
            {
              title: this.$t('dashboard-text-4'),
              val: this.formatDate(this.user.registration)
            },
            {
              title: this.$t('dashboard-text-3'),
              val: this.user.kyc_ok,
              type: 'Verified'
            }
          ]
        },
        {
          cardTitle: this.$t('dashboard-subtitle-2'),
          subtitle: this.$t('dashboard-text-9') + ':',
          subTitleVal: this.totalProfit + ' USDT',
          statsItems: [
            {
              title: this.$t('dashboard-text-5'),
              val: this.totalPlansProfit
            },
            {
              title: this.$t('dashboard-text-6'),
              val: this.totalReferralMoney
            },
            {
              title: this.$t('dashboard-text-7'),
              val: this.totalDepositMoney
            },
            {
              title: this.$t('dashboard-text-8'),
              val: this.totalWithdrawalMoney
            }
          ]
        },
        {
          cardTitle: this.$t('dashboard-subtitle-3'),
          subtitle: this.$t('dashboard-text-2') + ':',
          subTitleVal: this.formatBalance(this.balance.active) + ' USDT',
          statsItems: [
            {
              title: this.$t('dashboard-text-5'),
              val: this.totalPlansProfit
            },
            {
              title: this.$t('dashboard-text-10'),
              val: this.statusPlans.active
            },
            {
              title: this.$t('dashboard-text-10-1'),
              val: this.statusPlans.closed
            },
            {
              title: this.$t('dashboard-text-11'),
              val: this.statusPlans.reinvestment
            }
          ]
        },
        {
          cardTitle: this.$t('dashboard-subtitle-4'),
          subtitle: this.$t('dashboard-text-6') + ':',
          subTitleVal: this.totalReferralMoney + ' USDT',
          statsItems: [
            {
              title: this.$t('dashboard-text-12'),
              val: this.totalPartners
            },
            {
              title: this.$t('dashboard-text-13'),
              val: this.user.referal_first_count
            },
            {
              title: this.$t('dashboard-text-14'),
              val: this.user.referal_second_count
            },
            {
              title: this.$t('dashboard-text-15'),
              val: this.user.referal_third_count
            }
          ]
        }
      ]
    },
    totalPartners() {
      return (
        this.user.referal_first_count +
        this.user.referal_second_count +
        this.user.referal_third_count
      )
    },
    totalReferralMoney() {
      let totals = this.user.totals_new
      let sum =
        totals.bns_rfrl.amount + totals.rfrl.amount + totals.cmd_bns.amount

      return this.formatBalance(sum)
    },
    statusPlans() {
      let result = { active: 0, closed: 0, reinvestment: 0 }
      this.plans.forEach((plan) => {
        if (plan.active) {
          result.active++
          if (plan.reinvest) result.reinvestment++
        } else result.closed++
      })
      return result
    },
    totalPlansProfit() {
      let sum = 0
      this.transactions.forEach((transaction) => {
        if (transaction.tr_type === 'dvdnt') {
          sum += transaction.tr_amount
        }
      })
      return this.formatBalance(sum)
    },
    totalDepositMoney() {
      let sum = 0
      this.transactions.forEach((transaction) => {
        if (
          transaction.tr_type === 'pay' &&
          transaction.tr_status === 'approved'
        ) {
          sum += transaction.tr_amount
        }
      })
      return this.formatBalance(sum)
    },
    totalWithdrawalMoney() {
      let sum = 0
      this.transactions.forEach((transaction) => {
        if (
          transaction.tr_type === 'draw' &&
          transaction.tr_status === 'approved'
        ) {
          sum += transaction.tr_amount
        }
      })
      return this.formatBalance(sum)
    },
    totalProfit() {
      let total = this.user.totals_new
      let sum =
        total.bns_rfrl.amount +
        total.cmd_bns.amount +
        total.dvdnt.amount +
        total.rfrl.amount
      return this.formatBalance(sum)
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    },
    formatBalance(balance) {
      return new Intl.NumberFormat(this.locale).format(balance)
    },
    truncateText(text, count) {
      if (text.length > count) {
        return text.slice(0, count) + '...'
      }
      return text
    },
    getUserData() {
      this.loading = true
      this.$store
        .dispatch('user/getUserData')
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false
        })
    },
    getBalance() {
      this.loading = true
      this.$store
        .dispatch('user/getBalance')
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false
        })
    },
    getLatestNews() {
      this.newsLoading = true
      this.$store
        .dispatch('news/getLastNews')
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.newsLoading = false
        })
    },
    getPlanList() {
      this.loading = true
      this.$store
        .dispatch('user/getPlanLists')
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false
        })
    },
    logout() {
      this.$store.dispatch('app/logout').then(() => {
        this.$router.push({ name: 'Login' })
      })
    }
  }
}
</script>
