import AppServices from '@/services/api/app.services'
import UserServices from '@/services/api/user.services'

let state = {
  locale: '',
  theme: 'dark',
  agreePrivacy: false,
  localesList: ['en', 'ru', 'tr', 'es', 'it', 'de', 'nl', 'fa'],
  authToken: null,
  selectedTab: 'PersonalDataTab',
  countries: [],
  sessions: []
}

const mutations = {
  SET_LOCALE(state, locale) {
    const topLevelDomain = '.coinfuze.com'
    document.cookie = `locale=${encodeURIComponent(
      locale
    )};domain=${topLevelDomain};path=/`

    state.locale = locale
  },
  SET_LOGOUT(state) {
    state.authToken = null
    state.sessions = []
    state.selectedTab = 'PersonalDataTab'
  },
  SET_AUTH_TOKEN(state, payload) {
    state.authToken = payload
  },
  SET_THEME(state, theme) {
    state.theme = theme
  },
  SET_AGREE_PRIVACY(state, agreePrivacy) {
    state.agreePrivacy = agreePrivacy
  },
  SET_COUNTRIES(state, payload) {
    state.countries = payload
  },
  SET_SESSIONS(state, payload) {
    state.sessions = payload
  },
  SET_TAB(state, tab) {
    state.selectedTab = tab
  }
}

const actions = {
  setDefaultLocale({ commit }, locale) {
    commit('SET_LOCALE', locale)
  },
  setLocale({ commit }, locale) {
    commit('SET_LOCALE', locale)
    return new Promise((resolve, reject) => {
      UserServices.post('changeUserData', { locale: locale })
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  selectPersonalPageTab({ commit }, tab) {
    commit('SET_TAB', tab)
  },
  setAuthToken({ commit }, authToken) {
    commit('SET_AUTH_TOKEN', authToken)
  },
  login({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      AppServices.post('login', data)
        .then((response) => {
          commit('SET_AUTH_TOKEN', response.data.rsp)
          commit('SET_LOCALE', response.data.user.default_languege)
          dispatch('user/setUserName', response.data.caarea, { root: true })
          dispatch('user/setUser', response.data.user, { root: true })
          dispatch('user/getTwoFactorData', response.data.user, { root: true })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  oauth({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      AppServices.post('oauth', data)
        .then((response) => {
          if (response.data.rsp) {
            commit('SET_AUTH_TOKEN', response.data.rsp)
            commit('SET_LOCALE', response.data.user.default_languege)
            dispatch('user/setUserName', response.data.caarea, { root: true })
            dispatch('user/setUser', response.data.user, { root: true })
            dispatch('user/getTwoFactorData', response.data.user, {
              root: true
            })
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logout({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      AppServices.get('logout')
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          commit('SET_LOGOUT')
          dispatch('user/logout', {}, { root: true })
          dispatch('news/logout', {}, { root: true })
          dispatch('notification/logout', {}, { root: true })
        })
    })
  },
  getCountries({ commit }) {
    return new Promise((resolve, reject) => {
      AppServices.get('getCountries')
        .then((response) => {
          commit('SET_COUNTRIES', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllSessions({ commit }) {
    return new Promise((resolve, reject) => {
      AppServices.get('sessions')
        .then((response) => {
          commit('SET_SESSIONS', response.data.results)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logoutSession({ commit }, id) {
    return new Promise((resolve, reject) => {
      AppServices.post('sessions', id)
        .then((response) => {
          commit('SET_SESSIONS', response.data.results)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logoutSessions({ commit }) {
    return new Promise((resolve, reject) => {
      AppServices.post('sessions')
        .then((response) => {
          commit('SET_SESSIONS', response.data.results)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const getters = {
  getLocale: (state) => state.locale,
  getLocalesList: (state) => state.localesList,
  getAuthToken: (state) => state.authToken,
  getCountries: (state) => state.countries,
  getAppTheme: (state) => state.theme,
  getAgreePrivacy: (state) => state.agreePrivacy,
  getAllSessions: (state) => state.sessions,
  getSelectedTab: (state) => state.selectedTab
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
