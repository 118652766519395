<template>
  <div>Loading...</div>
</template>
<script>
export default {
  name: 'YandexPage',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {
    this.yaSend()
    console.log(this.$route)
  },
  methods: {
    yaSend() {
      window.YaSendSuggestToken('https://coin-fuze.info')
    }
  }
}
</script>
