<template>
  <div :id="parentId">
    <button
      class="gsi-material-button"
      v-if="type === 'google'"
      @click="gRequest"
    >
      <div class="gsi-material-button-state"></div>
      <div class="gsi-material-button-content-wrapper">
        <div class="gsi-material-button-icon">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="display: block"
          >
            <path
              fill="#EA4335"
              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
            ></path>
            <path
              fill="#4285F4"
              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
            ></path>
            <path
              fill="#FBBC05"
              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
            ></path>
            <path
              fill="#34A853"
              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
            ></path>
            <path fill="none" d="M0 0h48v48H0z"></path>
          </svg>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'OAuth2',
  props: {
    type: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    redirectUri: {
      type: String,
      required: true
    },
    parentId: {
      type: String,
      default: 'ya-auth-container'
    }
  },
  data() {
    return {
      gclient: null
    }
  },
  mounted() {
    if (this.type === 'yandex') {
      this.initYandexAuth()
    }
    if (this.type === 'google') {
      this.initGoogleAuth()
      this.test()
    }
  },
  methods: {
    initYandexAuth() {
      window.YaAuthSuggest.init(
        {
          client_id: this.clientId,
          response_type: 'token',
          redirect_uri: this.redirectUri
        },
        'https://coin-fuze.info/',
        {
          view: 'button',
          parentId: this.parentId,
          buttonSize: 's',
          buttonView: 'icon',
          buttonTheme: 'light',
          buttonBorderRadius: '18',
          buttonIcon: 'yaEng'
        }
      )
        .then((result) => result.handler())
        .then((data) => {
          console.log('Сообщение с токеном: ', data)
          // Обрабатываем токен или передаем его выше по иерархии компонентов
          this.$emit('token', data)
        })
        .catch((error) => {
          console.log('Что-то пошло не так: ', error)
          // Обрабатываем ошибку
          this.$emit('error', error)
        })
    },
    gRequest() {
      this.gclient.requestAccessToken()
    },
    authFunc(res) {
      console.log('123123', res)
    },
    handleCredentialResponse(response) {
      console.log('Encoded JWT ID token: ' + response.credential)
    },
    test() {
      const self = this
      window.google.accounts.id.initialize({
        client_id: this.clientId,
        callback: self.handleCredentialResponse
      })
      window.google.accounts.id.renderButton(
        document.getElementById('buttonDiv'),
        { theme: 'outline', size: 'large' } // customization attributes
      )
      // window.google.accounts.id.prompt() // also display the One Tap dialog
    },
    initGoogleAuth() {
      const self = this
      this.gclient = window.google.accounts.oauth2.initTokenClient({
        client_id: this.clientId,
        scope:
          'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.phonenumbers.read',
        ux_mode: 'popup',
        callback: (response) => {
          const code_receiver_uri = 'https://custxperts.com/api/auth/google/'
          const xhr = new XMLHttpRequest()
          xhr.open('POST', code_receiver_uri, true)
          xhr.setRequestHeader(
            'Content-Type',
            'application/x-www-form-urlencoded'
          )
          xhr.setRequestHeader('X-Requested-With', 'XmlHttpRequest')
          xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
              self.authFunc(JSON.parse(xhr.responseText))
              console.log('Auth code response: ' + xhr.responseText)
            } else self.$showError('Something Went Wrong')
          }
          xhr.send(
            'access_token=' +
              response.access_token +
              '&expires_in=' +
              response.expires_in +
              '&scope=' +
              response.scope +
              '&token_type=' +
              response.token_type
          )
        }
      })
    }
  }
}
</script>
<style scoped>
.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 40px;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
  margin: 0;
  padding: 9px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}
</style>
